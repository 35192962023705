<template>
  <v-container>
    <v-card>
      <v-card-title> Add New User</v-card-title>
      <v-card-text>
        <v-row justify="end">
            <v-btn v-if="user.isDeleted" depressed color="success" @click="restoreUser()">Restore</v-btn>
            <v-btn v-else depressed color="primary" @click="deleteUser()">Deactivate</v-btn>
        </v-row>
        <v-row>
          <v-col md="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col md="6">
                  <v-text-field v-model.trim="user.firstName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'First Name is required']" label="First Name" required></v-text-field>
                </v-col>

                <v-col md="6">
                  <v-text-field v-model.trim="user.lastName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'Last Name is required']" label="Last Name" required></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-text-field v-model.trim="user.email" :rules="[ (v) => !!v || 'E-mail is required', (v) => /^\w+([.-]?\w+)*@[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]{1,})+$/.test(v) || 'E-mail must be valid' ]" label="E-mail Address" required></v-text-field>
                </v-col>

                <v-col md="4">
                  <label for="phone">Phone Number</label>
                  <vue-tel-input id="phone" v-model.trim="user.phonePrimary" @input="onPrimaryPhoneObject"/>
                  <div v-if="user.phonePrimary">
                    <small v-if="!primaryPhoneObject.valid" class="error--text">
                      Phone Number is not valid
                    </small>
                  </div>
                </v-col>

                <v-col md="4">
                  <label for="phoneSecondary">Secondary Phone</label>
                  <vue-tel-input id="phoneSecondary" v-model.trim="user.phoneSecondary" @input="onSecPhoneObject" />
                  <div v-if="user.phoneSecondary">
                    <small v-if="!secPhoneObject.valid" class="error--text">Phone number is not valid</small>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-select v-model.trim="selectedEmployer" :items="employerStore.employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']" :label="'Select ' + employerStore.fieldLabelSettings.groupLabel" required></v-select>
                </v-col>
                <v-col md="6">
                  <v-select v-model.trim="selectedRole" :items="rolesList" :item-text="(item) => item.name" :item-value="(item) => item.id" label="Role" :rules="[(v) => !!v || 'Role is required']" required></v-select>
                </v-col>
              </v-row>

              <v-switch v-model.trim="user.forcePasswordReset" :disabled="user.isDeleted" inset label="Force Reset User Password"></v-switch>

            </v-form>
          </v-col>
          <v-col md="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="updateUser">Update</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="$router.push({ name: 'user_admin_index' })">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useUserStore } from "../../stores/user";
import { useEmailStore } from "../../stores/email";
import { useEmployerStore } from "../../stores/employer";
import { useLookupStore } from "../../stores/lookup";
import { useAuth0Store } from "../../stores/auth0";
import { convertToAuthZeroID, convertToAuth0UserObject, convertToUserObjectForDatabase, generateRandomPassword } from "../../commons/user";
import { roles } from "../../data/definitions/roles";
import { DateTime } from "luxon";

export default {
  name: "NewEmployerComponent",
  setup() {
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const auth0Store = useAuth0Store();
    const emailStore = useEmailStore();
    const userStore = useUserStore();
    const authStore = useAuthStore();

    return { employerStore, lookupStore, emailStore, auth0Store, userStore, authStore };
  },
  data() {
    return {
      primaryPhoneObject: { valid: false },
      secPhoneObject: { valid: false },
      valid: true,
      user: {
        email: "",
        phonePrimary: "",
        phoneSecondary: "",
        firstName: "",
        lastName: "",
        isAdmin: false,
        isDeleted: false,
        forcePasswordReset: false,
        created: "",
        createdBy: ""
      },
      existingEmail: "",
      selectedEmployer: "",
      selectedRole: "",
      assignedRole: "",
      authZeroID: "",
      rolesList: [],
      isDirty: false,
      pageLoad: true
    };
  },
  methods: {
    preventReload(event) {
      /* the beforeunload event functions based on the value of isDirty. 
       if false, event would run but if true, user would receive a prompt message when reloading page */
      if (!this.isDirty) return
      event.preventDefault()
      event.returnValue = ""
    },
    onPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.primaryPhoneObject = phoneObject;
    },
    onSecPhoneObject(formattedNumber, phoneObject) {
      this.secPhoneObject = phoneObject;
    },
    updateUser(showSwal=true) {
      let primaryPhoneValidation = !this.primaryPhoneObject.valid
        ? "- Primary Phone Number is Required"
        : "";
      if (primaryPhoneValidation) {
        this.user.phonePrimary = "+1";
      }
      let swal = (validation) => {
        let formValidate = validation == this.$refs.form._data.inputs ? true : false;
        let phoneNum = formValidate == true ? primaryPhoneValidation : "";
        let secondaryPhoneValidation = this.user.phoneSecondary && !this.secPhoneObject.valid ? "<br/> - Secondary Phone Number is Invalid" : "";

        this.$swal({
          title: "Error",
          icon: "error",
          html: (
            phoneNum + secondaryPhoneValidation +
            Object.keys(validation).map((key) => {
              if (validation[key].validations) {
                if (validation[key].validations.length > 0) {
                  return "<br>" + "- " + validation[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      };

      if (!this.$refs.form.validate() || !this.primaryPhoneObject.valid || this.user.phoneSecondary != '' && !this.secPhoneObject.valid) {
        return swal(this.$refs.form._data.inputs);
      }

       //isDirty has to be set to false else the prompt message would show up when the route is about to change 
      this.isDirty = false;

      let userObject = convertToAuth0UserObject(this.user.email, this.user.firstName, this.user.lastName, true);
      //updating emails to auth0 requires the client_id to be passed as payload
      if(this.existingEmail != this.user.email){
        userObject.client_id = process.env.VUE_APP_AUTH0_CLIENT_ID
      }

      //save to auth0
      this.auth0Store.updateUser(this.authZeroID, userObject).then((res) => {
        if(this.assignedRole != this.selectedRole) {
          //delete existing role from user
          this.auth0Store.deleteUserRoles(res.user_id, { roles: this.assignedRole.split() });
          //assign role (auth0)
          this.auth0Store.assignRole(res.user_id, { roles: this.selectedRole.split() });
        }

        //save to database
        if(res.identities[0].user_id){
          let user = convertToUserObjectForDatabase(this.user.firstName, this.user.lastName, this.user.email, this.user.phonePrimary, this.user.phoneSecondary, this.selectedEmployer, res.identities[0].user_id, this.user.isAdmin, this.user.forcePasswordReset, true);
          //add userID to the object
          user.userID = this.$route.query.userID;
          user.created = this.user.created;
          user.createdBy = this.user.createdBy;

          if(this.user.isDeleted){
            user.IsDeleted = true;
            user.Deleted = DateTime.now().toISO();
          }
          this.userStore.updateUser(this.$route.query.userID, user).then(() => {
            if(this.user.forcePasswordReset) {
              let newPassword = generateRandomPassword();
              //reset password on auth0
              this.auth0Store.updateUser(this.authZeroID, {password: newPassword}).then(() => {
                //send reset password email
                this.emailStore.sendResetPasswordMail(this.user.email, newPassword, this.user.firstName, window.location.origin + "/login");
              });
            }

            if(showSwal) {
              this.$swal({
                title: "Saved",
                icon: "success",
                confirmButtonColor: this.$vuetify.theme.themes.light.success,
                confirmButtonText: "Continue",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed)
                  this.$router.push({ name: "user_admin_index" });
                if (result.isDenied) 
                  this.$router.go();
              });
            } else {
              this.$router.push({ name: "user_admin_index" });
              showSwal = true;
            }
            
          })
          .catch((err) => {
            this.$swal({
              title: "Error",
              icon: "error",
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              confirmButtonText: "Try Again",
              html: Object.keys(err.data.errors).map((key) => { return "<br>" + "- " + err.data.errors[key][0]; })
            })
          });
        }
      });
      }, 
   
    deleteUser() {
      this.user.forcePasswordReset = false;
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: "This will deactivate the user and remove their ability to access the system. Would you like to continue?",        
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          //block user from login (auth0)
          this.auth0Store.updateUser(this.authZeroID, {blocked: true});

          //soft delete user on the database
          this.userStore.deleteUser(this.$route.query.userID).then(() => this.user.isDeleted = true);
        }
      });
    },
    restoreUser() {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: 'Reactivating this record will activate this user. Are you sure you wish to continue?',
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          //unblock user from login (auth0)
          this.auth0Store.updateUser(this.authZeroID, {blocked: false});

          //restore(undelete) user from the database
          this.userStore.UnDeleteUser(this.$route.query.userID).then(() => this.user.isDeleted = false);
        }
      });
    },
  },
  watch: {
    // using a deep watcher here helps to trigger this callback on a nested user mutation. Whenever a mutation occurs, the value of isDirty is set to true
    user: {
      handler: function(){
        if(!this.pageLoad) {
          this.isDirty = true;
        }
      },
      deep: true,
    },
    assignedRole() {
      this.pageLoad = false;
    },
    selectedEmployer: {
      handler: function(){
        if(!this.pageLoad) {
          this.isDirty = true;
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) { this.updateUser(false) }
        else if(result.isDismissed){ next(); }
        else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  }, 
  mounted() {
    if (this.$route.query.userID) {

      //get user
      this.userStore.getUser(this.$route.query.userID)
      .then((res) => {
        //get all roles
        this.auth0Store.getAllRoles().then((res) => {

          // check if logged in user is an admin
          const found = this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin);
          if(found){
            this.isAdmin = true;
            this.rolesList = res;
          }else {
            this.user.isAdmin = false;
            // if user isn't an admin, remove the admin role from the list of roles
            this.rolesList = [...res.filter((x) => !x.name.includes("Revive"))];
          }

          this.auth0Store.getUserRoles(this.authZeroID).then((res) => {
            this.selectedRole = res[0].id;
            this.assignedRole = res[0].id;
          })

        });

        this.user = res;
        this.user.forcePasswordReset = false;
        this.selectedEmployer = res.employerID;
        this.existingEmail = res.email;

        //get all employers
        this.employerStore.getAllEmployersByActor()

        //get user role
        this.authZeroID = convertToAuthZeroID(res.authZeroID);

      });

    }else {
      this.$swal({
        title: "Error",
        text: "Invalid Page",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
        showCancelButton: false,
      }).then((okay) => {
        if (okay) {
          this.$router.push({ name: "user_admin_index" });
        }
      });
  }
  },
};
</script>
