<template>
  <v-item v-slot="{active, toggle}" :value="value">
    <v-card interactive :selected="active" dense @click="toggle">
      <v-card-title>
        <div v-if="isAdmin" >
          <v-badge bordered color="error" content="admin">
          {{ name }}
          </v-badge>
        </div>
        <div v-else>
          {{ name }}
        </div>
      </v-card-title>
      <v-card-subtitle>
        {{ description }}
      </v-card-subtitle>
    </v-card>
  </v-item>
</template>

<script>
export default { 
  name: "ProductListCard",
  props: {
    isDefault: Boolean,
    skuid: String,
    name: String,
    description: String,
    defaultPrice: Number,
    isAdmin: Boolean
  },
  computed: {
    value() {
      return {
        isDefault: this.isDefault,
        name: this.name,
        skuid: this.skuid,
        description: this.description,
        defaultPrice: this.defaultPrice,
      };
    }
  }
};
</script>
