import apiClient from "../services/api";
import { defineStore } from "pinia";
import apiUploadClient from "../services/api";
import fileClient from "../services/api2";

export const useMemberStore = defineStore("member", {
  state: () => ({}),
  getters: {},
  actions: {
    assignToMember(member) {
      if (member.productStart)
        this.assignProduct(member.memberID, member.product, member.productStart, member.productEnd).then(() => {
          //assign hubspotID
          if(member.selectedEmployer)
            this.assignHubspotID(member.memberID, member.selectedEmployer, null)
        });
      //assign External ID
      if (member.externalId)
        this.assignUniqueID(member.memberID, member.externalId);
      //assign language
      if (member.language)
      this.assignLanguage(member.memberID, member.language);
    },
    //Get member information
    async getAllMembersByUserID() {
      const data = await apiClient.get("/member/getAllMembersByUserID");
      return data.data;
    },
    async getMemberListingDetailsByUserID() {
      const data = await apiClient.get("/member/getMemberListingDetailsByUserID");
      return data.data;
    },
    async getMemberListingDetailsByUserID2(page, pageSize,showInactive, showDependents, memberType, sortBy, sortDesc, search) {
      const url = `/member/getMemberListingDetailsByUserID2?page=${page}&pageSize=${pageSize}&showInactive=${showInactive}&showDependents=${showDependents}&memberType=${memberType}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${encodeURIComponent(search)}`;
      const data = await apiClient.get(url);
      return data.data;
    },
    async getInvoices() {
      const data = await apiClient.get("/member/GetInvoices");
      return data.data;
    },
    async getFullMemberByID(id){
      //returns the primary member with dependents regardless of dependent id passed or not.
      const member = await apiClient.get("/member/getFullMemberByID?memberID="+id);
      return member.data;
    },
    async getMembersByEmployer(employerID) {
      try {
        return await apiClient.get("/member/getAllMembersByEmployerID?employerID=" + employerID);
      }
      catch{
        return [];
      }
    },
    //create and update members
    async uploadMemberList(members, employerID){
      const data = await apiClient.post("/member/uploadMemberList?employerID="+employerID, members);
      return data.data;
    },

    //upload member through ftp processor console app
    async uploadMemberThroughConsole(enrollmentFile, employerID, uploadType){
      let formData = new FormData();
      formData.append('enrollmentFile', enrollmentFile);
      return await fileClient.post("/member/UploadMemberThroughConsole?employerID="+employerID+"&uploadType=" + uploadType, formData, {
        responseType: 'blob'
      });
    },

    //validate member details before uploading a file
    async ValidateMemberUploadFile(enrollmentFile, employerID){
      let formData = new FormData();
      formData.append('enrollmentFile', enrollmentFile);
      return await fileClient.post("/member/ValidateMemberUploadFile?employerID="+employerID, formData, {
        responseType: 'application/json'
      });
    },
    
    async createNewFamily(familyPayload){
      const data = await apiClient.post("/member/CreateFamily", familyPayload);
      return data.data;
    },
    async updateFamily(familyPayload){
      const data = await apiClient.put("/member/UpdateFamily", familyPayload);
      return data.data;
    },

    async createMember(member, employerID){
      const data = await apiClient.post("/member/createMember?employerID="+employerID, member);
      return data.data;
    },

    async updateMember(employerID, member){
      const data = await apiClient.put(`/member/updateMember?employerID=${employerID}&id=${member.memberID}`, member);
      return data.data;
    },
    async removeProduct(memberID, productID){
      await apiClient.delete("/member/removeProduct?memberID="+memberID+"&productID="+productID);
    },
    async deleteMember(member){
       await apiClient.delete("/member/deleteMember?id="+member.memberID);
    },
    async UnDeleteMember(member){
      await apiClient.delete("/member/UnDeleteMember?id="+member.memberID);
   },
    async terminateMember(memberID, endDate){
        await apiClient.delete("/member/terminateMember?id="+memberID+'&end='+endDate);
    },
    //update assignments. if the item is already found it is automatically updated instead of overwritten. 
    async assignProduct(member, product, start, end){
      if (end == null || end.length == 0)
      {end = '';}
      return await apiClient.post(`/member/assignProduct?memberID=${member}&productID=${product}&start=${start}&end=${end}&visitLimit=false`);
    },
    async assignUniqueID(member, id){
      const data = await apiClient.post(`/member/assignUniqueID?memberID=${member}&uniqueID=${id}`);
      return data.data;
    },
    async assignLanguage(member, language){
      const data = await apiClient.post(`/member/AssignLanguage?memberID=${member}&language=${language}`);
      return data.data;
    },
    async assignHubspotID(memberID, employerID, hubspotID=null, isNonReviveUser){
      const data = await apiClient.post(`/member/assignHubspotID?memberID=${memberID}&actor=${employerID}&hubspotID=${hubspotID}&isNonReviveUser=${isNonReviveUser}`);
      return data.data;
    },
    async assignProductByPrimaryMember(member, product, start, end){
      if (end == null || end.length == 0)
      {end = '';}
      const data = await apiClient.post(`/member/assignProductByPrimaryMember?memberID=${member}&productID=${product}&start=${start}&end=${end}&visitLimit=false`);
      return data.data;
    },
    async assignDoseformID(memberID, employerID){
      const data = await apiClient.post(`/member/assignDoseformID?memberID=${memberID}&employerID=${employerID}`);
      return data.data;
    },
    async downloadAllMembers(){
      return await apiUploadClient.get("/member/DownloadAllEmployerMembers", {
        responseType: 'blob'
      });
    }
  },
});
