import axios from "axios";
import Swal from "sweetalert2";

const auth0ApiClient = axios.create({
  baseURL: process.env.VUE_APP_AUTH0_AUDIENCE,
  headers: {
    "Content-Type": "application/json",
  },
});
const requestHandler = async(request) => {

    await axios.post(process.env.VUE_APP_AUTH0_TOKEN_URL, {
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      client_secret: process.env.VUE_APP_AUTH0_CLIENT_SECRET,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
      grant_type: 'client_credentials'
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function (response) {
      request.headers.Authorization = 'Bearer ' + response.data.access_token;
    })
    .catch(function (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
      return;
    });

    return request;

}

auth0ApiClient.interceptors.request.use(
    
  (request) => requestHandler(request),
  (err) => {
    return Promise.reject(err);
  }
);

auth0ApiClient.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return Promise.resolve(response);
    }else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (!error.isAxiosError && !error.response) {
      Swal.fire({
        title: "Error",
        text: "Service currently unavailable, please contact support or try again later.",
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
    }
    if (error.response.status == 500) {
      Swal.fire({
        title: "Error",
        text: "Error received please contact support.",
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
      return Promise.reject(error.response.data);
    }
    else if (error.response.status == 404) {
      Swal.fire({
        title: "No Results",
        text: error.response.data.message,
        icon: "info",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
      return Promise.reject(error.response.data);
    }
    else if (error.response.status == 409) {
      Swal.fire({
        title: "Conflict",
        text: error.response.data.message,
        icon: "warning",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
       return Promise.reject(error.response.data);
     }
    else if (error.response.status == 400) {
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
      return Promise.reject(error.response.data);
    }
  }
);
export default auth0ApiClient;
