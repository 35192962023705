import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    userID: ""
  }),
  getters: {

  },
  actions: {
    async getUser(id) {
      let data = await apiClient.get(`/user/getUser?id=${id}`);
      return data.data;
    },
    async getUserByAuthZeroID() {
      let data = await apiClient.get(`/user/getUserByAuthZeroID?authZeroID=${this.userID}`);
      return data.data;
    },
    async getAllUsersInDownpath() {
      let data = await apiClient.get(`/user/getAllUsersInDownpath?authZeroID=${this.userID}`);
      return data.data;
    },
    async getAllUsersInDownpath2(page,pageSize,sortBy,sortDesc,search) {
      let data = await apiClient.get(`/user/getAllUsersInDownpath2?authZeroID=${this.userID}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${search}`);
      return data.data;
    },
    async createUser(user){
      const data = await apiClient.post("/user/createUser", user);
      return data.data;
    },
    async updateUser(id, user){
      const data = await apiClient.put(`/user/updateUser?id=${id}`, user);
      return data.data;
    },
    async deleteUser(id){
      await apiClient.delete(`/user/deleteUser?id=${id}`);
    },
    async UnDeleteUser(id){
      await apiClient.delete(`/user/unDeleteUser?id=${id}`);
    }
  },
});