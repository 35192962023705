import axios from "axios";
import Swal from "sweetalert2";
import { getInstance } from '../auth/index';

const fileClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data;",
  },
});
const requestHandler = async(request) => {
    const authService = getInstance(); 
   const token = await authService.getTokenSilently();
   request.headers.Authorization = 'Bearer ' + token;
    return request;
}
//TODO:Implment error catching for local storage being empty
fileClient.interceptors.request.use(
    
  (request) => requestHandler(request),
  (err) => {
    return Promise.reject(err);
  }
);

fileClient.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
  
    if (!error.isAxiosError && !error.response) {
      Swal.fire({
        title: "Error",
        text: "Service currently unavailable, please contact support or try again later.",
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
    }
    if (error.response.status == 500) {
      console.log('error.response: ', error.response);
      if(error.response.config.url.indexOf('member') > -1){

        return Promise.reject(error.response.data);

      }else {
      Swal.fire({
        title: "Error",
        text: "Error received please contact support.",
        icon: "error",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#757575",
        confirmButtonText: "Ok",
        showCancelButton: false,
      });
      return Promise.reject(error.response);}
    }
    else if (error.response.status == 404) {
      if(error.response.config.url.includes('report') || error.response.config.url.indexOf('member') > -1 || error.response.config.url.includes("MappingUploadSetting") || error.response.config.url.includes("getDashboardMapReport") || error.response.config.url.includes('/user/getAllUsersInDownpath2')){
        return Promise.reject(error.response.data);
      } else {
        Swal.fire({
          title: "No Results",
          text: typeof error.response.data === 'object' ? error.response.data.message : error.response.data,
          icon: "info",
          confirmButtonColor: "#28a745",
          denyButtonColor: "#757575",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });

        return Promise.reject(error.response);
      }
    }
    else if (error.response.status == 409) {
      console.log('error.response: ', error.response);
      if(error.response.config.url.indexOf('employer/assignHubspotID?employerID') > -1 || error.response.config.url.indexOf('chargify/CreateEmployerSubscription?employerID') > -1 || error.response.config.url.indexOf('member/createMember?employerID') > -1){
        return Promise.reject(error.response);
      }
      else if(error.response.config.url.indexOf('member/assignHubspotID?memberID') > -1 || error.response.config.url.indexOf('chargify/CreateEmployerSubscription?employerID') > -1 || error.response.config.url.indexOf('member/createMember?employerID') > -1){
        return Promise.reject(error.response.data);
      }
      else if(error.response.config.url.indexOf('employer') > -1 || error.response.config.url.indexOf('member') > -1){
        Swal.fire({
            title: "Conflict",
            text: typeof error.response.data == 'object' ? error.response.data.message : error.response.data,
            icon: "warning",
            confirmButtonColor: "#28a745",
            denyButtonColor: "#757575",
            confirmButtonText: "Ok",
            showCancelButton: false,
          });
        return Promise.reject(error.response.data);
      }else{
        return Promise.reject(error.response.data);
      }
     }
    else if (error.response.status == 400) {
      if(error.response.config.url.includes('report') || error.response.config.url.indexOf('member') > -1 || error.response.config.url.indexOf('employer') > -1){
        return Promise.reject(error.response.data);
      }else {
        Swal.fire({
          title: "Error",
          text: error.response.data.title,
          icon: "error",
          confirmButtonColor: "#28a745",
          denyButtonColor: "#757575",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
        return Promise.reject(error.response);
      }
    }
  }
);
export default fileClient;
