<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>Resource Library</v-card-title>
      <v-card-text>
        <v-alert dismissible type="info" v-if="!loading && resourceStore.documentList.length < 1">
            There are no resources visible for you yet. Please check back soon!
        </v-alert>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model.trim="search" label="Search" placeholder="Search Title, Description, and Material Type" clearable></v-text-field>
            </v-col>
        </v-row>
        <v-row v-for="item in resourceList" :key="item.listType">
            <v-col cols="12" elevation="12">
                <span class="text-h4 primary--text">{{item.listType}}</span>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col md="3" xl="2" lg="3" sm="4" xs="4" v-for="doc in item.documents" :key="doc.name" >
                    
                        <v-card>
                            <v-tooltip bottom v-if="checkIfDateIsLessThan30Minutes(doc.timeCreated)">
                                <template v-slot:activator="{ on, attrs }">
                                     <v-icon v-bind="attrs" v-on="on" large color="blue" style="position: absolute; top:0px; right:0px">new_releases</v-icon>
                                </template>
                                <span v-if="checkIfDateIsLessThan30Minutes(doc.timeCreated)">New Document Available</span>
                              
                            </v-tooltip>
                           
                            <v-card-text class="text-center" >
                                
                                <v-row>
                                    <v-col cols="12" style="height: 150px;">
                                        <v-tooltip bottom v-if="doc.properties.publicDocumentInformation">
                                            <template v-slot:activator="{ on, attrs }">
                                                <img v-bind="attrs" v-on="on" :src="'data:image/jpg;base64,'+doc.thumbnailData" alt="document thumbnail"  width="100%" style="max-height: 140px;"/>
                                            </template>
                                            <!-- <span>{{!checkIfDateIsLessThan30Minutes(doc.timeCreated) && checkIfDateIsLessThan30Minutes(doc.timeLastModified) ? doc.properties.publicUpdateInformation : doc.properties.publicDocumentInformation }}</span> -->
                                            <span>{{ doc.properties.publicDocumentInformation}}</span>
                                        </v-tooltip>
                                        <img v-else :src="'data:image/jpg;base64,'+doc.thumbnailData" alt="thumbnail" width="100%" style="max-height: 140px;"/>
                                    </v-col>
                                    <v-col cols="12" class="text-center" style="height: 75px;">
                                        <span >{{(doc.title && doc.title.length > 0 ? doc.title : doc.name)}}</span>
                                        <!-- <br />
                                        <span>Version: {{doc.majorVersion}}</span> -->
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn @click="downloadPDF(doc.__metadata.uri, doc.name)" class="ma-2" color="primary">Download</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useEmployerStore } from "../../stores/employer";
import { useLookupStore } from "../../stores/lookup";
import { useResourceStore } from "../../stores/resource";
import { checkIfDateIsLessThan30Minutes } from "../../commons/date";

export default {
  name: "ResourcesList",
  setup() {
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const resourceStore = useResourceStore();

    return {
      employerStore,
      lookupStore,
      resourceStore,
      checkIfDateIsLessThan30Minutes,
    };
  },
  data() {
    return {
        loading: true,
        search: null,
    }
  },
  computed: {
    resourceList: {
        get(){
            if (this.resourceStore.documentList.length < 1)
            {return [];}
            else
            {
                let returnArray = [];
                let first = true;
                let mutateableArray = [...this.resourceStore.documentList];
                //order the list by date
                mutateableArray.sort(function(a,b){
                    return new Date(b.timeLastModified) - new Date(a.timeLastModified);
                });
                mutateableArray.forEach((item) => {
                    //check if there is anything to search 
                    let addToList = false;
                    if (this.search && this.search.length > 2)
                    {
                        const lowerSearch = this.search.toLowerCase();
                        //checking title, name, and description.
                        
                        if ((item.name && item.name.toLowerCase().includes(lowerSearch)) || (item.title && item.title.toLowerCase().includes(lowerSearch)) || 
                        (item.properties && item.properties.materialType.toLowerCase().includes(lowerSearch)) ||
                        (item.properties && item.properties.publicDocumentInformation && item.properties.publicDocumentInformation.toLowerCase().includes(lowerSearch))
                        ) 
                        {
                            addToList = true;
                        }
                    }
                    else {
                        addToList = true;
                    }
                    //now create and add list
                    if (addToList)
                    {
                        if (item.properties && item.properties.materialType)
                        {
                            if (first)
                            {
                                let add = {
                                    listType: item.properties.materialType, documents: []
                                }
                                add.documents.push(item);
                                returnArray.push(add);
                                first = false;
                            }
                            else{
                                let found = returnArray.find(x => x.listType == item.properties.materialType);
                                if (found)
                                {found.documents.push(item);}
                                else
                                {
                                    let add = {
                                    listType: item.properties.materialType, documents: []
                                    }
                                    add.documents.push(item);
                                    returnArray.push(add);
                                }
                            }
                        }
                    }
                })
                returnArray.sort((a, b) => a.listType.localeCompare(b.listType))
                return returnArray;
            }
        }
    }
  },    
  methods: {
    downloadPDF: function(documentURL, documentName){
        this.resourceStore.getDocumentForDownload(documentURL).then((result) => {
            let url = window.URL.createObjectURL(new Blob([result]));
            let link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", documentName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        
    }
  },

  mounted() {
    this.resourceStore.getDocumentsByEmployerType(this.employerStore.currentEmployerType, this.employerStore.currentCustomerType).then(() => {
        this.loading = false;
    });
  },
};
</script>
