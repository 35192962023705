<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title> Add New User</v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col md="6">
                  <v-text-field v-model="user.firstName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'First Name is required']" label="First Name" required>
                  </v-text-field>
                </v-col>

                <v-col md="6">
                  <v-text-field v-model.trim="user.lastName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'Last Name is required']" label="Last Name" required>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-text-field v-model.trim="user.email" :rules="[ (v) => !!v || 'E-mail is required', (v) => /^\w+([.-]?\w+)*@[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]{1,})+$/.test(v) || 'E-mail must be valid' ]" label="E-mail Address" required>
                  </v-text-field>
                </v-col>

                <v-col md="4">
                  <label for="phone">Phone Number</label>
                  <vue-tel-input id="phone" v-model.trim="user.phonePrimary" @input="onPrimaryPhoneObject"/>
                  <div v-if="user.phonePrimary">
                    <small v-if="!primaryPhoneObject.valid" class="error--text">
                      Primary Phone Number is not valid
                    </small>
                  </div>
                </v-col>

                <v-col md="4">
                  <label for="phoneSecondary">Secondary Phone</label>
                  <vue-tel-input id="phoneSecondary" v-model.trim="user.phoneSecondary" @input="onSecPhoneObject" />
                  <div v-if="user.phoneSecondary">
                    <small v-if="!secPhoneObject.valid" class="error--text">Secondary Phone number is not valid</small>
                  </div>
                </v-col>
              </v-row>
<!-- string.replace(/^\s+|\s+$/g, ""); -->

              <v-row>
                <v-col md="6">
                  <v-select v-model.trim="user.selectedEmployer" :items="employerStore.employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']" :label="'Select ' + employerStore.fieldLabelSettings.groupLabel" required></v-select>
                </v-col>
                <v-col md="6">
                  <v-select v-model.trim="user.selectedRole" :items="rolesList" :item-text="(item) => item.name" :item-value="(item) => item.id" label="Role" :rules="[(v) => !!v || 'Role is required']" required></v-select>
                </v-col>
              </v-row>

            </v-form>
          </v-col>
          <v-col md="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="createUser">Save</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="$router.push({ name: 'user_admin_index' })">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useUserStore } from "../../stores/user";
import { useEmailStore } from "../../stores/email";
import { useEmployerStore } from "../../stores/employer";
import { useLookupStore } from "../../stores/lookup";
import { useAuth0Store } from "../../stores/auth0";
import { convertToAuth0UserObject, convertToUserObjectForDatabase } from "../../commons/user";
import { roles } from "../../data/definitions/roles";

export default {
  name: "NewEmployerComponent",
  setup() {
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const auth0Store = useAuth0Store();
    const emailStore = useEmailStore();
    const userStore = useUserStore();
    const authStore = useAuthStore();

    return { employerStore, lookupStore, auth0Store, emailStore, userStore, authStore };
  },
  data() {
    return {
      overlay: false,
      primaryPhoneObject: { valid: false },
      secPhoneObject: { valid: false },
      valid: true,
      user: {
        email: "",
        phonePrimary: "",
        phoneSecondary: "",
        firstName: "",
        lastName: "",
        selectedEmployer: "",
        selectedRole: "",
      },
      isAdmin: false,
      rolesList: [],
      isDirty: false
    };
  },
  methods: {
    preventReload(event) {
      /* the beforeunload event functions based on the value of isDirty. 
       if false, event would run but if true, user would receive a prompt message when reloading page */
      if (!this.isDirty) return
      event.preventDefault()
      event.returnValue = ""
    },
    onPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.primaryPhoneObject = phoneObject;
    },
    onSecPhoneObject(formattedNumber, phoneObject) {
      this.secPhoneObject = phoneObject;
    },
    createUser(showSwal=true) {
      let primaryPhoneValidation = !this.primaryPhoneObject.valid
        ? "- Primary Phone Number is Required"
        : "";
      if (primaryPhoneValidation) {
        this.user.phonePrimary = "+1";
      }
      let swal = (validation) => {
        let formValidate = validation == this.$refs.form._data.inputs ? true : false;
        let phoneNum = formValidate == true ? primaryPhoneValidation : "";
        let secondaryPhoneValidation = this.user.phoneSecondary && !this.secPhoneObject.valid ? "<br/> - Secondary Phone Number is Invalid" : "";

        this.$swal({
          title: "Error",
          icon: "error",
          html: (
            phoneNum + secondaryPhoneValidation +
            Object.keys(validation).map((key) => {
              if (validation[key].validations) {
                if (validation[key].validations.length > 0) {
                  return "<br>" + "- " + validation[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      };

      if (!this.$refs.form.validate() || !this.primaryPhoneObject.valid || this.user.phoneSecondary != '' && !this.secPhoneObject.valid) {
        return swal(this.$refs.form._data.inputs);
      }

      //toggle overlay to true
      this.overlay = true;

      //isDirty has to be set to false else the prompt message would show up when the route is about to change 
      this.isDirty = false;

      let userObject = convertToAuth0UserObject(this.user.email, this.user.firstName, this.user.lastName, false);
      
      //save to auth0
      this.auth0Store.createUser(userObject).then((res) => {

        //assign role (auth0)
        if(this.user.selectedRole){
          this.auth0Store.assignRole(res.user_id, { roles: this.user.selectedRole.split() });
        }

        //save to database
        if(res.identities[0].user_id){
          let user = convertToUserObjectForDatabase(this.user.firstName, this.user.lastName, this.user.email, this.user.phonePrimary, this.user.phoneSecondary, this.user.selectedEmployer, res.identities[0].user_id, this.isAdmin, true, false);
          this.userStore.createUser(user)
          .then((userResponse) => {
            // send welcome email
            this.emailStore.sendWelcomeEmail(this.user.email, userObject.password, this.user.firstName, window.location.origin + "/login").then(() => {
              this.overlay = false;
                if(showSwal) {
                  return this.$swal({
                    title: "Saved",
                    icon: "success",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "Continue",
                    denyButtonText: "Add Another",
                    buttons: true,
                    showDenyButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.$router.push({ name: "user_admin_index" });
                    } else if (result.isDenied) {
                      this.$router.go();
                    }
                  });
                }else {
                  this.$router.push({ name: "user_admin_index" });
                  showSwal = true;
                }
            }).catch(() => {
              this.overlay = false
              this.userStore.deleteUser(userResponse.userID);
              this.auth0Store.deleteUser(res.user_id);
            });
          })
          .catch((err) => {
            this.overlay = false;
            //if there's an error while adding to database, delete the user record from auth0
            this.auth0Store.deleteUser(res.user_id);

            this.$swal({
              title: "Error",
              icon: "error",
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              confirmButtonText: "Try Again",
              html: Object.keys(err.data.errors).map((key) => { return "<br>" + "- " + err.data.errors[key][0]; })
            })
          });
        }
      });
    }
  },
  watch: {
    // using a deep watcher here helps to trigger this callback on a nested user mutation. Whenever a mutation occurs, the value of isDirty is set to true
    user: {
      handler: function(){
        this.isDirty = true
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) { this.createUser(false) }
        else if(result.isDismissed){ next(); }
        else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  }, 
  mounted() {
    this.employerStore.getAllEmployersByActor();
  
    this.auth0Store.getAllRoles().then((res) => {

      const found = this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin);
      if(found){
        this.isAdmin = true;
        this.rolesList = res;
      }else {
        this.rolesList = [...res.filter((x) => !x.name.includes("Revive"))];
      }

    });
  },
};
</script>
