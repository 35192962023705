<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="end">
      <v-btn v-if="employerStore.billingSettings.BillingInfoCompleted && (employerStore.isFromSelfService || (employerStore.billingSettings.billingMethod == 'individualBilling' && employerStore.currentEmployerFull.documentURL) || employerStore.billingSettings.billingMethod != 'individualBilling')" depressed color="primary" @click="$router.push({ name: 'subscriber_admin_new'})">
        <v-icon class="mr-1">mdi-account-plus</v-icon>
        Add {{employerStore.fieldLabelSettings.subscriberLabel}}
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="mt-4" v-if="!employerStore.billingSettings.BillingInfoCompleted">
            <v-alert border="left" dismissible type="info">
              You cannot add {{employerStore.fieldLabelSettings.subscriberLabel}} until billing information has been added
            </v-alert> 
          </div>
          <div class="mt-4" v-else-if="employerStore.billingSettings.billingMethod == 'individualBilling' && !employerStore.currentEmployerFull.documentURL && !employerStore.isFromSelfService">
            <v-alert border="left" dismissible type="info">
              You cannot add {{employerStore.fieldLabelSettings.subscriberLabel}} until the contract has been uploaded.
            </v-alert>
          </div>
          <v-card-title>
            {{employerStore.fieldLabelSettings.secondarySubscriberLabel}}
            <v-spacer></v-spacer>
            <!-- start export component -->
            <v-btn class="mr-5" v-if="memberListing.length" @click="downloadCSV(memberListing)">
                Download Current View
                <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <v-btn class="mr-5" v-if="allMembers.length" @click="downloadAll()">
              <v-progress-circular v-if="isDownloadingAll" indeterminate size="25" style="margin-right: 10px;"></v-progress-circular>
              Download All
              <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <!-- end export component -->
            <v-switch v-model.trim="filters.showInactiveMembers" label="Show Inactive Members" class="pa-3"></v-switch>
            <v-switch v-model.trim="filters.showDependents" label="Show Dependents" class="pa-3"></v-switch>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field @keyup.enter="pageLoad" :label="'Search for ' + employerStore.fieldLabelSettings.subscriberLabel" v-model.trim="search">
                  <template v-slot:append-outer>
                    <v-btn class="mr-2" @click="pageLoad">
                      Search
                      <v-icon class="ma-2">search</v-icon>
                    </v-btn>
                    <v-btn @click="resetSearch" color="primary">
                      Reset
                      <v-icon class="ma-2">restore</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <!-- <v-data-table :items="memberListing" :headers="headers" :search="search" :loading="!memberListing.length" loading-text="Loading... Please wait" multi-sort> -->
                  <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="memberListing"
                  :options.sync="options"
                  :server-items-length="totalMembers"
                  :loading="loading"
                  :footer-props="{
                    'items-per-page-options': rowsPerPage
                  }"
                >

                  <template v-slot:no-data>
                    No Results Found. Please try removing filters or expanding your search.
                  </template>
                  
                  <template v-slot:[`item.dateOfBirth`]="{ item }">{{ item.dateOfBirth }}</template>
                  <template v-slot:[`item.isDeleted`]="{ item }">
                    {{ item.isDeleted || item.isActive == false ? 'No' : 'Yes' }}
                  </template>
                  <template v-slot:[`item.isActive`]="{ item }">
                    <span v-if="item.memberType == 'Child'">N/A</span>
                    <span v-else>
                      {{ item.isActive ? 'Yes' : 'No' }}
                    </span>
                  </template>
                  <template  v-if="employerStore.billingSettings.BillingInfoCompleted" v-slot:[`item.actions`]="{ item }">
                    <div v-if="!item.isDeleted && item.productCount > 0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on" @click=" $router.push({ name: 'subscriber_admin_edit', query: { memberID: item.parentID ? item.parentID : item.memberID } })">
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="deleteMember(item)">cancel</v-icon>
                        </template>
                        <span>Deactivate Immediately - To set a future deactivation edit the products to have an end date.</span>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="$router.push({ name: 'subscriber_admin_view', query: { memberID: item.memberID } })">
                            mdi-eye
                          </v-icon>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="restoreMember(item)">restore</v-icon>
                        </template>
                        <span>Restore</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn v-if="employerStore.billingSettings.BillingInfoCompleted && (employerStore.isFromSelfService || (employerStore.billingSettings.billingMethod == 'individualBilling' && employerStore.currentEmployerFull.documentURL) || employerStore.billingSettings.billingMethod != 'individualBilling')" color="primary" class="ma-2" @click="dialog = true">
                  Upload Enrollment File
                </v-btn>
              </v-col>
            </v-row>
            <v-dialog width="80%" v-model.trim="dialog" persistent>
              <v-card flat style="position:relative">
                <v-overlay :value="isDialogLoading" absolute>
                  <v-progress-circular
                    indeterminate
                    size="48"
                  ></v-progress-circular>
                </v-overlay>
                <v-overlay :value="uploadingData" absolute>
                  <v-progress-circular
                    indeterminate
                    size="48"
                  ></v-progress-circular>
                </v-overlay>
                <v-card-title>
                  <v-row>
                    <v-col cols="4">
                      <span class="secondary--text">Upload Enrollment File</span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="9">
                      <v-autocomplete :label=" productList.length > 0 ? `To re-enable ${employerStore.fieldLabelSettings.groupLabel} selection please click clear below.` : `Select ${employerStore.fieldLabelSettings.groupLabel}`" 
                        v-model.trim="selectedEmployer" :items="employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" clearable @change="getListingSetup()" :disabled="productList.length > 0"></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-select v-model.trim="uploadType" :items="types" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Upload Type"></v-select>
                    </v-col>
                    <v-alert border="left" dismissible type="warning" v-if="uploadType == 'FullUpload'">
                      When uploading a full file any {{employerStore.fieldLabelSettings.subscriberLabel}} or {{employerStore.fieldLabelSettings.dependentLabel}} not found in this file that currently exist will have their benefits terminated and will be deactivated.
                    </v-alert>
                    <v-alert border="left" dismissible type="info" v-if="uploadType == 'EditOnly'">
                      You are uploading an Edit Only file. Members will not be terminated through this file upload.
                    </v-alert>
                  </v-row>
                  <v-row v-if="productList.length > 0">
                    <v-col cols="10">
                      <v-file-input show-size label="Enrollment File" accept=".csv,.txt" truncate-length="50" :disabled="clicked" v-model.trim="enrollmentFile"></v-file-input>
                    </v-col>
                    <v-col cols="2" v-if="enrollmentFile">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="accept" class="white--text ma-2" v-bind="attrs" v-on="on" @click="previewFile" :disabled="clicked">Preview File</v-btn>
                        </template>
                        <span>Preview And Validate File Before Submitting.</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
          
                  <v-row v-if="uploadComplete">
                      <v-col cols="12">
                          <span class="primary--text subtitle-1">Template Test Results</span> 
                      </v-col>   
                      <v-col cols="12">
                          <v-data-table
                              :items="tableResult"
                              :headers="activeHeader"
                          ></v-data-table>
                      </v-col>
                  </v-row>

                  <v-row v-if="isValidationCompleted">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="4">
                          <span>Total Families: {{ totalFamilies }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>Total Valid Families: {{ totalValidFamilies }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>Total Invalid Families: {{ totalInvalidFamilies }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>Total People: {{ totalPeople }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>Total Valid People: {{ totalValidPeople }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>Total Invalid People: {{ totalInvalidPeople }}</span>
                        </v-col>
                        <v-col cols="10">
                          <span>Errors: 
                            <ul>
                              <li v-for="item in validationErrors" :key="item">
                                {{item}}
                              </li>
                            </ul>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn color="blue" dark class="ma-2" v-if="isValidationCompleted" @click="uploadToConsoleApp()">Upload file</v-btn>
                      <v-btn color="blue" dark class="ma-2" v-if="isFileAttached && !isValidationCompleted" @click="validateBeforeUpload()">Validate</v-btn>

                      <v-btn color="cancel" dark class="ma-2" @click="clearSet(); pageLoad(); dialog = false">Close</v-btn>
                      <v-btn color="blue" dark class="ma-2" @click="clearSet()">Clear</v-btn>
                      <v-btn color="blue" dark class="ma-2" @click="upload()" v-if="clicked && uploadable > 0 && !uploadComplete">Upload</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useMemberStore } from "../../stores/member";
import { useEmployerStore } from "../../stores/employer";
import { useProductStore } from "../../stores/product";
import { useLookupStore } from "../../stores/lookup";

import { cleanDate, cleanISOTime } from "../../commons/date";
import { getMemberTypeName, getGenderName } from "../../commons/member";
import { DateTime } from "luxon";
import { lookupTypes } from '../../data/definitions/lookupTypes';
import { download } from "../../commons/employer";
export default {
  name: "MemberListing",
  data: () => ({
    rowsPerPage: [5,10,15,50,100],
    overlay: false,
    genderTypes: ['M', 'm', 'F', 'f', 'N', 'n'],
    panel: [0, 1],
    //general items
    types: [ { text: "Full File", value: "FullUpload" }, { text: "Edits Only File", value: "EditOnly" } ],
    clicked: false,
    filters: { showInactiveMembers: false, showDependents: false },
    search: "",
    dialog: false,
    validationComplete: false,
    uploadComplete: false,
    //employer items
    selectedEmployer: "",
    //product items
    productList: [],
    hasProducts: false,
    //member items
    memberListing: [],
    enrollmentList: [],
    enrollmentFile: null,
    exisitingEmployerMembers: [],
    removeList: [],
    allMembers: [],
    //error list
    uploadType: "",
    uploadable: 0,
    emailAddresses: [],
    uploadList: [],
    //loadingscreen
    isDialogLoading: false,
    uploadingData: false,
    isDownloadingAll: false,

    validationErrors: [],
    totalFamilies: 0,
    totalValidFamilies: 0,
    totalInvalidFamilies: 0,
    totalPeople: 0,
    totalValidPeople: 0,
    totalInvalidPeople: 0,
    showPreview: false,
    isValidationCompleted : false,
    isFileAttached : false,

    loading: true,
    options: {},
    totalMembers: 0,

    fileResults: null,
    useFileHeaders: true,
    mappingArrayNaming: [],
    allColumns:[],
    activeColumns: [],
    loadingTemplateCompleted: false,
    mappingFile: null,
  }),
  setup() {
    const memberStore = useMemberStore();
    const productStore = useProductStore();
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    return { memberStore, employerStore, productStore, lookupStore, cleanISOTime, cleanDate, getMemberTypeName };
  },
  computed: {
    tableResult: {
        get(){
          let returnArray = [];
          if (this.fileResults && this.fileResults.data && this.fileResults.data.length > 0)
          {
            this.fileResults.data.forEach((x) => {
              let newEntry = {};
              if(this.useFileHeaders){
                  this.activeHeader.forEach((item) => {
                      newEntry[item.value] = x[item.value];
                  })
              }
              else{
                  this.activeHeader.forEach((item) => {
                    let index = item.value.replace(/^\D+/g, '');                                
                    newEntry[item.value] = x[index - 1];
                  })
              }
              
              returnArray.push(newEntry);
            })
          }
          return returnArray;
      }
    },
    activeHeader:{
        get(){
          let result = [];
          this.allColumns.forEach( (x) => {
            if(this.mappingArrayNaming.includes(x)){
              let selectedColumn = this.activeColumns.filter((item) => {
                  return item.columnName == x;
              });

              if(selectedColumn.length > 0){
                  result.push({
                    text: selectedColumn[0].mapping,
                    value: selectedColumn[0].columnName
                  });                                
              }
            }
          });
          return result;
      }
    },

    validatedList() {
      if (this.showOnlyErorrs)
      {
        let returnArray = [];
        this.enrollmentList.forEach((x) => {
          if (!x.isValid)
          {
            returnArray.push(x);
          }
          else
          {
            let find = x.dependents.find(d => d.isValid == false);
            if (find)
            {
              returnArray.push(x);
            }
          } 

        });
        return returnArray;
      }
      else
      {
        return this.enrollmentList;
      }
    },
    
    employerList() {
      return this.employerStore.filteredEmployersList;
    },
    csvHeaders: {
      get() {
        return [
          this.employerStore.fieldLabelSettings.groupLabel +' Name', 'Parent '+ this.employerStore.fieldLabelSettings.groupLabel +' name', 'Product Count', this.employerStore.fieldLabelSettings.subscriberLabel + ' ID', 'First Name', 'Last Name', 'Middle Name', 'Email', 'Primary Phone Number', 'Secondary Phone Number', 'Gender', 'Date of Birth', this.employerStore.fieldLabelSettings.subscriberLabel  + ' Type', 'Primary Street', 'Primary Street 2', 'Primary City', 'Primary Region', 'Primary Postal', 'Primary Country'
        ]
      }
    },
    csvColumns: {
      get() {
        return ['employerName', 'parentEmployerName', 'productCount', 'memberID', 'firstName', 'lastName', 'middleName', 'email', 'phonePrimary', 'phoneSecondary', 'gender', 'dateOfBirth', 'memberType', 'primaryStreet', 'primaryStreetTwo', 'primaryCity', 'primaryRegion', 'primaryPostal', 'primaryCountry']
      }
    },
    headers: {
      get() {
        return [
          { text: "First Name", value: "firstName" },
          { text: "Last Name", value: "lastName" },
          { text: "Full Name", value: "fullName", align: ' d-none' },
          { text: "Email", value: "email" },
          { text: "Phone", value: "phonePrimary" },
          { text: "Date of Birth", value: "dateOfBirth" },
          { text: this.employerStore.fieldLabelSettings.groupLabel, value: "employerName" },
          { text: `Parent ${this.employerStore.fieldLabelSettings.groupLabel}`, value: "parentEmployerName" },
          { text: "Active", value: "isDeleted" },
          { text: "Registered", value: "isActive" },
          { text: "Actions", value: "actions", sortable: false },
        ]
      }
    }
  },

  mounted() {
    this.employerStore.filterEmployersByActor();
    //this.pageLoad();
    this.lookupStore.getGenderList();
    this.lookupStore.getMemberTypeList();
  },
   watch: {
    filters: {
      handler: function(){
        this.options.page = 1;
        this.options.pageSize = 10;
        this.options.sortBy = []; 
        this.options.sortDesc = [];
        this.pageLoad()
      },
      deep: true,
    },
    options: {
        handler () {
          this.pageLoad()
        },
        deep: true,
      }
   },
  methods: {
    resetSearch(){
      this.search = "";
      this.options.page = 1;
      this.options.pageSize = 10;
      this.options.sortBy = []; 
      this.options.sortDesc = [];
    },
    downloadCSV(membersData) {
      let members = membersData.map(member => [ member.employerName, member.parentEmployerName, member.productCount, member.memberID, member.firstName, member.lastName, member.middleName, member.email, member.phonePrimary, member.phoneSecondary, member.gender, member.dateOfBirth, member.memberType, member.primaryStreet, member.primaryStreetTwo, member.primaryCity, member.primaryRegion, member.primaryPostal, member.primaryCountry ]);
      const csv = this.$papa.unparse({fields: this.csvHeaders, data: members}, {columns: this.csvColumns});
      // download function
      download(csv, this.employerStore.fieldLabelSettings.secondarySubscriberLabel + ' List.csv');
    },
    downloadAll(){
      this.isDownloadingAll = true
      this.memberStore.downloadAllMembers()
      .then( (response) => {
          download(response.data, "All Members.csv");
      }).finally( () => this.isDownloadingAll = false);
    },
    //#region LoadPage
    pageLoad(){
      
      const { page, itemsPerPage, sortBy, sortDesc} = this.options;

      if(sortDesc[0] != true && sortDesc[0] != false){
        sortDesc[0] = false;
      }

      this.loading = true;
      this.overlay = true;

      this.memberStore.getMemberListingDetailsByUserID2(page-1, itemsPerPage,this.filters.showInactiveMembers,this.filters.showDependents,lookupTypes.memberType.primary, sortBy, sortDesc[0],this.search).then((result) => {

        let memberListing = result.memberFullDetails.map((res) => ({
          ...res,
          fullName: `${res.firstName} ${res.lastName}`,
          dateOfBirth: cleanDate(res.dateOfBirth),
          gender: getGenderName(res.gender),
          memberType: getMemberTypeName(res.memberType)
        }));
        this.totalMembers = result.totalCount;
        let members = memberListing;
        this.memberListing = members;
        
        //export all list
        this.allMembers = memberListing;
      }).catch(() => {
        this.memberListing = [];
      })
      .finally(() => {
        this.loading =  false;
        this.overlay = false;
      })
    },
    //#endregion
    //#region Delete Member
    deleteMember(member)
    {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: (member.parentID ? ' You are about to deactivate this ' +this.employerStore.fieldLabelSettings.subscriberLabel : 'This is a Primary '+this.employerStore.fieldLabelSettings.subscriberLabel+'. Deactivating this '+this.employerStore.fieldLabelSettings.subscriberLabel+' will deactivate all of its ' + this.employerStore.fieldLabelSettings.secondaryDependentLabel) + '. Do you wish to continue?',        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.memberStore.deleteMember(member).then(() => { this.pageLoad(); })
        }
      });
    },
    restoreMember(member)
    {
      //check if parent exists and if parent is active.
      if (member.parentID) {
        const parent = this.memberListing.find(x => x.memberID == member.parentID);
        if (parent != null && parent != undefined && parent.isDeleted) {
          this.$swal({
            title: "Warning",
            icon: "warning",
            text: 'Reactivating this record will activate the primary as well. Are you sure you wish to continue?',
            buttons: true,
            showDenyButton: true,
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            denyButtonColor: this.$vuetify.theme.themes.light.deny,
            confirmButtonText: "Continue",
            denyButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              this.memberStore.UnDeleteMember(parent).then(() => { this.pageLoad(); })
            }
          });
        }
        else {
          this.memberStore.UnDeleteMember(member).then(() => { this.pageLoad(); })
        }
      }
      else {
          this.memberStore.UnDeleteMember(member).then(() => { this.pageLoad(); })
        }

      
    },
    //#endregion

    //Get the list of products availble to upload items to. Sets has products. User cannot upload items if there are no products.
    //Get the list of exisiting members for employer.
    getListingSetup() {
      if (this.selectedEmployer.length > 0) {
        this.employerStore.getFullEmployerByID(this.selectedEmployer).then((res) => {
          //get member settings from list of objects
          let memberSettings = res.employerAttributes.find( (x) => x.attributeType == lookupTypes.settings.member );
          //parse the value using JSON.parse()
          let parsedMemberSettings = JSON.parse(memberSettings.attributeValue);
          // assign the value
          //check if value is null if it is set to both
          if (parsedMemberSettings.uploadType)
            this.uploadType = parsedMemberSettings.uploadType;
          else
            this.uploadType = 'both';
        });
        this.productStore.getProductStockByEmployer(this.selectedEmployer).then((res) => { this.productList = res.data; });
        this.memberStore.getMembersByEmployer(this.selectedEmployer).then((res) => {
          if (res.data)
            this.exisitingEmployerMembers = res.data;
          else
            this.exisitingEmployerMembers = [];
        }).catch(() => { this.exisitingEmployerMembers = []; });
      } else {
        this.productList = [];
        this.exisitingEmployerMembers = [];
      }
    },

    async getPreviousMapping(){
      this.isDialogLoading = true;
      this.clicked = true;    
      await this.employerStore
        .GetEmployerUploadSettings(this.selectedEmployer)
        .then((res) => {
            this.isDialogLoading = false;
            if(res){
                this.loadFileBasedOnEmployerMapping(res);
                this.uploadComplete = true;
                this.isFileAttached = true;
                this.isValidationCompleted = false; 
            }
            else{
              this.showNoTemplate();
            }
        }).catch((err) => {
            this.showNoTemplate();
            console.error("Error: ", err);
        });
    },
    
    showNoTemplate(){
      this.uploadComplete = true;
      this.isFileAttached = false;
      this.isValidationCompleted = false; 
      this.isDialogLoading = false;
      this.$swal({
        title: "The selected employer doesn't have upload template.",
        icon: "warning",
        text: "Please enter a employer upload template before uploading a file.",
        showDenyButton: false,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        confirmButtonText: "OK!",
      });
    },

    loadFileBasedOnEmployerMapping(res){
      this.activeColumns = JSON.parse(res.data.mappingColumns);
      this.uploadComplete = true;
      this.mappingType = (JSON.parse(res.data.mappingOptions)).mappingType;
      this.useFileHeaders = (JSON.parse(res.data.mappingOptions)).useFileHeaders;
      this.allColumns = (JSON.parse(res.data.allMappingColumns));

      this.loadingTemplateCompleted = true;

      if(this.mappingType == 1){
          this.flatFileColumns = JSON.parse(res.data.mapping);
      }
      else if(this.mappingType == 2){
          this.primaryAsRelationshipColumns = JSON.parse(res.data.mapping);
      }

      let selectedItems = [];
      this.allColumns.forEach( (x) => {
        let selectedColumn = this.activeColumns.filter((item) => {
            return item.columnName == x;
        });
        
        if(selectedColumn.length == 0){
            selectedItems.push(x);
        }
      });
          
      this.mappingArrayNaming = [...this.allColumns];

      this.$papa.parse(this.enrollmentFile, {
          header: this.useFileHeaders,
          skipEmptyLines: true,
          complete: (results) => { 
            let item = (JSON.stringify(results)).replace(/"\s*/g, '"');
            this.fileResults = JSON.parse(item);
          }
      });
    },
    
    previewUploadFile() {
      this.isDialogLoading = true;
      this.getPreviousMapping();
    },

    //resets and clears the upload form
    clearSet() {
      this.showPreview = false;
      this.isValidationCompleted = false;
      this.emailAddresses = [];
      this.clicked = false;
      this.productList = [];
      this.enrollmentFile = null;
      this.enrollmentList = [];
      this.selectedEmployer = "";
      this.validationComplete = false;
      this.exisitingEmployerMembers = [];
      this.uploadComplete = false;

      this.uploadList = [];
      this.totalFamilies = 0;
      this.totalInvalidFamilies = 0;
      this.totalInvalidPeople = 0;
      this.totalPeople = 0;
      this.totalValidFamilies = 0;
      this.totalValidPeople = 0;
      this.validationErrors = []; 
      this.isDialogLoading = false;
      this.isFileAttached = false;
      this.uploadType = "";
    },

    //preview file result set, performs same selection and preperation to send file. But as preview only.
    previewFile() {
      if(this.uploadType == "EditOnly" || this.uploadType == "FullUpload"){
        this.isDialogLoading = true;
        this.previewUploadFile();
      }
      else{
        this.$swal({
          text: "Please select upload type!",
          icon: "warning",
          showDenyButton: false,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          confirmButtonText: "OK!",
        });
      }
    },
    
    //validates product start and stop date. Eventually a call based on employer defaults will be required. For now this
    //will use the start of next month and end of next month as default.
    getProductDate(date, isStart) {
      let start = this.employerStore.memberSettings.startDate == 'currentDay' ? DateTime.now().toFormat("yyyy-MM-dd") : DateTime.now().plus({ month: 1 }).startOf("month").toFormat("yyyy-MM-dd");
      
      let productDate;
      if (!date)
        productDate = isStart ? DateTime.fromISO(start).toFormat("MM/dd/yyyy") : null;
      else
        productDate = DateTime.fromFormat(date, "M/d/yyyy").toFormat("MM/dd/yyyy");

      return productDate;
    },

    uploadToConsoleApp() {
      if(this.validationErrors && this.validationErrors.length > 0){
        this.$swal({
          title: "File contains invalid data!",
          icon: "warning",
          text: "Please fix the invalid data in the file attached and resubmit for upload!",
          showDenyButton: false,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          confirmButtonText: "OK!",
        });        
      }
      else{
        this.isDialogLoading = true;
        this.memberStore.uploadMemberThroughConsole(this.enrollmentFile, this.selectedEmployer, this.uploadType)
          .then((response) => {
            this.downloadUploadResponse(response);
            
          }).catch(error => {
            this.isDialogLoading = false;
            console.error("Error: ", error);
        });
      }
    },

    downloadUploadResponse(response){
      let responseFileUrl = window.URL.createObjectURL(new Blob([response.data]));
      let fileDownloader = document.createElement('a');
      fileDownloader.href = responseFileUrl;
      fileDownloader.setAttribute('download', 'output_' + this.enrollmentFile.name);
      document.body.appendChild(fileDownloader);  
      fileDownloader.click();

      this.$swal({
        title: "File upload was completed",
        icon: "success",
        text: "Please check the downloaded response file for detailed information about the upload.",
        showDenyButton: false,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        confirmButtonText: "OK!",
      });

      this.clearSet();
    },

    validateBeforeUpload(){
      this.isDialogLoading = true;
      this.showPreview = true;
      this.memberStore.ValidateMemberUploadFile(this.enrollmentFile, this.selectedEmployer)
        .then((response) => {
          this.totalFamilies = response.data.totalFamilies;
          this.totalValidFamilies = response.data.totalValidFamilies;
          this.totalInvalidFamilies = response.data.totalInvalidFamilies;
          this.totalPeople = response.data.totalPeople;
          this.totalValidPeople = response.data.totalValidPeople;
          this.totalInvalidPeople = response.data.totalInvalidPeople;
          this.validationErrors = response.data.errors;
          
          this.isValidationCompleted = true;
          this.isFileAttached = true;
          this.isDialogLoading = false;
        }).catch(error => {
          console.error("Error: ", error);
      });
    }
  },
};
</script>
<style scoped>
.itemRow {
  background: #cdcdcd;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:nth-child(3) {
  display: none !important;
}

.itemRow,
.itemRowDep {
  border-bottom: 1px solid #787878;
}

::v-deep  .v-data-footer {
  padding: 0 60px;
}
</style>
