<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="end">
      <v-btn depressed color="primary" @click="$router.push({ name: 'user_admin_new'})">
        <v-icon class="mr-1">mdi-account-plus</v-icon>
        Add User
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>User
            <v-spacer></v-spacer>
            <!-- start export component -->
              <v-btn class="mr-5" v-if="userList.length" @click="downloadCSV(userList)">
                Download
                <v-icon class="ma-2">download</v-icon>
              </v-btn>
            <!-- end export component --></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field @keyup.enter="loadUserTable" label="Search for User" v-model.trim="search">
                  <template v-slot:append-outer>
                    <v-btn class="mr-2" @click="loadUserTable">
                      Search
                      <v-icon class="ma-2">search</v-icon>
                    </v-btn>
                    <v-btn @click="resetSearch" color="primary">
                      Reset
                      <v-icon class="ma-2">restore</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="userList"
                  :options.sync="options"
                  :server-items-length="totalUsers"
                  :loading="loading"
                  :footer-props="{
                    'items-per-page-options': rowsPerPage
                  }"
                >
                  <template v-slot:no-data>
                    No Results Found. Please try removing filters or expanding your search.
                  </template>
                  <template  v-if="employerStore.memberSettings.profileEdit" v-slot:[`item.actions`]="{ item }">
                  <!-- edit action -->
                    <v-tooltip bottom v-if="!item.isDeleted">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ma-2" v-bind="attrs" v-on="on" @click=" $router.push({ name: 'user_admin_edit', query: { userID: item.userID } })">
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <!-- delete/deactive action -->
                    <v-tooltip bottom v-if="!item.isDeleted">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="deleteUser(item)">
                          cancel
                        </v-icon>
                      </template>
                      <span>Deactivate</span>
                    </v-tooltip>
                      <!-- Restore deleted member action -->
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="restoreUser(item)">
                          restore
                        </v-icon>
                      </template>
                      <span>Restore</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useUserStore } from "../../stores/user";
import { useAuth0Store } from "../../stores/auth0";
import { useLookupStore } from "../../stores/lookup";
import { useEmployerStore } from "../../stores/employer";
import { convertToAuthZeroID } from "../../commons/user.js";
import { download } from "../../commons/employer";
export default {
  name: "UserListing",
  data: () => ({
    rowsPerPage: [5,10,15,50,100],
    search: "",
    userList: [],
    loading: true,
    options: {},
    totalUsers: 0,
    overlay: false
  }),
  setup() {
    const userStore = useUserStore();
    const auth0Store = useAuth0Store();
    const lookupStore = useLookupStore();
    const employerStore = useEmployerStore();

    return { userStore, auth0Store, lookupStore, employerStore };
  },
  watch: {
    options: {
        handler () {
          this.loadUserTable()
        },
        deep: true,
      },
  },
  computed: {
    exportLabels: { 
      get() {
        return {
          firstName: { title: 'First Name' },
          lastName: { title: 'Last Name' },
          email: { title: 'Email' },
          employerID: { title: this.employerStore.fieldLabelSettings.groupLabel },
          phonePrimary: { title: 'Phone Primary' },
          phoneSecondary: { title: 'Phone Secondary' },
          isDeleted: { title: 'Deleted' },
          userID: { title: 'User ID' },
         
        };
      },
    },
    headers: {
      get() {
        return [
          { text: "Email", value: "email" },
          { text: "First Name", value: "firstName" },
          { text: "Last Name", value: "lastName" },
          { text: "Phone", value: "phonePrimary" },
          { text: this.employerStore.fieldLabelSettings.groupLabel, value: "parentName" },
          { text: "Actions", value: "actions", sortable: false },
        ]
      }
    },
    csvHeaders: {
      get() {
        return [ 'First Name', 'Last Name', 'Email', this.employerStore.fieldLabelSettings.groupLabel, 'Phone Primary', 'Phone Secondary', 'Deleted', 'User ID' ]
      }
    },
    csvColumns: {
      get() {
        return [ 'firstName', 'lastName', 'email', 'employerID', 'phonePrimary', 'phoneSecondary', 'isDeleted', 'userID' ]    
      }
    },
  },
  methods: {
    resetSearch(){
      this.search = "";
      this.options.page = 1;
      this.options.pageSize = 10;
      this.options.sortBy = []; 
      this.options.sortDesc = [];
    },
    downloadCSV(usersData) {
      let users = usersData.map(user => [ user.firstName, user.lastName, user.email, user.parentName, user.phonePrimary, user.phoneSecondary, user.isDeleted, user.userID ]);
      const csv = this.$papa.unparse({fields: this.csvHeaders, data: users}, {columns: this.csvColumns});
      // download function
      download(csv, 'User List.csv');
    },
    loadUserTable(){
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc} = this.options;
      if(sortDesc[0] != true && sortDesc[0] != false){
        sortDesc[0] = false;
      }
      this.overlay = true;

      this.userStore.getAllUsersInDownpath2(page-1,itemsPerPage, sortBy, sortDesc[0],this.search).then((res) => {
        this.userList = res.usersInDownPath;
        this.totalUsers = res.totalCount;
        this.loading = false;
      })
      .catch(() => {
        this.userList = [];
        this.totalUsers = 0;
      })
      .finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    deleteUser(user) {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: "This will deactivate the user and remove their ability to access the system. Would you like to continue?",        
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          //block user from login (auth0)
          this.auth0Store.updateUser(convertToAuthZeroID(user.authZeroID), {blocked: true});

          //soft delete user on the database
          this.userStore.deleteUser(user.userID).then(() => { this.loadUserTable(); });
        }
      });
    },
    restoreUser(user) {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: 'Reactivating this record will activate this user. Are you sure you wish to continue?',
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          //unblock user from login (auth0)
          this.auth0Store.updateUser(convertToAuthZeroID(user.authZeroID), {blocked: false});

          //restore(undelete) user from the database
          this.userStore.UnDeleteUser(user.userID).then(() => { this.loadUserTable(); })
        }
      });
    },
  },
  mounted() {
     this.employerStore.getAllEmployersByActor();
    // this.loadUserTable();
  }
};
</script>
