<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="end">
      <v-btn v-if="employerStore.systemSettings.groupCreation || isReviveAdmin" depressed color="primary" @click="$router.push({ name: 'group_admin_new'})">
        <v-icon class="mr-1">mdi-account-plus</v-icon>
        Add {{employerStore.fieldLabelSettings.groupLabel}}
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12"> 
        <v-card>
          <v-card-title>
            {{employerStore.fieldLabelSettings.secondaryGroupLabel}}
            <v-spacer></v-spacer>
            <!-- start export component -->
            <v-switch v-model.trim="filters.showInactiveEmployers" label="Show Inactive Employers" class="pa-3"></v-switch>

            <v-btn class="mr-5" v-if="employerList.length" @click="downloadCSV(employerExportList)">
                Download Current View
                <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <v-btn class="mr-5" v-if="employerList.length" @click="downloadAll()">
              <v-progress-circular v-if="isDownloadingAll" indeterminate size="25" style="margin-right: 10px;"></v-progress-circular>
              Download All
              <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <!-- end export component -->
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field @keyup.enter="loadEmployersByActor" :label="'Search for '+employerStore.fieldLabelSettings.groupLabel" v-model.trim="search">
                  <template v-slot:append-outer>
                    <v-btn class="mr-2" @click="loadEmployersByActor">
                      Search
                      <v-icon class="ma-2">search</v-icon>
                    </v-btn>
                    <v-btn @click="resetSearch" color="primary">
                        Reset
                      <v-icon class="ma-2">restore</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              
              <v-col cols="12">
                  <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="employerList"
                  :options.sync="options"
                  :server-items-length="totalEmployers"
                  :loading="loading"
                  :footer-props="{
                    'items-per-page-options': rowsPerPage
                  }"
                >
                  
                  
                  <template v-slot:no-data>No Results Found. Please try removing filters or expanding your search.</template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="$router.push({ name: 'group_admin_edit', query: { employerID: item.employerID } })"> mdi-pencil </v-icon>
                    <!-- delete employer -->
                    <span v-if="item.employerID != employerStore.currentEmployerID">
                      <v-tooltip bottom v-if="!item.deleted">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="ms-2" v-bind="attrs" v-on="on" @click="deleteEmployer(item.employerID)" >cancel</v-icon>
                        </template>
                        <span>Delete Employer</span>
                      </v-tooltip>
  
                      <!-- <span restore employer-->
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on" @click="restoreEmployer(item.employerID)">restore</v-icon>
                        </template>
                        <span>Restore Employer</span>
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useEmployerStore } from "../../stores/employer";
import { useLookupStore } from "../../stores/lookup";
import { getEmployerType } from "../../commons/employer";
import { download } from "../../commons/employer";
import { roles } from "../../data/definitions/roles";
import { useAuthStore } from "../../stores/auth";

export default {
  name: "EmployerListing",
  data: () => ({
    search: "",
    filters: { showInactiveEmployers: false },
    employerList: [],
    allEmployers: [],
    overlay: false,
    itemsPerPage: 10,
    loading: true,
    totalEmployers: 0,
    options: {},
    rowsPerPage: [5,10,15,50,100],
    isDownloadingAll: false
  }),
  setup() {
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const authStore = useAuthStore();
    return {
      employerStore,
      lookupStore,
      getEmployerType,
      authStore
    };
  },
  computed: {
    isReviveAdmin() {
      if(this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin)) return true;
      return false;
    },
    employerExportList: {
      get(){
        let newArray = [...this.allEmployers];
        newArray.forEach((x) => {
          x.employerTypeName = getEmployerType(x.categoryID);
        });
        return newArray;
      }
    },
    headers: {
      get() {
        return [
          { text: this.employerStore.fieldLabelSettings.groupLabel+" ID", value: "employerID" },
          { text: this.employerStore.fieldLabelSettings.groupLabel+" Name", value: "name" },
          { text: "Parent "+this.employerStore.fieldLabelSettings.groupLabel, value: "parentName" },
          { text: "Actions", value: "actions", sortable: false },
        ]
      }
    },
    csvHeaders: {
      get() {
        return [ this.employerStore.fieldLabelSettings.groupLabel +' Name', 'Parent '+ this.employerStore.fieldLabelSettings.groupLabel +' name', this.employerStore.fieldLabelSettings.groupLabel + ' ID', 'Primary Phone Number', 'Secondary Phone Number', this.employerStore.fieldLabelSettings.groupLabel  + ' Type', 'Primary Street', 'Primary Street 2', 'Primary City', 'Primary Region', 'Primary Postal', 'Primary Country', 'Secondary Street', 'Secondary Street 2', 'Secondary City', 'Secondary Region', 'Secondary Postal', 'Secondary Country',]
      }
    },
    csvColumns: {
      get() {
        return [ 'name', 'parentName', 'employerID', 'phonePrimary', 'phoneSecondary', 'employerTypeName', 'primaryStreet', 'primaryStreetTwo', 'primaryCity', 'primaryRegion', 'primaryPostal', 'primaryCountry', 'secondaryStreet', 'secondaryStreetTwo', 'secondaryCity', 'secondaryRegion', 'secondaryPostal', 'secondaryCountry']
      }
    },
  },
  methods: {
    downloadAll(){
      this.isDownloadingAll = true
      this.employerStore.downloadAllEmployers()
      .then( (response) => {
          download(response.data, "All Employers.csv");
      }).finally( () => this.isDownloadingAll = false);
    },
    resetSearch(){
      this.search = "";
      this.options.page = 1;
      this.options.pageSize = 10;
      this.options.sortBy = []; 
      this.options.sortDesc = [];
    },
    downloadCSV(employersData) {
      let employers = employersData.map(user => [ user.name, user.parentName, user.employerID, user.phonePrimary, user.phoneSecondary, user.employerTypeName, user.primaryStreet, user.primaryStreetTwo, user.primaryCity, user.primaryRegion, user.primaryPostal, user.primaryCountry, user.secondaryStreet, user.secondaryStreetTwo, user.secondaryCity, user.secondaryRegion, user.secondaryPostal, user.secondaryCountry ]);
      const csv = this.$papa.unparse({fields: this.csvHeaders, data: employers}, {columns: this.csvColumns});
      // download function
      download(csv, this.employerStore.fieldLabelSettings.secondaryGroupLabel + ' List.csv');
    },
    loadEmployersByActor(){
      this.loading = true;
      let { sortBy, sortDesc,page, itemsPerPage } = this.options

      if(sortDesc[0] != true && sortDesc[0] != false){
        sortDesc[0] = false;
      }

      this.overlay = true;
      this.employerStore.getEmployersByActor(page-1,itemsPerPage,this.filters.showInactiveEmployers, this.search, sortBy, sortDesc[0]).then((result) => {
        this.allEmployers = result.employers;
        this.employerList = result.employers;
        this.totalEmployers = result.totalCount
      })
      .finally(() => {
        this.loading = false;
        this.overlay = false;
      });
    },
    deleteEmployer(employerID)
    {
      this.$swal({
        title: "Warning",
        icon: "warning",
        html: `You are about to deactivate this ${this.employerStore.fieldLabelSettings.groupLabel}. <br/> Do you wish to continue?`,        
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.employerStore.deleteEmployer(employerID).then(() => { this.loadEmployersByActor(); })
        }
      });
    },
    restoreEmployer(employerID){
      this.$swal({
        title: "Warning",
        icon: "warning",
        html: `You are about to re-activate this ${this.employerStore.fieldLabelSettings.groupLabel}. <br/> Do you wish to continue?`, 
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.employerStore.undeleteEmployer(employerID).then(() => { this.loadEmployersByActor(); })
        }
      });
    }
  },
  watch: {
    filters: {
      handler: function(){
         this.options.page = 1;
         this.options.pageSize = 10;
        this.loadEmployersByActor()
      },
      deep: true,
    },
    options: {
        handler () {
          this.loadEmployersByActor()
        },
        deep: true,
      },
   }
};
</script>
<style scoped>
#searchEmployer{
  margin-top:15px;
}

::v-deep  .v-data-footer {
  padding: 0 60px;
}
</style>