import { getInstance } from "./index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthStore } from "../stores/auth";
import router from "../router";
import { gncRoles } from "../data/definitions/gncRoles";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, check the user has the right roles if any exist. 
    if (authService.isAuthenticated) {
      if (to.meta.roles && to.meta.roles.length > 0)
        handleRoles(to.meta.roles, next)
      else
        return next();
    }
    else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
}

const handleRoles = (roles, next) => {
  const store = useAuthStore();

  let canAccess = false;

  roles.forEach((x) => {
    if (!canAccess)
      canAccess = store.getUserInRole(x);
  })
  
  if (canAccess)
    return next();
  else
  {
    Swal.fire({
      title: "Info",
      text: "You do not currently have access to this page. If you believe this is an error please contact support.",
      icon: "info",
      confirmButtonText: "Continue",
      confirmButtonColor: "#28a745",
      showCancelButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false
    }).then((okay) => {
      if (okay)
        return next("/dashboard");
    });
  }
}

export const homeAuthGuard = (to, from, next) => {
  const authService = getInstance();
  
  const fn = () => {
  if (authService.isAuthenticated) {
    const store = useAuthStore();
    authService.$watch("completedBillingInfo", (completedBillingInfo) => {
      if(authService.forceResetpassword) {
        router.push({ name: "update-password" });
      } else if(!completedBillingInfo && !authService.forceResetpassword && authService.enrolledInTheGroup) {
        router.push({ name: "billing", query: {employerID: authService.employerID, warning: true} });
      } else if(store.userRole == gncRoles.memberSupport){
        return next({ name: "subscriber_admin_index" })
      }else{ return next("/dashboard");}
    });
  }else {
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  }
};

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }
 
  
  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
}