import { defineStore } from "pinia";
import VueJwtDecode from 'vue-jwt-decode';
export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      authenticated: false,
      accessToken: "",
      token: {},
      user: {},
      userDetailsFromDatabase: {},
      userRole: "not exist"
    }
  },
  getters: {
    //gets the list of roles the user is in, returns empty array if no roles are available or they could not be parsed.
    getUserRoles: (state) => {
      return () => {
        //check permissions exist
        if (!state.token.permissions || state.token.permissions.length < 1)
        {return [];}
        return state.token.permissions;
      }
    },
    //returns true/false if the user is in the role. 
    getUserInRole: (state) => {
      return (roleName) => {
        if (state.token.permissions && state.token.permissions.length > 1)
        {
          const foundRole = state.token.permissions.find(x => x == roleName);
          if (foundRole)
          {
            return true;
          }
        }
        return false;
      }
    },
  },
  actions: {
    //returns true false if token is available to set. 
    setTokenObject(token){
      //TODO: Need to validate this to make sure it is a real jwt token and belongs to our auth methods. 
      if (token.length > 1)
      {
        try {
        this.token = VueJwtDecode.decode(token);
        if (this.token && this.token.exp != 0)
        {return true;}
        }
        catch {
          return false;
        }
     }
     else { return false;}
    }
  }
});
