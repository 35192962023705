<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title> Edit {{employerStore.fieldLabelSettings.subscriberLabel}} <v-icon> </v-icon> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form
              v-model.trim="emloyeeIsValid"
              ref="employeeListForm"
              lazy-validation
            >
              <v-row> 
                <v-col cols="9">
                  <v-autocomplete
                    :label="
                      productList.length > 0
                        ? 'To re-enable '+employerStore.fieldLabelSettings.groupLabel+' selection please click clear below.'
                        : 'Select ' + employerStore.fieldLabelSettings.groupLabel
                    "
                    v-model.trim="selectedEmployer"
                    :items="employerList"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.employerID"
                    clearable
                    @change="getProductsListing()"
                    :disabled="productList.length > 0"
                    :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    v-if="selectedEmployer"
                    @click="clearEmployee"
                    color="cancel"
                    class="ma-2"
                    dark
                  >
                    Change {{employerStore.fieldLabelSettings.groupLabel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="selected">
          <v-col cols="12">
            <p class="font-weight-bold primary--text mt-3" bold>
              Primary {{employerStore.fieldLabelSettings.subscriberLabel}} Demographics:
            </p>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.firstName"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z-'. ]/ } },
                    }"
                    :rules="[(v) => !!v || 'First Name is required']"
                    label="First Name"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.middleName"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z- ]/ } },
                    }"
                    label="Middle Name"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.lastName"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z-'. ]/ } },
                    }"
                    :rules="[(v) => !!v || 'Last Name is required']"
                    label="Last Name"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field 
                  v-model.trim="member.externalId" label="External ID" 
                  :append-icon="showMemberExternalID ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showMemberExternalID ? 'text' : 'password'"
                  @click:append="showMemberExternalID = !showMemberExternalID"
                  hint="This is the unique identifer your system uses"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" v-if="isReviveAdmin">
                  <v-text-field v-model.trim="member.hubspotId" label="Hubspot ID"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phonePrimary">Primary Phone</label>
                  <vue-tel-input
                    id="phonePrimary"
                    v-model.trim="member.phonePrimary"
                    @input="onPrimaryPhoneObject"
                    :onlyCountries="countries"
                  />
                  <div v-if="member.phonePrimary">
                    <span>
                      <small
                        v-if="!primaryPhoneObject.valid"
                        class="error--text"
                        >Primary Phone number is not valid</small
                      >
                    </span>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phoneSecondary">Secondary Phone</label>
                  <vue-tel-input
                    id="phoneSecondary"
                    v-model.trim="member.phoneSecondary"
                    @input="onSecPhoneObject"
                    :onlyCountries="countries"
                  />
                  <div v-if="member.phoneSecondary">
                    <span>
                      <strong v-if="!secPhoneObject.valid" class="error--text"
                        >Secondary Phone number is not valid</strong
                      >
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model.trim="member.gender"
                    :items="genderList"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.lookupID"
                    :rules="[(v) => !!v || 'Gender is required']"
                    label="Gender"
                    required
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.dob"
                    :rules="primaryMemberDate"
                    label="Date of Birth"
                    v-maska="'##/##/####'"
                    hint="MM/DD/YYYY"
                    required
                  >
                  </v-text-field>
                </v-col>

                 <v-col cols="12" md="4">
                  <v-select v-model.trim="member.language" :items="languages" 
                  :item-text="(item) => item.text" 
                  :item-value="(item) => item.value" 
                  label="Preferred Language"
                  :rules="[(v) => !!v || 'Preferred Language is required']"
                  required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="member.primaryStreet"
                    :rules="[(v) => !!v || 'Address is required']"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } },
                    }"
                    label="Address Line 1"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="member.primaryStreetTwo"
                    label="Address Line 2"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } },
                    }"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="member.primaryCity"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z- ]/ } },
                    }"
                    :rules="[(v) => !!v || 'City is required']"
                    label="City"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6"> </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" md="6">
                  <v-select
                    v-model.trim="member.primaryRegion"
                    :items="states"
                    :item-text="(item) => item.name"
                    :rules="[(v) => !!v || 'Region is required']"
                    :item-value="(item) => item.abbreviation"
                    label="Select Region"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="member.primaryPostal"
                    :rules="[
                      rules.zipCodeValidation(member.primaryPostal)
                    ]"
                    v-maska="{
                      mask: '#*',
                      tokens: { '#': { pattern: /[0-9\-]/ } },
                    }"
                    maxlength="10"
                    label="Postal Code"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="ml-2" v-if="employerStore.memberSettings.displayDependent">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3"
                    >{{employerStore.fieldLabelSettings.secondaryDependentLabel}}: {{ dependentList.length }} Added</span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-form
                        v-model.trim="depValid"
                        ref="dependentForm"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="8">
                                <v-select
                                  :items="filteredMemberType"
                                  :item-text="(item) => item.name"
                                  :item-value="(item) => item.lookupID"
                                  v-model.trim="depType"
                                  :label="'Select the type of '+employerStore.fieldLabelSettings.dependentLabel+' to add'"
                                >
                                  <template v-slot:item="data">
                                    <v-list-item-content>
                                      <v-list-item-title
                                        >{{ data.item.name }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle
                                        >{{ data.item.description }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="4">
                                <v-switch
                                  :label="'Use The Primary '+employerStore.fieldLabelSettings.subscriberLabel+' Address'"
                                  v-model.trim="depUsePrimaryAddress"
                                  @change="primaryAddressToggle($event)"
                                ></v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" v-if="depType">
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model.trim="dependent.depFirstName"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z-'. ]/ } },
                                  }"
                                  :rules="[
                                    (v) => !!v || 'First Name is required',
                                  ]"
                                  label="First Name"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model.trim="dependent.depMiddleName"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z- ]/ } }
                                  }"
                                  label="Middle Name"
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model.trim="dependent.depLastName"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z-'. ]/ } },
                                  }"
                                  :rules="[
                                    (v) => !!v || 'Last Name is required',
                                  ]"
                                  label="Last Name"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field 
                                v-model.trim="dependent.depExternalId" label="External ID" 
                                :append-icon="showDependentExternalID ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showDependentExternalID ? 'text' : 'password'"
                                @click:append="showDependentExternalID = !showDependentExternalID"
                                hint="This is the unique identifer your system uses"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" v-if="isReviveAdmin">
                                <v-text-field v-model.trim="dependent.depHubspotId" label="Hubspot ID"></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field v-model.trim="dependent.depEmail" :rules="[rules.emailValidation(depType, dependent.depEmail)]" label="E-mail"></v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <label for="depPrimaryPhone">Primary Phone</label>
                                <vue-tel-input id="depPrimaryPhone" v-model.trim="dependent.depPrimaryPhone" @input="onDepPrimaryPhoneObject" :onlyCountries="countries" />
                                <div v-if="dependent.depPrimaryPhone || !validPhoneInput">
                                  <small v-if="!depPrimaryPhoneObject.valid" class="error--text">Primary Phone number is not valid</small>
                                </div>
                              </v-col>

                              <v-col cols="12" md="4">
                                <label for="depSecondaryPhone"
                                  >Secondary Phone</label
                                >
                                <vue-tel-input
                                  id="depSecondaryPhone"
                                  @input="onDepSecPhoneObject"
                                  v-model.trim="dependent.depSecondaryPhone"
                                  :onlyCountries="countries"
                                />
                                <div v-if="dependent.depSecondaryPhone">
                                    <small v-if="!depSecPhoneObject.valid" class="error--text">Secondary Phone number is not valid</small>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="4">
                                <v-select
                                  v-model.trim="dependent.depGender"
                                  :items="genderList"
                                  :item-text="(item) => item.name"
                                  :item-value="(item) => item.lookupID"
                                  :rules="[(v) => !!v || 'Gender is required']"
                                  label="Gender"
                                  required
                                >
                                </v-select>
                              </v-col>

                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model.trim="dependent.depDob"
                                  :rules="date"
                                  label="Date of Birth"
                                  v-maska="'##/##/####'"
                                  hint="MM/DD/YYYY"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <v-select v-model.trim="dependent.depLanguage" :items="languages" 
                                :item-text="(item) => item.text" 
                                :item-value="(item) => item.value" 
                                label="Preferred Language"
                                :rules="[(v) => !!v || 'Dependent Preferred Language is required']"
                                required
                                ></v-select>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model.trim="dependent.depAddress1"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } },
                                  }"
                                  :rules="[
                                    (v) => !!v || 'Address Line 1 is required',
                                  ]"
                                  label="Address Line 1"
                                  :disabled="depUsePrimaryAddress"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model.trim="dependent.depAddress2"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } },
                                  }"
                                  :disabled="depUsePrimaryAddress"
                                  label="Address Line 2"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model.trim="dependent.depCity"
                                  v-maska="{
                                    mask: 'S*',
                                    tokens: { S: { pattern: /[a-zA-Z- ]/ } },
                                  }"
                                  :rules="[(v) => !!v || 'City is required']"
                                  label="City"
                                  :disabled="depUsePrimaryAddress"
                                  required
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6"> </v-col>
                            </v-row>
                            <v-row class="mb-5">
                              <v-col cols="12" md="6">
                                <v-select
                                  v-model.trim="dependent.depRegion"
                                  :items="states"
                                  :item-text="(item) => item.name"
                                  :item-value="(item) => item.abbreviation"
                                  :rules="[(v) => !!v || 'Region is required']"
                                  label="Select Region"
                                  :disabled="depUsePrimaryAddress"
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model.trim="dependent.depPostal"
                                  :rules="[
                                    rules.zipCodeValidation(dependent.depPostal)
                                  ]"
                                  v-maska="{
                                    mask: '#*',
                                    tokens: { '#': { pattern: /[0-9\-]/ } },
                                  }"
                                  maxlength="10"
                                  :disabled="depUsePrimaryAddress"
                                  label="Postal Code"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" class="d-flex justify-end">
                                <v-btn
                                  color="success"
                                  class="ma-2"
                                  @click="addDependent"
                                >
                                  {{getDependentButtonText}} {{employerStore.fieldLabelSettings.dependentLabel}}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row v-if="employerStore.memberSettings.displayDependent">
                    <v-col cols="12">
                      <v-data-table
                        :headers="dependentHeaders"
                        :items="dependentList"
                        hide-default-footer
                      >
                        <template v-slot:[`item.dateOfBirth`]="{ item }">
                          {{ cleanDate(item.dateOfBirth) }}
                        </template>
                        <template v-slot:[`item.memberType`]="{ item }">
                          {{ getMemberTypeName(item.memberType) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }" >
                          <v-icon
                            small
                            class="mr-2"
                            @click="deleteMemberDependent(item)"
                          >
                            delete
                          </v-icon>
                          <v-icon
                            small
                            class="mr-2"
                            @click="editMemberDependent(item)"
                          >
                            mdi-pencil
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                          No {{employerStore.fieldLabelSettings.dependentLabel}} added. 
                          Please add a {{employerStore.fieldLabelSettings.dependentLabel}} to this
                          {{employerStore.fieldLabelSettings.subscriberLabel}}.
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.serviceLabel}}: {{ memberProductList.length }} Added
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row >
                    <v-col cols="5">
                      <v-autocomplete v-model.trim="addProductList"
                        :label="'Select '+employerStore.fieldLabelSettings.serviceLabel+' To Add'"
                        :items="filteredProductList" :item-text="(item) => item.name"
                        :item-value="(item) => item.skuid" return-object>
                        <template v-slot:item="data">
                          <v-list-item-content>
                              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ data.item.shortList }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model.trim="startDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        nudge-top="400"
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model.trim="computedStartDateFormatted"
                            label="Start Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model.trim="startDate"
                          no-title
                          :min="today"
                          @input="startDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model.trim="endDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        nudge-top="400"
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model.trim="computedEndDateFormatted"
                            label="End Date"
                            prepend-icon="event"
                            :append-icon="endDate ? 'clear' : '' "
                            @click:append="endDate = null"
                            readonly
                            v-on="on" 
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model.trim="endDate"
                          no-title
                          @input="endDateMenu = false"
                          
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        color="success"
                        class="ma-2"
                        :disabled="addProductList.length < 1"
                        @click="addProducts"
                      >
                        {{getProductButtonText}}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="font-weight-bold primary--text mt-3">
                        {{employerStore.fieldLabelSettings.serviceLabel}} to Add:
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="memberProductHeaders"
                        :items="memberProductList"
                        hide-default-footer
                      >
                        <template v-slot:[`item.start`]="{ item }">
                          {{ cleanDate(item.start) }}
                        </template>
                        <template v-slot:[`item.end`]="{ item }">
                          {{ cleanDate(item.end) == 'Invalid DateTime' ? 'N/A' : cleanDate(item.end) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon class="ma-2" small @click="editMemberProduct(item)">
                              mdi-pencil
                          </v-icon>
                          <v-icon small class="mr-2" @click="removeMemberProduct(item)">
                            delete
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                          No {{employerStore.fieldLabelSettings.serviceLabel}} added. 
                          Please add a {{employerStore.fieldLabelSettings.serviceLabel}} to this
                          {{employerStore.fieldLabelSettings.subscriberLabel}}.
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="saveMember">
              Save
            </v-btn>
            <v-btn
              color="cancel"
              dark
              class="ma-2"
              @click="$router.push({ name: 'subscriber_admin_index' })"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import states from "../../../state.json";
import { useAuthStore } from "../../stores/auth";
import { useMemberStore } from "../../stores/member";
import { useEmployerStore } from "../../stores/employer";
import { useProductStore } from "../../stores/product";
import { useLookupStore } from "../../stores/lookup";
import { getFilteredMemberType, convertToMemberObject, countries } from "../../commons/member";
import { lookupTypes } from "../../data/definitions/lookupTypes";
import { cleanDate, validateDateForDependent } from "../../commons/date";
import { DateTime } from "luxon";
import { roles } from "../../data/definitions/roles";

export default {
  name: "EditMemberComponent",

  setup() {
    const memberStore = useMemberStore();
    const productStore = useProductStore();
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const authStore = useAuthStore();

    return { memberStore, employerStore, productStore, lookupStore, cleanDate, states, authStore, countries };
  },
  computed: {
    getDependentButtonText: {
      get() {
        const text = this.depEditingMode ? 'Save' : 'Add';
        return text;
      }
    },
    getProductButtonText: {
      get() {
        const text = this.productEditingMode ? 'Save' : 'Add';
        return text;
      }
    },
    employerList: {
      get() {
        return this.employerStore.filteredEmployersList;
      },
    },
    genderList: {
      get() {
        return this.lookupStore.genderList;
      },
    },
    //Display list that will not include any products already added to a member.
    filteredProductList: {
      get() {
          let newList = [];
          //find products already added to member and remove from available list.
          this.productList.forEach((product) => {

            const found = this.memberProductList.length > 0 && this.memberProductList.find(
              (x) => x.productID == product.skuid
            );
            if (!found) {
              const shortList = [product.products.map(x => (' ' + x.name) )];
              product.shortList = this.employerStore.fieldLabelSettings.serviceLabel + " In This Package: " + shortList.join(',')
              newList.push(product);
            }
          });
          return newList;

      },
    },
    //Date time formatting
    computedStartDateFormatted: function () {
      return cleanDate(this.startDate);
    },
    computedEndDateFormatted: function () {
      return this.endDate ? cleanDate(this.endDate) : null;
    },
    filteredMemberType: {
      get() {
        return getFilteredMemberType(this.lookupStore.memberType, this.dependentList);
      },
    },
  },
  data() {
    let self = this;
    return {
      overlay: false,
      primaryPhoneObject: {
        valid: false,
      },
      depPrimaryPhoneObject: {
        valid: false,
      },
      secPhoneObject: {
        valid: false,
      },
      depSecPhoneObject: {
        valid: false,
      },
      fullMemberObject: {},
      removeList: [],
      validPhoneInput: true,
      emloyeeIsValid: true,
      isReviveAdmin: false,
      valid: true,
      member: {
        hubspotId: "", 
        firstName: "",
        middleName: "",
        lastName: "",
        externalId: "",
        phonePrimary: "",
        phoneSecondary: "",
        dateOfBirth: "",
        email: "",
        primaryCity: "",
        primaryCountry: "US",
        primaryPostal: "",
        primaryRegion: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        language: "",
        gender: "",
      },
      showMemberExternalID: false,
      showDependentExternalID: false,
      employerSystemSettings: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || "E-mail must be valid",
      ],
      depEmailRules: [(v) => self.validEmail(v)],
      rules: {
        emailValidation(memberType, v) {
          if (memberType ==  lookupTypes.dependentType.dependent && v.length > 0) {
            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)) {
              return true;
            } else {
              return "E-mail must be valid";
            }
          } else if (
            memberType == lookupTypes.dependentType.dependent &&
            v.length == 0
          ) {
            return true;
          } else {
            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)) {
              return true;
            } else {
              return "E-mail must be valid";
            } 
          }
        },
        zipCodeValidation(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Zip code must be valid"
        }
      },
      items: ["Male", "Female"],
      date: [(v) => self.isValidDate(v)],
      primaryMemberDate: [
        (v) => validateDateForDependent(null, v, self.employerStore.fieldLabelSettings.subscriberLabel, self.employerStore.fieldLabelSettings.dependentLabel, self.employerSystemSettings?.allowMinorsAsPrimary, self.employerSystemSettings?.minimumPrimaryAge)
      ],
      //product data
      productList: [],
      addProductList: [],
      memberProductList: [],
      today: DateTime.now().toISO(),
      startDate: 
        self.employerStore.memberSettings.startDate == 'currentDay' ? 
        DateTime.now().toFormat("yyyy-MM-dd") : 
        DateTime.now().plus({ month: 1 }).startOf("month").toFormat("yyyy-MM-dd"),
      endDate: "",
      startDateMenu: false,
      endDateMenu: false,
      memberProductHeaders: [
        { text: this.employerStore.fieldLabelSettings.serviceLabel + " Name", value: "name" },
        { text: "Start Date", value: "start" },
        { text: "End Date", value: "end" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      //employer data
      selected: false,
      selectedEmployer: "",
      // parentEmployerID: null,
      pageLoad: true,
      //dependent data
      dependentList: [],
      depValid: false,
      dependent: {
        depFirstName: "",
        depMiddleName: "",
        depLastName: "",
        depExternalId: "",
        depHubspotId: "",
        depGender: "",
        depPrimaryPhone: "",
        depSecondaryPhone: "",
        depDob: "",
        depEmail: "",
        depCountry: "US",
        depRegion: "",
        depAddress1: "",
        depAddress2: "",
        depPostal: "",
        depLanguage: "",
        depCity: "",
      },
      depType: "",
      depMemberID: "",
      depUsePrimaryAddress: false,
      depEditingMode: false,
      productEditingMode: false,
      dependentHeaders: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Date of Birth", value: "dateOfBirth" },
        { text: this.employerStore.fieldLabelSettings.subscriberLabel+" Type", value: "memberType" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      isDirtyDependentForm: false,
      languages: [
        { text: "English", value: "en-us" },
        { text: "Spanish", value: "es-mx" },
      ],
      removedProducts: [],
      isDirty: false
    };
  },
  watch: {
    selectedEmployer(selectedEmployer) {
      if (selectedEmployer) {
        this.selected = true;
      }

      const found = this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin);
      if(found)
        this.isReviveAdmin = true;
    },
    /* using a deep watcher here helps to trigger this callback on a nested member and dependent mutation. 
    Whenever a mutation occurs, the value of isDirty is set to true */
    member: {
      handler: function(){
        if(!this.pageLoad) {
          this.isDirty = true
        }
      },
      deep: true,
    },
    dependent: {
      handler: function(){
        if(!this.pageLoad) {
          this.isDirty = true
        }
      },
      deep: true,
    },
    depType(value) {
      if(value) {
        this.isDirtyDependentForm = true;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveMember(false)
        }else if(result.isDismissed){
          next();
        } else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  },  
  created(){
    this.$watch("employerStore.billingSettings.BillingInfoCompleted", (completedBillingInfo) => {
      if(!completedBillingInfo || !this.employerStore.billingSettings.enrolledInTheGroup) {
        this.$swal({
          title: "Error",
          text: `Your ${this.employerStore.fieldLabelSettings.groupLabel} has not yet had billing information added, please complete this to begin editing ${this.employerStore.fieldLabelSettings.secondarySubscriberLabel}`,
          icon: "error",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Ok",
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then((okay) => {
          if (okay) {
            this.$router.push({ name: "billing", query: {employerID: this.employerStore.currentEmployerFull.employerID, warning: true} });
          }
        });
      }
    });
  },
  mounted() {
    if (this.$route.query.memberID) {
      this.memberStore
        .getFullMemberByID(this.$route.query.memberID)
        .then((res) => {
          this.fullMemberObject = res;
          //pull employer list
          this.employerStore.filterEmployersByActor();
          //get employer id
          this.selectedEmployer = this.fullMemberObject.memberEmployer.employerID;
          //pull gender list
          this.lookupStore.getGenderList();
          //parse and populate the products.
          this.getProductsListing();
          //assign dependents to edit list.
          this.dependentList = [];
          //test and add for external id in dependents
          if (this.fullMemberObject.dependents.length > 0) {
            this.fullMemberObject.dependents.forEach((dependent) => {
              let newDependent = dependent.member;
              const findExternal = dependent.memberAttributes.find(
                (x) => x.attributeType == lookupTypes.attributes.externalID
              );
              const findHubspotId = dependent.memberAttributes.find(
                (x) => x.attributeType == lookupTypes.attributes.hubspotID
              );
              let externalID = "";
              let hubspotID = "";

              if (findExternal) 
                externalID = findExternal.attributeValue;

              if(findHubspotId)
                hubspotID = findHubspotId.attributeValue;

              const depLanguage = dependent.memberAttributes.find(
                (x) => x.attributeType == lookupTypes.memberType.language
              );
              let language = 'en-us';
              if (depLanguage)
              {
                language = depLanguage.attributeValue;
              }
              newDependent.language = language;
              newDependent.externalID = externalID;
              newDependent.hubspotId = hubspotID;
              this.dependentList.push(newDependent);
            });
          }
          //parse object to editable object
          const findExternal = this.fullMemberObject.memberAttributes.find(
            (x) => x.attributeType == lookupTypes.attributes.externalID
          );
          const findHubspotId = this.fullMemberObject.memberAttributes.find(
            (x) => x.attributeType == lookupTypes.attributes.hubspotID
          );
          const findLanguage = this.fullMemberObject.memberAttributes.find(
            (x) => x.attributeType == lookupTypes.memberType.language
          );
          let externalID = "";
          let language = "";
          let hubspotID = "";

          if (findExternal)
            externalID = findExternal.attributeValue;
          
          if(findHubspotId)
            hubspotID = findHubspotId.attributeValue;
          
          if (findLanguage) {
            language = findLanguage.attributeValue;
          }
          // load employer's list
          this.employerStore.filterEmployersByActor();
          
          //for determining the dependent type, query the lookup/getMemberTypeList endpoint
          this.lookupStore.getMemberTypeList();

          this.fullMemberObject.member.dob = DateTime.fromISO(this.fullMemberObject.member.dateOfBirth).toFormat("MM/dd/yyyy");
          this.fullMemberObject.member.externalId = externalID;
          this.fullMemberObject.member.language = language;
          this.fullMemberObject.member.hubspotId = hubspotID;

          this.member = this.fullMemberObject.member;

          //load employer's member settings
          this.getEmployerMemberSettings();
        })
        // setting the pageLoad to true prevents the employer watch from assigning a truthy value to this.isDirty
        .finally(() => this.pageLoad = false);
    } else {
      this.$swal({
        title: "Error",
        text: "Invalid Page",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((okay) => {
        if (okay) {
          this.$router.push({ name: "subscriber_admin_index" });
        }
      });
    }
  },
  methods: {
    preventReload(event) {
      /* the beforeunload event functions based on the value of isDirty. 
       if false, event would run but if true, user would receive a prompt message when reloading page */
      if (!this.isDirty) return
      event.preventDefault()
      event.returnValue = ""
    },
    onPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.primaryPhoneObject = phoneObject;
    },
    onSecPhoneObject(formattedNumber, phoneObject) {
      this.secPhoneObject = phoneObject;
    },
    onDepPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.depPrimaryPhoneObject = phoneObject;
    },
    onDepSecPhoneObject(formattedNumber, phoneObject) {
      this.depSecPhoneObject = phoneObject;
    },
    clearEmployee() {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: "Warning, this will clear any " + this.employerStore.fieldLabelSettings.serviceLabel + " you have added to this "+this.employerStore.fieldLabelSettings.subscriberLabel+", are you sure you wish to continue?",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
        buttons: true,
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.productList = [];
          this.addProductList = [];
          this.memberProductList = [];
          this.selectedEmployer = "";
        }
      });
    },
    isValidDate(req) {
      return validateDateForDependent(this.depType, req, this.employerStore.fieldLabelSettings.subscriberLabel, this.employerStore.fieldLabelSettings.dependentLabel);
    },
    validEmail(v) {
      if (v.length > 0 || this.depType !== lookupTypes.dependentType.dependent) {
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)) {
          return true;
        } else {
          return "E-mail must be valid";
        }
      } else {
        return true;
      }
    },
    getEmployerMemberSettings: function(){
      this.employerStore.getFullEmployerByID(this.selectedEmployer).then((res) => {
        let systemSettings = res.employerAttributes.find( (x) => x.attributeType == lookupTypes.settings.system );
        this.employerSystemSettings = JSON.parse(systemSettings.attributeValue);
      });
    },
    //Get the list of products availble to upload items to. Sets has products. User cannot upload items if there are no products.
    getProductsListing: function () {
      if (this.selectedEmployer.length > 0) {
        this.productStore
          .getProductStockByEmployer(this.selectedEmployer)
          .then((res) => {
            this.productList = res.data ?? [];
            //parse products.
            if (this.fullMemberObject.memberProducts.length > 0) {
              //get list of sku's 
              let skuArray = [...new Set(this.fullMemberObject.memberProducts.map(x => x.skuid))];
              if(skuArray.length > 0){
                skuArray.forEach((product) => {
                  const find = this.productList.find(
                    (x) => x.skuid === product
                  );
                  const matchingProduct = this.fullMemberObject.memberProducts.find(x => x.skuid === product && x.isDeleted === false);
                  if (matchingProduct)
                  {
                    this.memberProductList.push({
                      end: matchingProduct.endDate,
                      start: matchingProduct.activeDate,
                      productID: product,
                      name: find.name,
                    });
                  }
                  
                });
              }
            }
            return;
          });
      }
      this.productList = [];
    },
    getMemberTypeName(id) {
      const type = this.lookupStore.memberType.find((x) => x.lookupID == id);
      return type.name; 
    },
    //member & dependent management
    editMemberProduct(item) {
      this.productEditingMode = true;
      this.removeMemberProduct(item)
      this.startDate = item.start;
      this.endDate = item.end;
      this.addProductList = {};
      this.addProductList.skuid = item.productID;
      this.addProductList.name = item.name;
      this.addProductList.products = item.productID.split();
    },
    deleteMemberDependent: function (item) {
      this.removeList.push(item);
      this.dependentList = [...this.dependentList.filter((x) => x != item)];
    },
    removeMemberDependent: function (item) {
      this.dependentList = [...this.dependentList.filter((x) => x != item)];
    },
    editMemberDependent: function (item) {
      this.depEditingMode = true;
      this.showDependentExternalID = false;
      this.removeMemberDependent(item);
      this.dependent.depFirstName = item.firstName;
      this.dependent.depMiddleName = item.middleName;
      this.dependent.depLastName = item.lastName;
      this.dependent.depExternalId = item.externalID;
      this.dependent.depHubspotId = item.hubspotId;
      this.dependent.depGender = item.gender;
      this.dependent.depPrimaryPhone = item.phonePrimary;
      this.dependent.depSecondaryPhone = item.phoneSecondary;
      this.dependent.depDob = DateTime.fromISO(item.dateOfBirth).toFormat("MM/dd/yyyy");
      this.dependent.depEmail = item.email;
      this.dependent.depCountry = item.primaryCountry;
      this.dependent.depRegion = item.primaryRegion;
      this.dependent.depAddress1 = item.primaryStreet;
      this.dependent.depAddress2 = item.primaryStreetTwo;
      this.dependent.depPostal = item.primaryPostal;
      this.dependent.depLanguage = item.language;
      this.dependent.depCity = item.primaryCity;
      this.depType = item.memberType;
      this.depUsePrimaryAddress = item.usePrimaryAddress;
      this.depMemberID = item.memberID;
    },
    clearDependent() {
      this.depValid = false;
      this.dependent.depFirstName = "";
      this.dependent.depMiddleName = "";
      this.dependent.depLastName = "";
      this.dependent.depExternalId = "";
      this.dependent.depHubspotId = "";
      this.dependent.depGender = "";
      this.dependent.depPrimaryPhone = "";
      this.dependent.depSecondaryPhone = "";
      this.dependent.depDob = "";
      this.dependent.depEmail = "";
      this.dependent.depCountry = "US";
      this.dependent.depRegion = "";
      this.dependent.depAddress1 = "";
      this.dependent.depAddress2 = "";
      this.dependent.depPostal = "";
      this.dependent.depLanguage = "";
      this.dependent.depCity = "";
      this.depType = "";
      this.depMemberID = "";
      this.depUsePrimaryAddress = false;
    },
    checkIfDependentEmailIsUnique(){
      let isEmailUnique = true;
      if(this.dependent.depEmail){
        //check primary member
        if(this.member.email){
          if(this.member.email.toLowerCase() == this.dependent.depEmail.toLowerCase()) 
            isEmailUnique = false;
        }
        //if email still unique, check dependents
        if(isEmailUnique){
          let duplicateEmails = this.dependentList.filter(x => x.email && x.email.toLowerCase() == this.dependent.depEmail.toLowerCase());
          if(duplicateEmails && duplicateEmails.length)
            isEmailUnique = false;
        }
      }
      return isEmailUnique;
    },
    addDependent() {
      if (this.depType === lookupTypes.dependentType.spouse || this.depType === lookupTypes.dependentType.ward) {        
        this.validPhoneInput = !this.depPrimaryPhoneObject.valid ? false : true;
      } else {
        this.validPhoneInput = true;
      }
      
      if (this.validPhoneInput && ((this.dependent.depSecondaryPhone.length > 0 && this.depSecPhoneObject.valid) || this.dependent.depSecondaryPhone.length < 1) && this.$refs.dependentForm.validate()) {

        if(this.checkIfDependentEmailIsUnique()){
          this.dependentList.push({
            firstName: this.dependent.depFirstName,
            middleName: this.dependent.depMiddleName,
            lastName: this.dependent.depLastName,
            externalID: this.dependent.depExternalId,
            hubspotId: this.dependent.depHubspotId,
            gender: this.dependent.depGender,
            phonePrimary: this.dependent.depPrimaryPhone,
            phoneSecondary: this.dependent.depSecondaryPhone,
            dateOfBirth: DateTime.fromFormat(this.dependent.depDob, "MM/dd/yyyy").toISO(),
            email: this.dependent.depEmail,
            primaryCountry: this.dependent.depCountry,
            primaryRegion: this.dependent.depRegion,
            primaryStreet: this.dependent.depAddress1,
            primaryStreetTwo: this.dependent.depAddress2,
            primaryPostal: this.dependent.depPostal,
            language: this.dependent.depLanguage,
            primaryCity: this.dependent.depCity,
            memberType: this.depType,
            usePrimaryAddress: this.depUsePrimaryAddress,
            created: DateTime.now().toISO(),
            lastUpdated: DateTime.now().toISO(),
            hasLogin: this.dependent.depEmail && this.dependent.depEmail.length > 0 ? true : false,
            memberID: this.depMemberID,
          });
          this.depEditingMode = false;
          this.clearDependent();
          this.isDirtyDependentForm = false;
        } else{
            this.$swal({
                title: "",
                icon: "warning",
                text: "Duplicate email found in the family! Please enter unique email for dependent!" ,
                confirmButtonColor: this.$vuetify.theme.themes.light.success,
                confirmButtonText: "OK",
                showCancelButton: false,
              })
        }
      } else {
        let phoneValidation = !this.validPhoneInput ? "- Dependent's Primary Phone Number is Required" : "";
        if (!this.dependent.depPrimaryPhone) {
          this.dependent.depPrimaryPhone = "+1";
        }
        let secondaryPhoneValidation = this.dependent.depSecondaryPhone && !this.depSecPhoneObject.valid ? "<br/> - Dependent's Secondary Phone Number is Invalid" : "";

        let formValidate = this.$refs.dependentForm.$children;

        this.$swal({
          title: "Error",
          icon: "error",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
          html: (
            phoneValidation + secondaryPhoneValidation +
            Object.keys(formValidate).map((key) => {
              if (formValidate[key].validations) {
                if (formValidate[key].validations.length > 0) {
                  return "<br>" + "- " + formValidate[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
        });
      }
    },
    saveMember(showSwal=true) {
      let primaryPhoneValidation = !this.primaryPhoneObject.valid ? "- Primary Phone Number is Required" : "";
      
      if (primaryPhoneValidation) {
        this.member.phonePrimary = "+1";
      }
      let secondaryPhoneValidation = this.member.phoneSecondary && !this.secPhoneObject.valid ? "<br/> - Secondary Phone Number is Invalid" : "";
      let swal = (validation) => {
        // let formValidate =
        //   validation == this.$refs.form.$children ? true : false;
        // let phoneNum = formValidate == true ? phoneValidation : "";

        this.$swal({
          title: "Error",
          icon: "error",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
          html: (
            primaryPhoneValidation + secondaryPhoneValidation +
            Object.keys(validation).map((key) => {
              if (validation[key].validations) {
                if (validation[key].validations.length > 0) {
                  return "<br>" + "- " + validation[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
        });

        return;
      };

      if (!this.$refs.employeeListForm.validate()) {
        return swal(this.$refs.employeeListForm.$children);
      }
      if (!this.$refs.form.validate() || !this.primaryPhoneObject.valid) {
        return swal(this.$refs.form.$children);
      }
      if(this.isDirtyDependentForm) {
        return this.$swal({
          title: "Error",
          icon: "error",
          text: `You have an unsaved ${this.employerStore.fieldLabelSettings.dependentLabel} form, click on the "${this.getDependentButtonText} ${this.employerStore.fieldLabelSettings.dependentLabel}" button to continue`,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
      }

      // checking if member selected a product
      if (!this.memberProductList.length) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Choosing a "+this.employerStore.fieldLabelSettings.serviceLabel+" is required to update this "+this.employerStore.fieldLabelSettings.subscriberLabel,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
        });
        return;
      }
      //if we made it this far check only secondary phone number again. 
      if (secondaryPhoneValidation.length > 1){
        this.$swal({
          title: "Error",
          icon: "error",
          html: secondaryPhoneValidation,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
        });
        return;
      }
      //isDirty has to be set to false else the prompt message would show up when the route is about to change 
      this.isDirty = false;
      
      let newPrimaryMember = convertToMemberObject(
        this.member.externalId,
        this.member.firstName,
        this.member.lastName,
        this.member.gender,
        this.member.middleName,
        DateTime.fromFormat(this.member.dob, "MM/dd/yyyy").toISO(),
        "3EEC12D8-E769-43CA-A06D-4F8E96AFB811",
        this.member.email,
        this.member.phonePrimary,
        this.member.phoneSecondary,
        this.member.primaryStreet,
        this.member.primaryStreetTwo,
        this.member.primaryCity,
        this.member.primaryRegion,
        this.member.primaryPostal,
        this.member.language,
        this.member.primaryCountry,
        "",
        true,
        true
      );
      newPrimaryMember.memberID = this.$route.query.memberID;
      this.overlay = true;
      
      if(this.dependentList.length > 0){
        this.dependentList.forEach( (x) => {
          if(!x.memberID)
            x.memberID = '00000000-0000-0000-0000-000000000000';            
        });
      }

      let familyPayload = {
        primaryMember: newPrimaryMember,
        dependents: this.dependentList,
        products: this.memberProductList,
        employerID: this.selectedEmployer,
        removeProducts: this.removedProducts,
        removeDependents: this.removeList,
        isNonReviveUser: this.isReviveAdmin,
        productEndDate: this.employerStore.memberSettings.endDate == 'currentDay' ? DateTime.now().toFormat("yyyy-MM-dd") : DateTime.now().endOf("month").toFormat("yyyy-MM-dd")
      };

      this.memberStore.updateFamily(familyPayload).then((res) => {
        this.createdMemberID = res.memberID;

        if(res.errors.length > 0){
          return this.$swal({
                    title: "Error",
                    icon: "error",
                    html: `<div>The following errors were encountered: </div> <br /> ` + res.errors.join('<br /> '),
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "OK",
                  });
        } else {
          this.successResponse(showSwal);
        }
      }).catch((err) => {
        return this.$swal({
                    title: "Error",
                    icon: "error",
                    html: `<div>The following errors were encountered: </div> <br /> ` + err,
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "OK",
                  });
      }).finally(() => this.overlay = false);

    },
    successResponse(showSwal){
      if(showSwal) {
        this.$swal({
          title: "Saved",
          icon: "success",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Continue",
          showCancelButton: false,
        }).then((okay) => {
          if (okay) {
            this.$router.push({ name: "subscriber_admin_index" });
          }
        });
      }else {
        this.$router.push({ name: "subscriber_admin_index" });
      }
    },
    //member product management
    removeMemberProduct: function (item) {
      const existingProduct = this.fullMemberObject.memberProducts.find(
        (x) => x.productID == item.productID
      );
      if(existingProduct){
        this.removedProducts.push(item.productID);
      }
      this.memberProductList = [
        ...this.memberProductList.filter((x) => x != item),
      ];
    },
    addProducts: function () {
      if (this.endDate && (this.startDate > this.endDate)) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "end date must be greater than start date",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
        });
        return;
      }

      //TODO: Revisit this. I think we may need to implement this different. For now the back end code manages it. 
      // if(this.removedProducts.includes(this.addProductList[0].productID)){
      //   this.removedProducts = [
      //     ...this.removedProducts.filter((x) => x != this.addProductList[0].productID),
      // ];
      // }
      //END TODO
      //check to see if there is an intersection of the child products of the sku. 
      if (this.memberProductList.length > 0)
      {
        let memberProductWithSubs = [];
        //loop through the exisitng to add to the list of current items to check against.
        this.memberProductList.forEach((memberProd) => {
          const found = this.productList.find(x => x.skuid == memberProd.productID);
          if (found)
          {
            memberProductWithSubs.push(found);
          }
        });
        const addProductIDList = [...this.addProductList.products.map(x => x.productID)];
        //check for an intersection.
        memberProductWithSubs.forEach((prod) => {
          const foundIntersection = prod.products.filter(x => addProductIDList.includes(x.productID));
          if (foundIntersection && foundIntersection.length > 0)
          {
            //intersection found, remove product that has the intersection
            this.memberProductList = [...this.memberProductList.filter(x => x.productID != prod.skuid)];
          }
        });
      }
      this.memberProductList.push({
        start: this.startDate,
        end: this.endDate,
        name: this.addProductList.name,
        productID: this.addProductList.skuid,
      });
      this.addProductList = {};
      this.productEditingMode = false;
      this.endDate = null;
      this.startDate = this.employerStore.memberSettings.startDate == 'currentDay' ? 
        DateTime.now().toFormat("yyyy-MM-dd") : 
        DateTime.now().plus({ month: 1 }).startOf("month").toFormat("yyyy-MM-dd");
    },
    primaryAddressToggle: function (toggle) {
      //toggle the fields. for display purposes only does not get committed to database in case there is a change to the primary after a dependent is created.
      if (toggle) {
        this.dependent.depAddress1 = this.member.primaryStreet;
        this.dependent.depAddress2 = this.member.primaryStreetTwo;
        this.dependent.depCity = this.member.primaryCity;
        this.dependent.depRegion = this.member.primaryRegion;
        this.dependent.depPostal = this.member.primaryPostal;
      }
      //Clear the toggle fields.
      else {
        this.dependent.depAddress1 = "";
        this.dependent.depAddress2 = "";
        this.dependent.depCity = "";
        this.dependent.depRegion = "";
        this.dependent.depPostal = "";
      }
    },
  },
};
</script>
