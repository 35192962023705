import apiClient from "../services/api";
import fileClient from "../services/api2";
import { defineStore } from "pinia";
// import { Swal } from "sweetalert2/dist/sweetalert2";
import { convertAuthZeroIdToUserID } from "../commons/user";
import { getInstance } from "../auth";
import { convertToSettingsType } from "../commons/employer";
import { lookupTypes } from "../data/definitions/lookupTypes";

export const useEmployerStore = defineStore("employer", {
  state: () => ({
      employerList: [],
      filteredEmployersList: [],
      hasBillingDetails: null,
      systemSettings: {
        allowSelfEnrollment: false,
        groupCreation: null,
        welcomeMail: null,
        reminderTiming: 0,
        enrollmentCode: null,
        allowMinorsAsPrimary: null,
        minimumPrimaryAge: null,
        minimumEmailAge: null
      },
      memberSettings: {
        profileEdit: null,
        displayDependent: null,
        dependentAddOrEdit: 2,
        directLogins: null,
        endDate: null,
        startDate: null,
        uploadType: null,
      },
      fieldLabelSettings: {
        groupLabel: 'Employer',
        serviceLabel: 'Product',
        subscriberLabel: 'Member',
        dependentLabel: 'Dependent',
        secondaryGroupLabel: 'Employers',
        secondaryServiceLabel: 'Products',
        secondarySubscriberLabel: 'Members',
        secondaryDependentLabel: 'Dependents',
        ProgramNameLabel: 'Program Name'
      },
      customizationSettings: {
        enableCustomization: null,
        primaryColor: null,
        secondaryColor: null,
        backgroundColor: null,
      }, 
      billingSettings: {
        billingMethod: null,
        enrolledInTheGroup: null, 
        BillingInfoCompleted: null
      },
      currentEmployerID: '',
      currentEmployerFull: {},
      currentEmployerType: 'Employer',
      currentCustomerType: null,
      isFromSelfService: false
  }),
  getters: {},
  actions: {
    async getAllEmployersByActor() {
      const data = await apiClient.get(`/employer/getAllEmployersByActor`);
      this.employerList = data.data;
      return data.data;
    },
    async getEmployersByActor(page,pageSize,showInactive,search,sortBy,sortDesc) {
      const data = await apiClient.get(`/employer/GetAllEmployersByActor2?page=${page}&pageSize=${pageSize}&search=${search}&sortBy=${sortBy}&sortDesc=${sortDesc}&showInactive=${showInactive}`);
      return data.data;
    },
    async filterEmployersByActor() {
      const data = await apiClient.get(`/employer/filterEmployersByActor`);
      this.filteredEmployersList = data.data;
    },
    async getAllEmployers(){
      const data = await apiClient.get(`/employer/getAllEmployers`);
      return data.data;
    },
    async getEmployerByID(employerID){
      const data = await apiClient.get(`/employer/getEmployerByID?employerID=${employerID}`);
      return data.data;
    },
    async getEmployerSubscription(employerID){
      const data = await apiClient.get(`/employer/GetSubscription?employerID=${employerID}`);
      return data.data;
    },
    //gets the parent name by id or N/A if not visible 
    getParentNameByID(parentID){
      if (this.employerList.length > 0)
      {
        const found = this.employerList.find(x => x.employerID == parentID);
        if (found)
        {return found.name}
        return 'N/A';
      }
    },
    // returns an employer's details
    async getFullEmployerByID(id){
      const data = await apiClient.get(`/employer/getFullEmployerByID?employerID=${id}`);
      return data.data;
    },
    async getEmployerSettingsFromHeirarchy(id){
      const data = await apiClient.get(`/employer/GetEmployerAttributesByEmployerWithHeirarchy?employerID=${id}`);
      return data.data;
    },
    async getEmployerAttributesByEmployer(id) {
      const data = await apiClient.get(`/employer/GetEmployerAttributesByEmployer?employerID=${id}`);
      return data.data;
    },
    async CheckIfEmployerIsFromSelfService(id){
      const data = await apiClient.get(`/employer/CheckIfEmployerIsFromSelfService?employerID=${id}`);
      return data.data;
    },
    async createEmployer(employer){
      const data = await apiClient.post(`/employer/createEmployer`, employer);
      return data.data;
    },
    async UploadFile(employerID, imageFile, oldFileLink){
      const data = fileClient.post(`/employer/UploadFile?employerID=${employerID}&oldFile=${oldFileLink}`, imageFile)
      return data;
      
      
    },
    refreshSettings() {
      const authService = getInstance();
      authService.assignEmployerSetings(convertAuthZeroIdToUserID(authService.user.sub))
    },
    async uploadContract(employerID, employerData, doc, oldFileLink=null) {

      let fileFormData = new FormData();
      fileFormData.append('file', doc);
      
      await this.UploadFile(employerID, fileFormData, oldFileLink).then((response) => {

        employerData.employerID = employerID;
        employerData.documentURL = response.data;
        this.updateEmployer(employerData).then(() => {
          this.refreshSettings();
        });

        return response;
      });
    },
    uploadLogo(employerID, image, customization, type, oldFileLink=null) {
      console.log("🚀 ~ file: employer.js:130 ~ uploadLogo ~ image:", image)
      let imageFormData = new FormData();
      imageFormData.append('file', image);
      
      this.UploadFile(employerID, imageFormData, oldFileLink).then((response) => {
        console.log("🚀 ~ file: employer.js:135 ~ this.UploadFile ~ imageFormData:", imageFormData)
        let settingsArray = [];
        if(type == 'mobileLogo') { customization.mobileLogo = response.data; }
        else { customization.mainLogo = response.data; }
        settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.customization, customization));
        this.assignSettings(employerID, settingsArray);
        
        return response;
      }).catch((err) => {
        return err;
      });
    },
    async updateEmployer(employer){
      const data = await apiClient.put(`/employer/updateEmployer?id=${employer.employerID}`, employer);
      return data.data;
    },
    async removeProduct(employerID, skuid){
      await apiClient.delete(`/employer/removeProduct?employerID=${employerID}&skuid=${skuid}`);
    },
    async deleteEmployer(employerID){
       await apiClient.delete(`/employer/deleteEmployer?id=${employerID}`);
    },
    async undeleteEmployer(employerID){
      await apiClient.delete(`/employer/unDeleteEmployer?id=${employerID}`);
    },
    async deleteLogo(employerID, file){
      await apiClient.delete(`/employer/deleteLogo?employerID=${employerID}&file=${file}`);
   },
    async assignProduct(employer, skuid, defaultPrice, isDefault, partners){
      const data = await apiClient.post(`/employer/assignProduct?employerID=${employer}&skuid=${skuid}&defaultPrice=${defaultPrice}&visitLimit=false&isDefault=${isDefault ?? false}`, partners);
      return data.data;
    },
    async assignSettings(employer, settings){
      const data = await apiClient.post(`/employer/assignSettings?employerID=${employer}`, settings);
      return data;
    },
    async assignSkuSettings(settings){
      const data = await apiClient.post(`/employer/assignSkuSettings`, settings);
      return data;
    },
    async assignUniqueID(employer, id){
      const data = await apiClient.post(`/employer/assignUniqueID?employerID=${employer}&uniqueID=${id}`);
      return data;
    },
    async assignHubspotID(employer, id=null, forceSubmit){
      const data = await apiClient.post(`/employer/assignHubspotID?employerID=${employer}&hubspotID=${id}&forceSubmit=${forceSubmit}`);
      return data;
    },
    async saveEmployerUploadSettings(uploadRequest){
      const data = await apiClient.post(`/employer/SaveMappingUploadSettings`, uploadRequest);
      return data;
    },
    async GetEmployerUploadSettings(employerID){
      const data = await apiClient.get(`/employer/GetMappingUploadSettings?employerID=${employerID}`);
      return data;
    },
    async downloadAllEmployers(){
      return await apiClient.get("/employer/DownloadAllEmployers", {
        responseType: 'blob'
      });
    },
    async assignVisitLimits(employerID, productVisitLimits){
      const data = await apiClient.post(`/employer/AssignVisitLimits?employerID=${employerID}`, productVisitLimits);
      return data;
    },
    async saveEmployerPricingStructure(payload){
      const data = await apiClient.post(`/EmployerPricing/SaveEmployerPricing`, payload);
      return data.data;
    },
    async getEmployerPricing(employerID){
      const data = await apiClient.get(`/EmployerPricing/GetEmployerPricing?employerID=${employerID}`);
      return data.data;
    },
    async getEmployerPricingWithHeirarchy(employerID){
      const data = await apiClient.get(`/EmployerPricing/GetEmployerPricingWithHeirarchy?employerID=${employerID}`);
      return data.data;
    },
    async getEmployerCustomFieldsForRegistration(employerID){
      return await fileClient.get("/Employer/GetEmployerCustomFieldsForRegistration?employerID="+employerID);
    }
  }
});
