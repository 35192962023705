export const lookupTypes = {
    attributes: {
        hubspotID: "433c12d8-e769-43ca-a06e-5f8e96afb911",
        externalID: "233c12d8-e769-43ca-a06d-4f8e96afb811",
        language: "233c12d8-e769-43ca-a06d-4f8e96afb812",
    },
    memberType: {
        primary: "3eec12d8-e769-43ca-a06d-4f8e96afb811",
        spouse: "3eec12d8-e769-43ca-a06d-4f8e96afb812",
        language: "233c12d8-e769-43ca-a06d-4f8e96afb812"
    },
    dependentType: {
        ward: "3eec12d8-e769-43ca-a06d-4f8e96afb813",
        dependent: "3eec12d8-e769-43ca-a06d-4f8e96afb814",
        spouse: "3eec12d8-e769-43ca-a06d-4f8e96afb812"
    },
    gender: {
        male: "3eec12d8-e769-43ca-a06d-4f8e96afb815",
        female: "3eec12d8-e769-43ca-a06d-4f8e96afb816",
        default: "3eec12d8-e769-43ca-a06d-4f8e96afb817"
    },
    settings: {
        system: "433c12d8-e769-43ca-a06d-5f8e96afb811",
        member: "433c12d8-e769-43ca-a06d-5f8e96afb812",
        fieldLabel: "433c12d8-e769-43ca-a06d-5f8e96afb813",
        customization: "433c12d8-e769-43ca-a06d-5f8e96afb814",
        billing: '419c12d8-d769-43cd-a06d-5f9a99dfb913',
        enableApps: 'f90d9d36-7f69-478d-8d27-62b55ef203a1'
    },
    employer: {
        hubspotID: "433c12d8-e769-43ca-a06e-5f8e96afb911"
    },
    employerType: {
        employer: "3fec12d8-e769-44ca-a06d-4f8e96afb81b",
        affiliate: "3fec12d8-e769-44ca-a06d-4f8e96afb129",
        DTC: "3fec12d8-e769-44ca-a06d-4f8e96afb128",
        HealthPlan: "3fec12d8-e769-44ca-a06d-4f8e96afb127",
        PEO: "3fec12d8-e769-44ca-a06d-4f8e96afb126",
        TPA: "3fec12d8-e769-44ca-a06d-4f8e96afb125",
        generalAgency: "3fec12d8-e769-44ca-a06d-4f8e96afb124",
        association: "3fec12d8-e769-44ca-a06d-4f8e96afb123",
        broker: "3fec12d8-e769-44ca-a06d-4f8e96afb122"
    },
    productAttributeType: {
        visitLimit: "0b4e32d8-9fc5-4392-bb84-48f78a4f9983",
        enableVisitLimitFee: "7345d046-5f9f-429f-9be3-a2620f53d066",
        serviceFee: "7345d046-5f9f-429f-9be3-a2620f53d067"
    },
    partnerType: {
        application: "6d5abab5-f0a5-4cf5-b1a8-167226734f1b",
    },
    partners:{
        reviveApp: '08f02ee1-8f93-45c9-8e74-9bd3de9bc655'
    }
};