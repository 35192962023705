<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginView",
  created() {
    this.$watch("$auth.loading", () => {
      this.$auth.loginWithRedirect();
    })
  },
};
</script>
