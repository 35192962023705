<template>
  <v-container>
    <HeaderComponent class="mb-12" />
    <v-card>
      <Dialog v-model.trim="dialog" :firstName="inviteDetails.firstName" :lastName="inviteDetails.lastName" :inviteID="inviteDetails.inviteId" @cancel="isDirty = false" />
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title class="justify-center font-weight-bold">
        <h3>{{parentEmployerName}} New Account Sign Up</h3>
      </v-card-title> 
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p class="font-weight-bold primary--text mt-3">
              {{employerStore.fieldLabelSettings.groupLabel}} Demographics:
            </p>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="inviteDetails.firstName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'First Name is required']" label="First Name" required></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="inviteDetails.lastName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'Last Name is required']" label="Last Name" required></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="inviteDetails.email" :rules="[ (v) => !!v || 'E-mail is required', (v) => /^\w+([.-]?\w+)*@[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]{1,})+$/.test(v) || 'E-mail must be valid' ]" label="E-mail Address" required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.name" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[0-9a-zA-Z-.£,'&()%# ]/ } } }" :rules="[(v) => !!v || 'Name is required']" label="Name" required></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.email"
                    :rules="[ rules.emailValidation(employer.email)]"
                    label="Email">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phone">Primary Phone</label>
                  <vue-tel-input id="phone" v-model.trim="employer.phone" @input="onPrimaryPhoneObject" />
                  <div v-if="employer.phone">
                    <span>
                      <strong v-if="!primaryPhoneObject.valid" class="error--text">Phone number is not valid</strong>
                    </span>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phone2">Secondary Phone</label>
                  <vue-tel-input id="phone2" v-model.trim="employer.phone2" @input="onSecPhoneObject" />
                  <div v-if="employer.phone2">
                    <span>
                      <strong v-if="!secPhoneObject.valid" class="error--text">Phone number is not valid</strong>
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.externalId" label="External ID" hint="This is the unique identifer your system uses"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" :rules="[(v) => !!v || 'Address is required']" label="Address Line 1" required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryStreetTwo" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 2"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } } }" :rules="[(v) => !!v || 'City is required']" label="City" required></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-5">
                <v-col cols="12" md="8">
                  <v-select v-model.trim="employer.primaryRegion" :items="states" :item-text="(item) => item.name" :rules="[(v) => !!v || 'Region is required']" :item-value="(item) => item.abbreviation" label="Select Region" required></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.primaryPostal" 
                  :rules="[
                    rules.zipCodeValidation(employer.primaryPostal)
                  ]"
                  v-maska="{
                    mask: '#*',
                    tokens: { '#': { pattern: /[0-9\-]/ } },
                  }"
                  maxlength="10" 
                  label="Postal Code" required></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-expansion-panels v-model.trim="shippingExpansionPanel">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="font-weight-bold primary--text mt-3">Shipping Address</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                          <v-row>
                            <v-col cols="4">
                              <v-switch label="Use The Billing Address" v-model.trim="useBillingAddress" @change="billingAddressToggle($event)"></v-switch>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 1" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping address is required']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreetTwo" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 2" :disabled="useBillingAddress"></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } } }" label="City" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping city is required']" required></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mb-5">
                            <v-col cols="12" md="8">
                              <v-select v-model.trim="employer.secondaryRegion" :items="states" :item-text="(item) => item.name" :item-value="(item) => item.abbreviation" label="Select Region" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping region is required']" required></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field v-model.trim="employer.secondaryPostal" 
                              :rules="[
                                rules.zipCodeValidationPostal(employer.secondaryPostal)
                              ]"
                              v-maska="{
                                mask: '#*',
                                tokens: { '#': { pattern: /[0-9\-]/ } },
                              }"
                              maxlength="10"
                              :disabled="useBillingAddress" required></v-text-field>
                            </v-col>
                          </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.secondaryServiceLabel}}: {{ employerProductList.length }} Added
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-item-group v-model.trim="addProductList">
                    <v-row>
                        <v-col md="3" v-for="product in filteredProductList" :key="product.skuid" class="m-2">
                          <product-list :isDefault="product.isDefault" :skuid="product.skuid" :name="product.name" :description="product.description" :defaultPrice="product.defaultPrice" :isAdmin="false"></product-list>
                        </v-col>
                    </v-row>
                  </v-item-group>

                  <v-row v-if="filteredProductList.length">
                    <v-col>
                      <v-btn color="success" class="ma-2" :disabled="addProductList.length < 1" @click="addProducts">Add</v-btn>
                    </v-col>
                  </v-row>

                  <v-row v-if="filteredProductList.length">
                    <v-col cols="12">
                      <p class="font-weight-bold primary--text mt-5">
                        {{employerStore.fieldLabelSettings.secondaryServiceLabel}} to Add:
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="employerProductHeaders" :items="employerProductList" hide-default-footer>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="removeEmployerProduct(item)">delete</v-icon>
                        </template>
                        <template v-slot:[`item.isDefault`]="{ item }">
                          <v-switch v-model.trim="item.isDefault" @change="handleChange($event, item.skuid, item.isDefault)"></v-switch>
                        </template>
                        <template v-slot:no-data>
                          No {{employerStore.fieldLabelSettings.serviceLabel}} added. Please add a {{employerStore.fieldLabelSettings.serviceLabel}} to this {{employerStore.fieldLabelSettings.groupLabel}}.
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="saveEmployer">Save</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="cancelInvitation">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Dialog from "./Dialog.vue";
import states from "../../../state.json";
import { useUserStore } from "../../stores/user";
import { useAuth0Store } from "../../stores/auth0";
import HeaderComponent from "../HeaderComponent.vue";
import ProductList from "../ProductListComponent.vue";
import { usePublicApiStore } from "../../stores/public";
import { useEmployerStore } from "../../stores/employer";
import { lookupTypes } from "../../data/definitions/lookupTypes";
import { convertToEmployerObject, convertToSettingsType } from "../../commons/employer";
import { convertAuthZeroIdToUserID, convertToAuth0UserObject, convertToUserObjectForDatabase } from "../../commons/user";

export default {
  name: "NewEmployerComponent",
  components: { ProductList, HeaderComponent, Dialog },
  setup() {
    const employerStore = useEmployerStore();
    const publicApiStore = usePublicApiStore();
    const auth0Store = useAuth0Store();
    const userStore = useUserStore();

    return { publicApiStore, employerStore, auth0Store, userStore, states };
  },
  data() {
    return {
      primaryPhoneObject: {
        valid: false,
      },
      secPhoneObject: {
        valid: false,
      },
      dialog: false,
      firstName: "",
      lastName: "",
      marker: true,
      isReviveAdmin: false,
      // isSubmit: false,
      validPhoneInput: true,
      valid: true,
      emloyeeIsValid: true,
      shippingExpansionPanel: 0,
      employer:{
        name: "",
        externalId: "",
        hubspotId: "",
        phone: "",
        phone2: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryCity: "",
        primaryRegion: "",
        primaryPostal: "",
        primaryCountry: "US",
        secondaryStreet: "",
        secondaryStreetTwo: "",
        secondaryCity: "",
        secondaryRegion: "",
        secondaryPostal: "",
        secondaryCountry: "US",
        groupID: "218347",
        email: ""
      },
      rules: {
        zipCodeValidation(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Postal code must be valid"
        },
        zipCodeValidationPostal(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Shipping Postal code must be valid";
        },
        emailValidation(email){
          if(!email)
            return true;
          if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))            
            return true;
          return "Email must be valid";
        }

      },
      selected: false,
      selectedEmployer: "",
      selectedEmployerDetails: {},
      categoryID: lookupTypes.employerType.employer,
      depType: "",
      productList: [],
      productListFromApi: [],
      addProductList: [],
      employerProductList: [],
      useBillingAddress: false,
      employerProductHeaders: [ { text: "Name", value: "name" }, { text: "Description", value: "description" }, { text: "Default", value: "isDefault" }, { text: "Remove", value: "actions", sortable: false } ],
      allProductStock: [],
      isDirty: false,
      employerType: [],
      parentEmployerName: "",
      inviteDetails: {
        inviteId: "",
        email: "",
        firstName: "",
        lastName: "",
        employerID: ""
      },
      overlay: false,
      employerRole: "",
      billingSettings: {}
    };
  },
  computed: {
    filteredProductList: {
      get() {
        if (this.employerProductList.length > 0) {
          let newList = [];
          //find products already added to employer and remove from available list.
          this.productList.forEach((product) => {
            const found = this.employerProductList.find( (x) => x.skuid == product.skuid );
            if (!found) { newList.push(product); }
          });
          return newList;
        }
        return this.productList;
      },
    }
  },
  methods: {
    redirectToHomePage(){
      this.isDirty = false;
      window.location.href = "/";
    },
    handleChange(e,id,value){
      this.employerProductList.forEach( (ep) => {
        if(ep.skuid.toLowerCase() === id.toLowerCase())
          ep.isDefault = value;
        else
          ep.isDefault = false;
      });

      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault);
      if (!checkForDefaultProduct) {
        return this.$swal({
          title: "Error",
          icon: "error",
          text: "Default product is not selected.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
      }
    },
    cancelInvitation() {
      this.publicApiStore.cancelInvite(this.inviteDetails.inviteId);
      this.redirectToHomePage();
    },
    preventReload(event) {
      // the beforeunload event functions based on the value of isDirty. if false, event would run but if true, user would receive a prompt message when reloading page 
      if (!this.isDirty) return
      event.preventDefault();
      event.returnValue = "";
    },
    getProductStockByEmployer() {
      this.publicApiStore
        .getProductStockByEmployer(this.inviteDetails.inviteId, this.inviteDetails.employerID)
        .then((res) => {
          this.productList = res.data ?? [];
          return;
        });
    },
    addProducts() {
      this.employerProductList.push(this.addProductList);
      this.addProductList = [];
    },
    removeEmployerProduct(item) {
      this.employerProductList = [...this.employerProductList.filter((x) => x != item) ];
    },
    onPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.primaryPhoneObject = phoneObject;
    },
    onSecPhoneObject(formattedNumber, phoneObject) {
      this.secPhoneObject = phoneObject;
    },
    saveEmployer(){

      let phoneValidation = !this.primaryPhoneObject.valid ? "- Phone Number is Required" : "";
      if (phoneValidation) { this.phone = "+1"; }
      let swal = (validation) => {
        let formValidate = validation == this.$refs.form._data.inputs ? true : false;
        let phoneNum = formValidate == true ? phoneValidation : "";

        this.$swal({
          title: "Error",
          icon: "error",
          html: (
            phoneNum +
            Object.keys(validation).map((key) => {
              if (validation[key].validations) {
                if (validation[key].validations.length > 0) {
                  return "<br>" + "- " + validation[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      };

      if (!this.$refs.form.validate() || !this.primaryPhoneObject.valid) {
        return swal(this.$refs.form._data.inputs);
      }

      if (!this.employerProductList.length > 0) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Choosing a " + this.employerStore.fieldLabelSettings.serviceLabel +" is required to create an "+this.employerStore.fieldLabelSettings.groupLabel,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }

      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault);
      if (!checkForDefaultProduct) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Choosing a default " + this.employerStore.fieldLabelSettings.serviceLabel +" is required to complete your sign up.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }

      this.overlay = true;

        //update invite details
        this.inviteDetails.newEmployerName = this.employer.name;
        this.publicApiStore.UpdateInvitation(this.inviteDetails.inviteId, this.inviteDetails);

      let employer = convertToEmployerObject(this.employer.name, this.employer.externalId, this.employer.hubspotId, this.employer.phone, this.employer.phone2, this.employer.primaryStreet, this.employer.primaryStreetTwo, this.employer.primaryCity, this.employer.primaryRegion, this.employer.primaryPostal, this.employer.primaryCountry,
                     this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.secondaryCity, this.employer.secondaryRegion, this.employer.secondaryPostal, this.employer.secondaryCountry, this.categoryID, this.inviteDetails.employerID, this.employer.groupID, this.employer.email);
      
                     
      this.publicApiStore.createEmployer(this.inviteDetails.inviteId, employer).then((res) => {
        if(res && res.employerID) {
          if (this.employerProductList.length > 0) {
            //assign product
            this.employerProductList.forEach((prod) => { this.publicApiStore.assignProduct( this.inviteDetails.inviteId, res.employerID, prod.skuid, prod.defaultPrice, prod.isDefault ); });
          }
          if (this.employer.externalId) {
            this.publicApiStore.assignUniqueID( this.inviteDetails.inviteId, res.employerID, this.employer.externalId );
          }
          //assign to hubspot
          this.publicApiStore.assignHubspotID(res.employerID, this.employer.hubspotId);

          let settingsArray = [];
          
          let billingSettings = { billingMethod: this.billingSettings.billingMethod == 'aggregateBilling' ? null : 'individualBilling', enrolledInTheGroup: true }

          let memberSettings = { profileEdit: true, displayDependent: true, 
            dependentAddOrEdit: 2, 
            directLogins: null, endDate: null, startDate: null, uploadType: null }
          
          settingsArray.push(convertToSettingsType(res.employerID, lookupTypes.settings.member, memberSettings));

          settingsArray.push(convertToSettingsType(res.employerID, lookupTypes.settings.billing, billingSettings));
            
          if (settingsArray.length > 0)
          { 
            this.publicApiStore.assignSettings(this.inviteDetails.inviteId, res.employerID, settingsArray);
          }

          // create an auth0 profile for the employer
          let userObject = convertToAuth0UserObject(this.inviteDetails.email, this.inviteDetails.firstName, this.inviteDetails.lastName, false);

         //save to auth0
          this.auth0Store.createUser(userObject).then((userRes) => {

            //assign role (auth0)
            if(this.employerRole){
              this.auth0Store.assignRole(userRes.user_id, { roles: this.employerRole.split() });
            }

            //save to database
            if(userRes.user_id){
              
              let user = convertToUserObjectForDatabase(this.inviteDetails.firstName, this.inviteDetails.lastName, this.inviteDetails.email, this.employer.phone, this.employer.phone2, res.employerID, convertAuthZeroIdToUserID(userRes.user_id), false, true, false);
              this.publicApiStore.createUser(this.inviteDetails.inviteId, user)
              .then((userResponse) => {
                
                // send welcome email
                this.publicApiStore.sendWelcomeEmail(this.inviteDetails.inviteId, this.inviteDetails.email, userObject.password, this.inviteDetails.firstName, window.location.origin + "/login").then(() => {
                  this.overlay = false;
                  //isDirty has to be set to false else the prompt message would show up when the route is about to change 
                  this.isDirty = false;
                  //complete invitation (sets completedSignUp to true and updates the employer's ID on the selfSignUp table)
                  this.publicApiStore.completeInvite(this.inviteDetails.inviteId, res.employerID);

                  return this.$swal({
                    title: "Saved",
                    icon: "success",
                    text: "You will receive an email with your user ID and temporary password so you can login to your company portal and add enrollment",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) { this.redirectToHomePage() }
                  });
                }).catch(() => {
                  this.overlay = false
                  this.userStore.deleteUser(userResponse.userID);
                  this.auth0Store.deleteUser(userRes.user_id);
                });
              })
              .catch((err) => {
                this.overlay = false;
                //if there's an error while adding to database, delete the user record from auth0
                this.auth0Store.deleteUser(res.user_id);

                this.$swal({
                  title: "Error",
                  icon: "error",
                  confirmButtonColor: this.$vuetify.theme.themes.light.success,
                  confirmButtonText: "Try Again",
                  html: Object.keys(err.data.errors).map((key) => { return "<br>" + "- " + err.data.errors[key][0]; }),
                })
              });
            }
          });

        }
      })
      .catch((err) => {
        this.overlay = false
        if(err.status == 400) {
          this.$swal({
            title: "Error",
            icon: "error",
            html: Object.keys(err.errors).map((key) => { return "<br>" + "- " + err.errors[key][0]; }),
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            confirmButtonText: "Try Again",
          });
        }
      });
    
    },
    billingAddressToggle(toggle) {
      //toggle the fields. for display purposes only does not get committed to database in case there is a change to the billing address.
      if (toggle) {
        this.employer.secondaryStreet = this.employer.primaryStreet;
        this.employer.secondaryStreetTwo = this.employer.primaryStreetTwo;
        this.employer.secondaryCity = this.employer.primaryCity;
        this.employer.secondaryRegion = this.employer.primaryRegion;
        this.employer.secondaryPostal = this.employer.primaryPostal;
        this.employer.secondaryCountry = this.employer.primaryCountry;
      }
      //Clear the toggle fields.
      else {
        this.employer.secondaryStreet = "";
        this.employer.secondaryStreetTwo = "";
        this.employer.secondaryCity = "";
        this.employer.secondaryRegion = "";
        this.employer.secondaryPostal = "";
        this.employer.secondaryCountry = "US";
      }
    },
  },
  watch: {
    /* using a deep watcher here helps to trigger this callback on a nested employer mutation. 
    Whenever a mutation occurs, the value of isDirty is set to true */
    employer: {
      handler: function(){
        this.isDirty = true
      },
      deep: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveEmployer();
        }else if(result.isDismissed){
          next();
        } else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  }, 
  mounted() {
    if(this.$route.query.Invite) {
      this.auth0Store.getAllRoles().then((res) => {
        this.employerRole = [...res.filter((x) => !x.name.includes("Revive"))][0].id;
      });
      // get invite details
      this.publicApiStore.getInviteById(this.$route.query.Invite)
      .then((response) => {
        
        this.employer.name = response.newEmployerName;
        this.inviteDetails = response;
        //make a call to get eployer ID so GA's name can be displayed on the top of this signup form
        this.publicApiStore.getEmployerByID(response.employerID).then((res) => {
          
          this.parentEmployerName = res.name;
          // get parent employer products
          this.getProductStockByEmployer();
          
        });
        this.dialog = true;

        //apply GA customization
        this.publicApiStore.getEmployerSettingsFromHeirarchy(response.employerID).then((employerSettings) => {
          let customizationSettings = employerSettings.find(
            (x) => x.attributeType == lookupTypes.settings.customization
          );
          let billingSettings = employerSettings.find(
            (x) => x.attributeType == lookupTypes.settings.billing
          );
          this.employerStore.customizationSettings = JSON.parse(customizationSettings.attributeValue);
          this.billingSettings = JSON.parse(billingSettings.attributeValue);
        });
      })
      .catch((error) => {
        this.$swal({
            title: "Error",
            icon: "error",
            html: error.data,
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            confirmButtonText: "Ok",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.redirectToHomePage()
            }
          });
      });

      this.publicApiStore.getEmployerTypeList().then((res) => {
        this.employerType = res;
      });
    }else{
      this.$swal({
        title: "Error",
        text: "No Employer Found",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((okay) => {
        if (okay) {
          this.redirectToHomePage();
        }
      });
    }
  },
};
</script>
