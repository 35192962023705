import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "material-design-icons-iconfont/dist/material-design-icons.css";
Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        grey: "#ececec",
        primary: "#2d4462",
        secondary: "#7cbf9a",
        error: "#C62828",
        warning: "#E65100",
        cancel: colors.grey.darken1,
        danger: "#FF0000",
        blue: "#24b2df",
        accept: colors.green.darken2,
        success: "#28a745",
        deny: "#757575",
        background: colors.shades.white,
        black: "#000000",
        white: "#ffffff",
        navColor: "#FBFBFC",
        navBackground: "#171B1D"
      },
    },
  },
});

export default vuetify;
