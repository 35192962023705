import apiWithoutAuthClient from "../services/apiWithoutAuth";
import { defineStore } from "pinia";

export const usePublicApiStore = defineStore("public", {
  state: () => ({}),
  getters: {},
  actions: {
    async sendWelcomeEmail(inviteID, email, password, name, loginUrl) {
      return await apiWithoutAuthClient.post(`/PublicEndpoints/sendWelcomeEmail?InviteID=${inviteID}&email=${email}&password=${password}&name=${name}&loginURL=${loginUrl}`);
    },
    async sendResetPasswordMail(email, password, name, loginUrl) {
      return await apiWithoutAuthClient.post(`/PublicEndpoints/sendResetPasswordMail?email=${email}&password=${password}&name=${name}&loginURL=${loginUrl}`);
    },
    async getProductStockByEmployer(inviteID, employerID) {
      try {
        return await apiWithoutAuthClient.get(`/PublicEndpoints/GetProductStockByEmployer?InviteID=${inviteID}&employerID=${employerID}`);
      }
      catch{ return []; }
    },
    async getEmployerTypeList() {
      const data = await apiWithoutAuthClient.get(`/PublicEndpoints/GetLookupListByType?type=Employer`);
      return data.data;
    },
    async generateInvite(url, employer) {
      const data = await apiWithoutAuthClient.post(`/PublicEndpoints/GenerateInvite?loginURL=${url}`, employer);
      return data.data;
    },
    async getInviteById(id){
      const data = await apiWithoutAuthClient.get(`/PublicEndpoints/GetInviteById?InviteID=${id}`);
      return data.data;
    },
    async cancelInvite(id) {
      await apiWithoutAuthClient.post(`/PublicEndpoints/UpdateInviteUsedToFalse?InviteID=${id}`)
    },
    async completeInvite(id, employerID) {
      await apiWithoutAuthClient.post(`/PublicEndpoints/CompleteInvite?InviteID=${id}&employerID=${employerID}`)
    },
    async createEmployer(inviteID, employer){
      const data = await apiWithoutAuthClient.post(`/PublicEndpoints/CreateEmployer?InviteID=${inviteID}`, employer);
      return data.data;
    },
    async createUser(inviteID, user){
      const data = await apiWithoutAuthClient.post(`/PublicEndpoints/createUser?InviteID=${inviteID}`, user);
      return data.data;
    },
    async UpdateInvitation(id, inviteDetails){
      const data = await apiWithoutAuthClient.put(`/PublicEndpoints/UpdateInvite?InviteID=${id}`, inviteDetails);
      return data.data;
    },
    async assignUniqueID(inviteID, employer, id){
      await apiWithoutAuthClient.post(`/PublicEndpoints/AssignUniqueID?InviteID=${inviteID}&employerID=${employer}&uniqueID=${id}`);
    },
    async assignProduct(inviteID, employer, skuid, defaultPrice, isDefault){
      await apiWithoutAuthClient.post(`/PublicEndpoints/AssignProduct?InviteID=${inviteID}&employerID=${employer}&skuid=${skuid}&defaultPrice=${defaultPrice}&visitLimit=false&isDefault=${isDefault ?? false}`);
    },
    async assignSettings(inviteID, employer, settings){
      await apiWithoutAuthClient.post(`/PublicEndpoints/AssignSettings?InviteID=${inviteID}&employerID=${employer}`, settings);
    },
    async assignHubspotID(employer, id=null){
      await apiWithoutAuthClient.post(`/PublicEndpoints/AssignHubspotID?employerID=${employer}&hubspotID=${id}`);
    },
    async getEmployerByID(employerID){
      const data = await apiWithoutAuthClient.get(`/PublicEndpoints/GetEmployerByID?employerID=${employerID}`);
      return data.data;
    },
    async getEmployerSettingsFromHeirarchy(id){
      const data = await apiWithoutAuthClient.get(`/PublicEndpoints/GetEmployerAttributesByEmployerWithHeirarchy?employerID=${id}`);
      return data.data;
    }
  }
});