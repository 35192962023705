<template>
  <v-container>
    <v-row justify="end">
      <v-btn color="primary" @click="createNewMessage">
        <v-icon class="mr-1">mdi-message-text-outline</v-icon>
        New Message
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Member Messages
            <v-spacer></v-spacer>
            <!-- start export component -->
            <v-btn
              class="mr-5"
              v-if="memberMessagingListing.length"
              @click="downloadCSV(memberMessagingListing)"
            >
              Download Current View
              <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <v-btn
              class="mr-5"
              v-if="allMemberMessages.length"
              @click="downloadCSV(allMemberMessages)"
            >
              Download All
              <v-icon class="ma-2">download</v-icon>
            </v-btn>
            <!-- end export component -->
            <v-switch
              v-model.trim="filters.showInactiveMessages"
              label="Show Inactive Messages"
              class="pa-3"
            ></v-switch>
          </v-card-title> 
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="'Search for Messages'"
                  v-model.trim="search"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-data-table
                  :items="memberMessagingListing"
                  :headers="headers"
                  :search="search"
                  :loading="isLoading"
                  loading-text="Loading... Please wait"
                  multi-sort
                >
                  <template v-slot:no-data>
                    No Results Found. Please try removing filters or expanding
                    your search.
                  </template>
                  <template v-slot:[`item.messageTitle`]="{ item }">
                    <a>{{ item.messageTitle }}</a>
                  </template>
                  <template v-slot:[`item.created`]="{ item }">
                    {{ cleanDate(item.created) }}
                  </template>
                  <template v-slot:[`item.active`]="{ item }">
                    {{ item.active ? "Active" : "Inactive" }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editMessage(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small class="mr-2" @click="deleteMessage(item)">
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="messageDialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ popOutHeader }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="messageValid" ref="messageForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model.trim="memberMessage.messageType"
                    :items="messageType"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.lookupID"
                    :label="'Message Type*'"
                    :rules="[(v) => !!v || 'Message Type is required']"
                    required
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    label="Message Title*"
                    v-model.trim="memberMessage.messageTitle"
                    :rules="[(v) => !!v || 'Message Title is required']"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label
                    class="v-label v-label--active theme--light"
                    style="font-size: 12px"
                    >Message Body*</label
                  >
                  <vue-editor
                    label="Message Body*"
                    hint="example of helper text only on focus"
                    v-model.trim="memberMessage.messageBody"
                    :rules="[(v) => !!v || 'Message Body is required']"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="true"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        type="textarea"
                        :rules="[(v) => !!v || 'End Date is required']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        label="End Date*"
                        :value="formatDate"
                      />
                    </template>
                    <v-date-picker
                      v-model.trim="memberMessage.endDate"
                      @input="endDatemenu = false"
                      :min="minCalendarDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" sm="4">
                  <v-select
                    v-model="memberMessage.active"
                    :items="status"
                    label="Message Status*"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog()"
            class="ma-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!this.isEdit"
            color="blue-darken-1"
            variant="text"
            @click="saveNewMessage"
            class="ma-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default success"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            color="blue-darken-1"
            variant="text"
            class="ma-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default success"
            @click="saveUpdatedMessage"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useMemberMessagingStore } from "../../stores/membermessaging";
import { useLookupStore } from "../../stores/lookup";
import { useEmployerStore } from "../../stores/employer";
import Swal from "sweetalert2";
import {
  cleanDate,
  calendarDate,
  addOneYearToDate,
  minCalendarDate,
} from "../../commons/date";
import { VueEditor } from "vue2-editor";
import { download } from "../../commons/employer";
export default {
  components: {
    VueEditor,
  },
  name: "MemberMessageListing",
  data: () => ({
    popOutHeader: "",
    panel: [0, 1],
    //general items
    filters: { showInactiveMessages: false },
    search: "",
    messageDialog: false,
    //member messaging items
    memberMessagingListing: [],
    allMemberMessages: [],
    startDateMenu: false,
    endDateMenu: false,
    startDate: null,
    endDate: null,
    memberMessage: {
      messageID: "00000000-0000-0000-0000-000000000000",
      messageType: "",
      messageTitle: "",
      messageBody: "",
      startDate: new Date(),
      endDate: addOneYearToDate(new Date()),
      active: true,
      created: new Date(),
      createdBy: "00000000-0000-0000-0000-000000000000",
      createdByName: "",
    },
    messageValid: true,
    status: [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ],
    isEdit: false,
    isLoading: false,
    //toolbar for vue-editor
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["link"],
      ["clean"],
    ],
  }),
  setup() {
    const memberMessagingStore = useMemberMessagingStore();
    const lookupStore = useLookupStore();
    const employerStore = useEmployerStore();

    return { memberMessagingStore, lookupStore, cleanDate,employerStore };
  },
  computed: {
    messageType: {
      get() {
        return this.lookupStore.messageType;
      },
    },
    headers: {
      get() {
        return [
          { text: "Message Title", value: "messageTitle" },
          { text: "Created By", value: "createdByName" },
          { text: "Created Date", value: "created" },
          { text: "Status", value: "active" },
          { text: "Actions", value: "actions", sortable: false },
        ];
      },
    },
    formatDate() {
      return this.memberMessage.endDate
        ? cleanDate(this.memberMessage.endDate)
        : "";
    },
  },
  mounted() {
    this.currentEmployerID = localStorage.getItem("currentEmployerID");
    this.pageLoad();
    this.lookupStore.getMessageTypeList();
  },
  watch: {
    filters: {
      handler: function () {
        this.pageLoad();
      },
      deep: true,
    },
  },
  methods: {
    //#region Form validation function
    validateForm(){
      let messageTypeValidation = !this.memberMessage.messageType ? "- Message Type is Required<br>" : "";
      let messageTitleValidation = !this.memberMessage.messageTitle ? "- Message Title is Required<br>" : "";
      let messageBodyValidation = !this.memberMessage.messageBody ? "- Message Body is Required<br>" : "";

      if(!this.$refs.messageForm.validate() || !this.memberMessage.messageBody)
      {
        
                Swal.fire({
                    icon: "error",
                    confirmButtonText: "Try Again",
                    html: (
                      messageTypeValidation + messageTitleValidation + messageBodyValidation
                    ).replace(/,/g, ""),
                });
                return false;
        }
        else{
          return true;
        }

    },
    //#endregion
    //#region CREATE NEW Member Message
    createNewMessage() {
      this.isEdit = false;
      this.popOutHeader = "New Message";
      this.memberMessage = {
        messageID: "00000000-0000-0000-0000-000000000000",
        messageType: "",
        messageTitle: "",
        messageBody: "",
        startDate: new Date(),
        endDate: addOneYearToDate(new Date()),
        active: true,
        created: new Date(),
        createdBy: "00000000-0000-0000-0000-000000000000",
        createdByName: "",
        employerID: this.currentEmployerID
      };
      this.messageDialog = true;
      this.$refs.messageForm.resetValidation();

    },
    async saveNewMessage() {
           
      if (this.validateForm()) {
        this.memberMessagingStore
          .createMemberMessage(this.memberMessage)
          .then(() => {
            this.messageDialog = false;
            this.successResponse();
            this.pageLoad();
          });
      }
    },
    //#endregion
    //#region UPDATE Member Message
    editMessage(message) {
      this.isEdit = true;
      this.messageDialog = true;
      this.popOutHeader = "Update Message";
      this.memberMessage = {
        messageID: message.messageID,
        messageType: message.messageType,
        messageTitle: message.messageTitle,
        messageBody: message.messageBody,
        startDate: calendarDate(message.startDate),
        endDate: calendarDate(message.endDate),
        active: message.active,
        created: message.created,
        createdBy: message.createdBy,
        createdByName: message.createdByName,
        employerID: this.currentEmployerID
      };
      this.memberMessage.active = message.active;
    },
    async saveUpdatedMessage() {
      if (this.validateForm()) {
        this.memberMessagingStore
          .updateMemberMessage(this.memberMessage)
          .then(() => {
            this.messageDialog = false;
            this.successResponse();
            this.pageLoad();
          });
      }
    },
    //#endregion
    //#region LoadPage
    pageLoad() {
      this.isLoading = true;

      this.memberMessagingStore.getAllMemberMessagesByEmployerID(this.currentEmployerID).then((result) => {
        let memberMessagingListing = result;

        let messages = this.filters.showInactiveMessages
          ? memberMessagingListing.filter((x) => !x.active)
          : memberMessagingListing.filter((x) => x.active);

        this.memberMessagingListing = messages.filter((x) => !x.deleted);

        //export all list
        this.allMemberMessages = memberMessagingListing;
        this.isLoading = false;
      });
    },
    //#endregion
    //#region DELETE Member Message
    deleteMessage(message) {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: `You are about to delete this Message, Title: ${message.messageTitle}. Do you wish to continue?`,
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.memberMessagingStore
            .deleteMemberMessage(message.messageID)
            .then(() => {
              this.pageLoad();
            });
        }
      });
    },
    //#endregion
    //#region Close Open Dialog
    closeDialog() {
      this.$refs.messageForm.resetValidation();
      this.messageDialog = false;
      //this.$refs.messageForm.resetValidation();
    },
    //#endregion
    //#region Download CSV File
    csvDate() {
      return cleanDate(new Date());
    },
    downloadCSV(message) {
      const csv = this.$papa.unparse(
        { fields: this.csvHeaders, data: message },
        { columns: this.csvColumns }
      );
      // download function
      download(
        csv,
        `Message List_${new Date().toISOString().substring(0, 19)}.csv`
      );
    },

    //#endregion
    //set minimum Calendar Date
    minCalendarDate() {
      return minCalendarDate();
    },
    //region Alerts
    successResponse() {
      this.$swal({
        title: "Saved",
        icon: "success",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        showCancelButton: false,
      }).then(() => {});
    },
    //#endregion
  },
};
</script>
<style>
.itemRow {
  background: #cdcdcd;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:nth-child(3) {
  display: none !important;
}

.itemRow,
.itemRowDep {
  border-bottom: 1px solid #787878;
}
#quill-container > div.ql-tooltip.ql-editing{
  position: initial !important; 
}

::v-deep  .v-data-footer {
  padding: 0 60px;
}

</style>
