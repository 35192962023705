import { DateTime } from "luxon";

export const convertToAuth0UserObject = (email, firstName, lastName, isEditMode) => {
  const user = {
    email,
    given_name: firstName,
    family_name: lastName,
    name: firstName + ' ' + lastName,
    connection: 'Username-Password-Authentication',
    email_verified: true,
    verify_email: false,
  }
  if(!isEditMode){ 
    user.blocked = false;
    user.password = generateRandomPassword();
  }
  return user;
};

export const convertToUserObjectForDatabase = (firstName, lastName, email, phonePrimary, phoneSecondary, employerID, authZeroID, isAdmin, forcePasswordReset, isEditMode) => {
  const employer = {
    created: DateTime.now().toISO(),
    lastUpdated: DateTime.now().toISO(),
    firstName,
    lastName,
    email,
    phonePrimary,
    phoneSecondary,
    employerID,
    authZeroID,
    forcePasswordReset,
    isAdmin
  };
  if(!isEditMode){
    employer.isDeleted = false;
  }
  return employer;
};

export const generateRandomPassword = () => {
  let chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "!@*()_-|}{", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
  let randPwd = [4,1,3,2].map(function(len, i) { return Array(len).fill(chars[i]).map(function(x) { return x[Math.floor(secureRandom() * x.length)] }).join('') }).concat().join('').split('').sort(function(){return 0.9-secureRandom()}).join('');
  return randPwd;
}

export const randomString = (length) => {
  let str = '';
  let chars ='0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz0123456789'.split('');
  let charsLen = chars.length;
  if (!length)
    length = ~~(secureRandom() * charsLen);
  for (let i = 0; i < length; i++) {
    str += chars[~~(secureRandom() * charsLen)];
  }
  return str;
};

export const convertToAuthZeroID = (userID) => {
  return `auth0|${userID}`;
}

export const convertAuthZeroIdToUserID = (authZeroID) => {
  return authZeroID.replace('auth0|','');
}

function secureRandom() {
  let array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] / (Math.pow(2, 32) - 1);
}