<template>
  <v-container>
    <HeaderComponent class="mb-12" />
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
       
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-img src="../../assets/ReviveHealth_SmillingImage.png"></v-img>
          </v-col>
          <v-col cols="12" md="1"></v-col>
          <v-col cols="12" md="7">
            <h1 class="font-weight-bold primary--text mb-5 fs-35">{{parentEmployerName}} Account Sign Up</h1>
            <div class="font-weight-normal fs-16">
              <p>You need benefits that work for your business and staff. We have solutions that improve access to care, reduce cost, and improve employee satisfaction with your benefits offerings.</p>
              <p>We just need a few minutes to get your company signed up</p>
            </div>
            <div>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Provide your email address so we can send you an email with a link to confirm your email address and begin the process."></v-checkbox>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Accept our online terms & conditions.  Please make sure you’re allowed to accept legal terms on behalf of your company."></v-checkbox>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Tell us a little more about your company."></v-checkbox>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Select the product(s) you’ll be enrolling your employees or members in."></v-checkbox>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Check your email for your temporary user ID and password."></v-checkbox>
              <v-checkbox class="rounded-circle mb-n7" readonly v-model.trim="selected" label="Provide your payment details (bank acount or credit card). Please have these details handy before you start the process."></v-checkbox>
              <v-checkbox class="rounded-circle" readonly v-model.trim="selected" label="Add your employees/members."></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="employer.firstName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'First Name is required']" label="First Name" required></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="employer.lastName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'Last Name is required']" label="Last Name" required></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="employer.email" :rules="[ (v) => !!v || 'E-mail is required', (v) => /^\w+([.-]?\w+)*@[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]{1,})+$/.test(v) || 'E-mail must be valid' ]" label="E-mail Address" required></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field v-model.trim="employer.newEmployerName" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()%# ]/ } } }" :rules="[(v) => !!v || 'Company Name is required']" label="Company Name" required></v-text-field>
                </v-col>
                
              </v-row>
            </v-form>
            <p class="font-weight-bold secondary--text mt-5">A message will be sent to your email inbox to verify your email address before proceeding</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="sendInvite">Send Verification Email</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="redirectToHomePage">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useEmployerStore } from "../../stores/employer";
import { usePublicApiStore } from "../../stores/public";
import { lookupTypes } from '../../data/definitions/lookupTypes';
import HeaderComponent from "../HeaderComponent.vue";

export default {
  name: "SelfSignUpComponent",
  components: { HeaderComponent },
  setup() {
    const employerStore = useEmployerStore();
    const publicApiStore = usePublicApiStore();

    return { publicApiStore, employerStore };
  },
  data() {
    return {
      selected: true,
      employer:{
        firstName: "",
        lastName: "",
        email: "",
        newEmployerName: "",
        employerID: ""
      },
      parentEmployerName: "",
      showSwal: true,
      valid: true,
      overlay: false,
      isDirty: false
    };
  },
  methods: {
    redirectToHomePage(){
      this.isDirty = false;
      window.location.href = "/";
    },
    preventReload(event) {
      // the beforeunload event functions based on the value of isDirty.  if false, event would run but if true, user would receive a prompt message when reloading page
      if (!this.isDirty) return
      event.preventDefault()
      event.returnValue = ""
    },
    sendInvite(){

      if(!this.$refs.form.validate()){
        return this.$swal({
          title: "Error",
          icon: "error",
          html: ("" + Object.keys(this.$refs.form._data.inputs).map((key) => {
            if (this.$refs.form._data.inputs[key].validations) {
              if (this.$refs.form._data.inputs[key].validations.length > 0) {
                return "<br>" + "- " + this.$refs.form._data.inputs[key].validations[0];
              }
            }
          })).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      }
      this.overlay = true;
      //send invite
      let employerObject = this.employer;
      employerObject.email = this.employer.email.toLowerCase();
      this.publicApiStore.generateInvite(window.location.origin + "/completeEmployerSignUp", employerObject)
      .then(() => {
        this.overlay = false;
        this.isDirty = false;

        if(this.showSwal) {
          return this.$swal({
            title: "Saved",
            icon: "success",
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            confirmButtonText: "OK",
            text: "You will receive an email with your user ID and temporary password so you can login to your company portal and add enrollment"
          }).then((result) => {
            if (result.isConfirmed) {
              // this.$router.push({ name: "home" });
              this.redirectToHomePage()
            }
          });
        }else {
          this.showSwal = true;
          this.redirectToHomePage()
          // this.$router.push({ name: "home" });
        }
      })
      .catch((err) => {
        this.overlay = false;
        
        if(err.status == 400) {
          this.$swal({
            title: "Error",
            icon: "error",
            html: Object.keys(err.data.errors).map((key) => { return "<br>" + "- " + err.data.errors[key][0]; }),
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            denyButtonColor: this.$vuetify.theme.themes.light.deny,
            confirmButtonText: "Try Again",
          });
        }
        else{
          this.$swal({
            title: "Error",
            icon: "error",
            html: err.data,
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            denyButtonColor: this.$vuetify.theme.themes.light.deny,
            confirmButtonText: "Try Again",
          });
        }
      });
    
    }
  },
  watch: {
    // using a deep watcher here helps to trigger this callback on a nested employer mutation. Whenever a mutation occurs, the value of isDirty is set to true 
    employer: {
      handler: function(){
        this.isDirty = true
      },
      deep: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) {
          this.showSwal = false;
          this.sendInvite();
        }else if(result.isDismissed){
          next();
        } else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  }, 
  mounted() {
    this.employer.employerID = this.$route.query.employerID;
    let reg = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if(this.$route.query.employerID && reg.test(this.$route.query.employerID)) {
      //apply GA customization
      this.publicApiStore.getEmployerSettingsFromHeirarchy(this.$route.query.employerID).then((employerSettings) => {
        let customization = employerSettings.find( (x) => x.attributeType == lookupTypes.settings.customization );
        this.employerStore.customizationSettings = JSON.parse(customization.attributeValue);
      });

      //make a call to get eployer ID so GA's name can be displayed on the top of this signup form
      this.publicApiStore.getEmployerByID(this.$route.query.employerID).then((res) => {
        this.parentEmployerName = res.name;
      });
    }else{
      this.$swal({
        title: "Error",
        text: "The URL you have entered is invalid. Please check the URL and try again. If you are unable to find your email or are still having difficulty, please contact support at 888-220-6650.",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((okay) => {
        if (okay) {
          this.isDirty = false;
          this.redirectToHomePage();
          // this.$router.push({ name: "home" });
        }
      });
    }
  },
};
</script>

<style scoped>
  .fs-16{
    font-size: 16px;
  }
  .fs-35{
    font-size: 35px;
    line-height: 1;
  }
</style>