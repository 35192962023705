import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import { useAuthStore } from "../stores/auth";
import { useAuth0Store } from "../stores/auth0";
import { useUserStore } from "../stores/user";
import { useEmployerStore } from "../stores/employer";
import { lookupTypes } from "../data/definitions/lookupTypes";
import { convertAuthZeroIdToUserID } from "../commons/user";
import { getEmployerType } from "../commons/employer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useChargifyStore } from '../stores/chargify';
import router from '../router';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        forceResetpassword: null,
        completedBillingInfo: null,
        enrolledInTheGroup: null,
        employerID: null,
        fullEmployerDetails: {}
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        window.localStorage.clear();
        return this.auth0Client.logout(o);
      },
      async createAuth0ClientMethod(connectionName=""){
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          client_id: options.clientId,
          redirect_uri: redirectUri,
          connection: connectionName
        });

        try {
          // If the user is returning to the app after authentication..
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            // handle the redirect and retrieve tokens
            const { appState } = await this.auth0Client.handleRedirectCallback();

            this.error = null;

            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
          } 
          //check if url contains an error and error_description query parameter
          if(
            window.location.search.includes('error=') &&
            window.location.search.includes('error_description=')
          ){
            Swal.fire({
              title: "User Account is Locked",
              text: "This account is currently inactive. If you believe this is in error, please contact your system administrator or contact Revive support at 904-453-8029 ext 1006",
              icon: "error",
              confirmButtonColor: "#28a745",
              confirmButtonText: "Ok",
              showCancelButton: false,
            })
          }
        } catch (e) {
          this.error = e;
        } finally {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.user = await this.auth0Client.getUser();
          // assignment to pinia auth store
          const store = useAuthStore();
          if(this.isAuthenticated){
            const auth0Store = useAuth0Store();
  
            //get the user role
            auth0Store.getUserRoles(this.user.sub).then((res) => {
              store.userRole = res[0]?.name;
              window.localStorage.setItem("isAuthenticated", 1);
              window.localStorage.setItem("roleName", res[0]?.name);

              assignEmployerSetings(this.user.sub);
            });
          }

          this.loading = false;
          store.authenticated = true;
          store.user = this.user;
          this.$auth.getTokenSilently().then((tok) => {
            
            store.accessToken = tok;
            store.setTokenObject(tok);
          });
        }
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    created() {
      this.createAuth0ClientMethod();
    }
  });

  return instance;
};

const assignEmployerSetings = (authZeroID) => {
        
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const chargifyStore = useChargifyStore();
  const employerStore = useEmployerStore();
  let settingsInstance = getInstance();
        
  let userID = convertAuthZeroIdToUserID(authZeroID);

  //store user id to user store
  userStore.userID = userID;

  userStore.getUserByAuthZeroID(userID).then((authResponse) => {
    //store user details from database to auth store
    authStore.userDetailsFromDatabase = authResponse;
    //set force reset password
    settingsInstance.forceResetpassword = authResponse.forcePasswordReset;
    //Store the current employer's id
    employerStore.currentEmployerID = authResponse.employerID;
    window.localStorage.setItem("currentEmployerID", authResponse.employerID);

    settingsInstance.employerID = authResponse.employerID;
    //Get and store the current employer 
    employerStore.getEmployerByID(employerStore.currentEmployerID).then((res) => {
      employerStore.currentEmployerFull = res;
      
      settingsInstance.fullEmployerDetails = res;
      if(res.billingID && !res.billingInfoCompleted) 
      { chargifyStore.getEmployerSubscription(res.employerID).then((res) => { if(res.billingInfoCompleted) { router.go(); } }); }

      if(res) {
        employerStore.hasBillingDetails = res.billingInfoCompleted ? true : false;
        employerStore.currentEmployerType = getEmployerType(res.categoryID);
        employerStore.currentCustomerType = res.customerType;
      }

    });

    // //assign settings to employer store
    assignSettings(authResponse.employerID);
  });
}

const assignSettings = (employerID) => {
  const employerStore = useEmployerStore();
  let settingsInstance = getInstance();

  employerStore.CheckIfEmployerIsFromSelfService(employerID).then(data => employerStore.isFromSelfService = data.enrolledFromSelfServiceSignUp);

  employerStore.getEmployerSettingsFromHeirarchy(employerID).then((employerSettings) => {
      
    let billingSettings = employerSettings.find(
      (x) => x.attributeType == lookupTypes.settings.billing
    );
    let systemSettings = employerSettings.find(
      (x) => x.attributeType == lookupTypes.settings.system
    );
    let memberSettings = employerSettings.find(
      (x) => x.attributeType == lookupTypes.settings.member
    );
    let fieldLabel = employerSettings.find(
      (x) => x.attributeType == lookupTypes.settings.fieldLabel
    );
    let customization = employerSettings.find(
      (x) => x.attributeType == lookupTypes.settings.customization
    );
    employerStore.systemSettings = JSON.parse(systemSettings.attributeValue);
    employerStore.memberSettings = JSON.parse(memberSettings.attributeValue);
    if (billingSettings?.attributeValue)
    {
      employerStore.billingSettings = JSON.parse(billingSettings.attributeValue);
      settingsInstance.completedBillingInfo = employerStore.billingSettings.BillingInfoCompleted;
      settingsInstance.enrolledInTheGroup = employerStore.billingSettings.enrolledInTheGroup;
    }
    else {
      employerStore.billingSettings = {
        billingMethod: null,
        enrolledInTheGroup: null, 
      } 
    }
    
    let fieldLabels = JSON.parse(fieldLabel.attributeValue);
    employerStore.customizationSettings = JSON.parse(customization.attributeValue);
    employerStore.fieldLabelSettings.groupLabel = fieldLabels.groupLabel ? fieldLabels.groupLabel : employerStore.fieldLabelSettings.groupLabel;
    employerStore.fieldLabelSettings.serviceLabel = fieldLabels.serviceLabel ? fieldLabels.serviceLabel : employerStore.fieldLabelSettings.serviceLabel;
    employerStore.fieldLabelSettings.dependentLabel = fieldLabels.dependentLabel ? fieldLabels.dependentLabel : employerStore.fieldLabelSettings.dependentLabel;
    employerStore.fieldLabelSettings.subscriberLabel = fieldLabels.subscriberLabel ? fieldLabels.subscriberLabel : employerStore.fieldLabelSettings.subscriberLabel;
    //secondary fields
    employerStore.fieldLabelSettings.secondaryGroupLabel = fieldLabels.secondaryGroupLabel ? fieldLabels.secondaryGroupLabel : employerStore.fieldLabelSettings.secondaryGroupLabel;
    employerStore.fieldLabelSettings.secondaryServiceLabel = fieldLabels.secondaryServiceLabel ? fieldLabels.secondaryServiceLabel : employerStore.fieldLabelSettings.secondaryServiceLabel;
    employerStore.fieldLabelSettings.secondaryDependentLabel = fieldLabels.secondaryDependentLabel ? fieldLabels.secondaryDependentLabel : employerStore.fieldLabelSettings.secondaryDependentLabel;
    employerStore.fieldLabelSettings.secondarySubscriberLabel = fieldLabels.secondarySubscriberLabel ? fieldLabels.secondarySubscriberLabel : employerStore.fieldLabelSettings.secondarySubscriberLabel;
  });
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};