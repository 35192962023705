import { DateTime } from "luxon";
import { lookupTypes } from "../data/definitions/lookupTypes";

export const customerTypes = [
  { text: "Revive", value: 'revive' },
  { text: "iSelect", value: "iSelect" },
  { text: "Manifest", value: "manifest" },
  { text: "BHS", value: "bhs" },
  { text: "Swift", value: "swift" }
];

export const getEmployerType = (lookupID) => {
  switch (lookupID.toUpperCase())
  {
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB122':
      return 'Broker';
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB123':
      return 'Association';
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB124':
      return 'General Agency'; 
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB125':
      return 'TPA';
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB126':  
      return 'PEO';
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB127':
      return 'Health Plan';
    case '3FEC12D8-E769-44CA-A06D-4F8E96AFB81B':
      return 'Employer';
  }
}

export const employerPricingStructureTypes = {
  pmpm: "d774e9e0-c8ab-4858-8fdc-bad5ca35c4b1",
  blended: "d774e9e0-c8ab-4858-8fdc-bad5ca35c4b2",
  tiered3: "d774e9e0-c8ab-4858-8fdc-bad5ca35c4b3",
  tiered4: "d774e9e0-c8ab-4858-8fdc-bad5ca35c4b4",
};

export const convertToEmployerObject = ( name, externalId, hubspotId, phone, phone2, primaryStreet, primaryStreetTwo, primaryCity, 
        primaryRegion, primaryPostal, primaryCountry, secondaryStreet, secondaryStreetTwo, secondaryCity, secondaryRegion, secondaryPostal, 
        secondaryCountry, categoryID, selectedEmployer, groupID, discountPercentage, numberOfMonths, customerType, hasCopay, email) => {
  return {
    created: DateTime.now().toISO(),
    lastUpdated: DateTime.now().toISO(),
    isDeleted: false,
    name: name,
    externalID: externalId,
    hubspotID: hubspotId,
    phonePrimary: phone,
    phoneSecondary: phone2,
    primaryStreet,
    primaryStreetTwo,
    primaryCity,
    primaryRegion,
    primaryPostal,
    primaryCountry,
    secondaryStreet,
    secondaryStreetTwo,
    secondaryCity,
    secondaryRegion,
    secondaryPostal,
    secondaryCountry,
    categoryID,
    parentID: selectedEmployer,
    billingAsPrimary: true,
    groupID,
    discountPercentage, 
    numberOfMonths,
    customerType,
    hasCopay,
    email
  };
};

export const convertToSettingsType = (employerID, attributeType, payload) => {
  return {
    employerID: employerID,
    attributeID: null,
    attributeType: attributeType,
    attributeValue: typeof(payload) == 'object' ? JSON.stringify(payload) : payload, 
    required: false,
    language: 'en-us',
    created: DateTime.now().toISO(),
    lastUpdated: DateTime.now().toISO(),
  }
}

export const convertToAssignLimitType = (employerID, visitLimitPayload) => {
  let visitLimitArray = [];

  visitLimitPayload.forEach((payload) => {

    //add for enableFeeForService
    visitLimitArray.push({
      employerID: employerID,
      attributeID: null,
      attributeType: lookupTypes.productAttributeType.enableVisitLimitFee,
      attributeValue: payload.enableFeeForService == true ? 1 : 0, 
      productID: payload.productID,
      created: DateTime.now().toISO(),
      lastUpdated: DateTime.now().toISO(),
    });

    //add for serviceFee
    visitLimitArray.push({
      employerID: employerID,
      attributeID: null,
      attributeType: lookupTypes.productAttributeType.serviceFee,
      attributeValue: payload.serviceFee ?? 0, 
      ProductID: payload.productID,
      created: DateTime.now().toISO(),
      lastUpdated: DateTime.now().toISO(),
    });

    //add for visitLimit
    visitLimitArray.push({
      employerID: employerID,
      attributeID: null,
      attributeType: lookupTypes.productAttributeType.visitLimit,
      attributeValue: payload.visitLimit, 
      ProductID: payload.productID,
      created: DateTime.now().toISO(),
      lastUpdated: DateTime.now().toISO(),
    });
  });

  return visitLimitArray;
}

export const convertToCustomerAttribute = (first_name, last_name, email, zip, phone, state, country, city, address, address_2, organization) => {
  return { first_name, last_name, email, zip, phone, state, country, city, address, address_2, organization }
}

export const getNewPayload = (payload) => {
  return {
    mainLogo: payload.mainLogo,
    backgroundColor: payload.backgroundColor,
    brandingType: payload.brandingType,
    enableCustomization: payload.enableCustomization,
    mobileLogo: payload.mobileLogo,
    primaryColor: payload.primaryColor,
    secondaryColor: payload.secondaryColor,
    secondaryTextColor: payload.secondaryTextColor,
    headerColor: payload.headerColor,
    headerTextColor: payload.headerTextColor,
    footerColor: payload.footerColor,
    footerTextColor: payload.footerTextColor,
    primaryActionColor: payload.primaryActionColor,
    secondaryActionColor: payload.secondaryActionColor,
    phoneNumber: payload.phoneNumber
  }
}

export const convertToSettingsPayload = (employerID, payload, hubspotId) => {
  let settingsArray = [];

  if (payload.billing)
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.billing, payload.billing));
  if (payload.system)
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.system, payload.system));
  if (payload.customization)
  {
    const tempPayload = getNewPayload(payload.customization);
    if(tempPayload.mainLogo != null && typeof(tempPayload.mainLogo) == 'object' && JSON.stringify(tempPayload.mainLogo) == '{}'){
      tempPayload.mainLogo = null;
    }
    if(tempPayload.mobileLogo != null && typeof(tempPayload.mobileLogo) == 'object' && JSON.stringify(tempPayload.mobileLogo) == '{}'){
      tempPayload.mobileLogo = null;
    }
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.customization, tempPayload));
  }
  if (payload.fieldLabel)
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.fieldLabel, payload.fieldLabel));
  if (payload.member)
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.settings.member, payload.member));
  if(hubspotId)
    settingsArray.push(convertToSettingsType(employerID, lookupTypes.employer.hubspotID, hubspotId));

  return settingsArray;
}

export const handleRedirectAfterEditingEmployer = (billingMethod, billingCustomerID, employerID) => {
  if(billingMethod && !billingCustomerID){
    return { name: "billing", query: {employerID: employerID} };
  }else {
    return { name: "group_admin_index" };
  }
}

export const handleRedirectAfterSavingEmployer = (invoiceCollection, billingMethod, employerID) => {
  if(billingMethod && invoiceCollection != 'remittance'){
    return { name: "billing", query: {employerID: employerID} }
  }else {
    return { name: "group_admin_index" }
  }
}

export const download = (csv, fileName) => {
  let blob = new Blob([csv]);
  let a = document.createElement("a");
  a.href = URL.createObjectURL(blob, {type: "text/plain"});
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const downloadPDF = (file, fileName) => {  
  let url = process.env.VUE_APP_FRONT_END_URL + file;

  fetch(url)
  .then(r => r.blob()).then(blobContract => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blobContract, {type: "application/pdf"});
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  })
}