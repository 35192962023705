<template>
  <div>Settings View</div>
</template>

<script>

export default {
  name: "SettingsView",
};
</script>
