import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useMemberMessagingStore = defineStore("membermessaging", {
  state: () => ({}),
  getters: {},
  actions: {
    //Get all member messages information filtered by employeeID
    async getAllMemberMessagesByEmployerID(employerID) {
      console.log(employerID)
      const data = await apiClient.get("/membermessaging/getAllMemberMessagesByEmployerID?employerId="+employerID);
      return data.data;
    },

    //create and update members
    async createMemberMessage(memberMessage) {
      const data = await apiClient.post(
        "/membermessaging/createMemberMessage",
        memberMessage
      );
      return data.data;
    },

    async updateMemberMessage(memberMessage) {
      const data = await apiClient.put(
        "/membermessaging/updateMemberMessage",
        memberMessage
      );
      return data.data;
    },

    //delete member message
    async deleteMemberMessage(messageID) {
      await apiClient.delete(
        "/membermessaging/deleteMemberMessage?messageID=" + messageID
      );
    },
  },
});
