<template>
  <v-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-space-between">
      <h3>Reporting</h3>
      <v-btn color="success" @click="exportToPDF" class="my-3">Export to PDF</v-btn>
    </div>
    <v-row v-if="employerList.length">
      <v-col cols="12">
        <v-autocomplete :label="'Select ' + employerStore.fieldLabelSettings.groupLabel " v-model="selectedEmployer" :items="employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" 
          @change="onSelectedEmployerChange()" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']" required></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" v-if="dynamicFilterOptions.length">
        <dynamic-filter :filterLists="dynamicFilterOptions" @filters-applied="applyDynamicFilter"></dynamic-filter>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tabID">
          <v-tab>
              <span class="primary--text subtitle-1">{{employerStore.fieldLabelSettings.subscriberLabel}} Reports</span>
          </v-tab>
          <v-tab>
              <span class="primary--text subtitle-1">{{employerStore.fieldLabelSettings.subscriberLabel}} Utilization</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" id="reportContainer">
        <v-tabs-items v-model="tabID">
          <v-tab-item :eager="true">
            <div id="itemToExport1">
              <v-row>
                <v-col cols="4" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingMemberActivity">
                    <v-card-title class="d-flex justify-center">
                      Percent Activated
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="pieChartPrimary"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingMemberByProduct">
                    <v-card-title class="d-flex justify-center">
                      Product Enrollment Percentage
                    </v-card-title>
                    <v-card-text>
                      <div class="mt-1" :style="`height:${isDesktop ? '50vh' : '70vh'}`" ref="pieChartPrimary1"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingTierCount">
                    <v-card-title class="d-flex justify-center">
                        Members By Tier
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="pieChartPrimary2"></div>
                    </v-card-text>
                  </v-card>
                </v-col> 
              </v-row> 
              <v-row>
                <v-col cols="6" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingProviders">
                    <v-card-title class="d-flex justify-center">
                      Provider Engagement
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="memberVisitChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingRxChart">
                    <v-card-title class="d-flex justify-center">
                      Rx Utilization
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="memberRxChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-tab-item> 
          <v-tab-item :eager="true">
            <div id="itemToExport2">
              <v-row no-gutters style="margin-top: 40px;">
                <v-spacer></v-spacer>
                <v-col cols="12" md="3">
                  <v-row no-gutters align="center">
                    <v-col cols="12" md="4" justify="center">
                      <label for="reportFilter">Report Filter: </label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-select :items="memberUtilizationReportFilter" outlined v-model="reportFilter" id="reportFilter" dense @change="onMemberUtilizationFilterTypeSelect()" hide-details></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                
                <v-col cols="12" md="8">
                  <v-row no-gutters v-if="showDateRangeFilter">
                    <v-col cols="12" md="6">
                      <v-row no-gutters align="center">
                        <v-col cols="12" md="2" justify="center">
                          <label for="from">From: </label>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-menu v-model="fromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="fromDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined id="from" hide-details></v-text-field>
                            </template>
                            <v-date-picker type="month" v-model="fromDate" @input="fromMenu = false" :max="maxFromDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6">
                      <v-row no-gutters align="center">
                        <v-col cols="12" md="2" justify="center">
                          <label for="to">To: </label>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-menu v-model="toMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="toDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined id="to" hide-details></v-text-field>
                            </template>
                            <v-date-picker type="month" v-model="toDate" @input="toMenu = false" :min="minToDate" :max="maxToDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="employerHasUCProduct ? 6 : 12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingProductUtilization">
                    <v-card-title class="d-flex justify-center">
                      Utilization by Product
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="productUtilizationChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-if="employerHasUCProduct" cols="6" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingVisitDistributionChart">
                    <v-card-title class="d-flex justify-center">
                      Visit Distribution
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="visitDistributionChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingCommonDiagnosisChart">
                    <v-card-title class="d-flex justify-center">
                      Most Common Diagnoses
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="mostCommonDiagnosisChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="page-break">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingAnnualizedTrend">
                    <v-card-title class="d-flex justify-center">
                      Annualized Visit Trend
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="allMemberVisitChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingVisitPerMonth">
                    <v-card-title class="d-flex justify-center">
                      Visits Per Month
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="visitsPerMonthChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingPrescribedMedicationsReport">
                    <v-card-title class="d-flex justify-center">
                      Most Prescribed Medications
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="mostPrescribedMedicationsChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="page-break">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingPrescriptionReport">
                    <v-card-title class="d-flex justify-center">
                      Rx Fill Method
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="rxFillMethodChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingPrescriptionTrend">
                    <v-card-title class="d-flex justify-center">
                      Annualized Prescription Trend
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="prescriptionTrendChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="page-break">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card class="chart23" flat :loading="isLoadingPrescriptionReport">
                    <v-card-title class="d-flex justify-center">
                      Prescriptions Per Month
                    </v-card-title>
                    <v-card-text>
                      <div style="height:50vh" ref="prescriptionPerMonthChart"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-card elevation="0">
                    <v-card-title class="d-flex justify-center">
                      <div class="text-center">
                        <span>Re-direction from Traditional Venues</span>
                        <p class="font-weight-light font-italic caption">
                            Where members reported they would have gone had they not engaged our services
                        </p>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table :items="surveyReportData" :headers="surveyReportHeaders" hide-default-footer
                        :loading="isLoadingMemberSurveyReport" no-data-text="No Member Survey Report found for the Selected Employer" loading-text="Loading... Please wait for the Report">
                        <template v-slot:footer>
                          <span class="font-weight-bold">Total Savings - ${{memberSurveyFooter}}</span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
import { useMemberStore } from "../../stores/member";
import { useEmployerStore } from "../../stores/employer";
import { useReportStore } from "../../stores/report";
import { cleanDate, cleanISOTime, toCleanISODate, getMonthCount1, getShortMonthName, getMaxYearMonth, getMinYearMonth } from "../../commons/date";
import { productTypes } from '../../data/definitions/productTypes';
import { DateTime } from "luxon";
import html2pdf from "html2pdf.js"
import DynamicFilter from "../../components/DynamicFilter.vue";

export default {
  name: "ReportingView",
  components: {
    DynamicFilter
  },
  data: () => ({
    selectedEmployer: localStorage.getItem("currentEmployerID"),
    isDesktop: window.innerWidth > 1440 ? true : false,
    tabID: 0,
    isLoading: false,
    isLoadingMemberList: false,
    isLoadingMemberActivity: false,
    isLoadingMemberByProduct: false,
    isLoadingTierCount: false,
    isLoadingProviders: false,
    isLoadingRxChart: false,
    isLoadingRxFillMethodReport: false,
    isLoadingAnnualizedTrend: false,
    isLoadingVisitPerMonth: false,
    isLoadingPrescriptionTrend: false,
    isLoadingPrescriptionReport: false,
    isLoadingProductUtilization: false,
    isLoadingMemberSurveyReport: false,
    isLoadingPrescribedMedicationsReport: false,
    isLoadingCommonDiagnosisChart: false,
    isLoadingVisitDistributionChart: false,
    memberReporting: [],
    showComplete: false,
    headers: [
      {text: 'Company', value: 'typeName'},
      {text: productTypes.reviveCare, value: 'care'},
      {text: productTypes.reviveValue, value: 'value'},
      {text: productTypes.revivePlus, value: 'plus'},
      {text: productTypes.bright, value: 'bright'},
      {text: productTypes.urgentMeds, value: 'urgent'},
      {text: productTypes.practiCare, value: 'practiCare'},
      {text: productTypes.manifestRx, value: 'manifestRx'},
    ],
    ssHeaders: [
      {text: 'Company Name', value: 'newEmployerName'},
      {text: 'First Name', value: 'firstName'},
      {text: 'Last Name', value: 'lastName'},
      {text: 'Email', value: 'email'},
      {text: 'Signed Up By', value: 'parentEmployerName'},
      {text: 'Email Verified', value: 'emailVerified'},
      {text: 'Completed', value: 'signUpCompleted'},
    ],
    surveyReportHeaders: [
      {text: 'Venue', value: 'venue'},
      {text: 'Re-directions', value: 'redirections'},
      {text: '% Re-directed', value: 'percentRedirected'}
    ],
    surveyReportData: [],
    memberSurveyFooter: null,
    primaryMembers: [],
    brokers: [],
    showCommonDiagnosisReport: false,
    employerHasUCProduct: false,
    memberUtilizationReportFilter:[ 'Last 12 Months', 'Date Range'],
    reportFilter: 'Last 12 Months',
    fromDate: null,
    toDate: null,
    logoUrl: null,
    employerCustomFields: [],
    dynamicFilterOptions: [],
    dynamicFilter: null,
    checkedEmployerHasUrgentCare: false
  }),
  setup(){
    const memberStore = useMemberStore();
    const employerStore = useEmployerStore();
    const reportStore = useReportStore();
    return { memberStore, employerStore, reportStore, cleanDate, cleanISOTime, toCleanISODate };
  }, 
  watch: {
    fromDate(newValue, oldValue) {
      if(oldValue == null || newValue == null || oldValue == newValue) return;
      const from = newValue;
      const to = this.toDate;
      let fromDate = DateTime.fromISO(this.fromDate);
      let toDate = DateTime.fromISO(this.toDate);
      if(toDate <= fromDate)
        this.toDate = DateTime.fromISO(this.minToDate).toFormat('yyyy-MM');
      else if(toDate > fromDate.plus({months: 12}))
        this.toDate = fromDate.plus({months: 12}).toFormat('yyyy-MM');
      if(from)
      setTimeout(() => {
        if (from == this.fromDate && to == this.toDate) {
          this.loadMemberUtilizationReports();
        }
      }, 2000);
    },
    
    toDate(newValue, oldValue) {
      if(oldValue == null || newValue == null || oldValue == newValue) return;
      const from = this.fromDate;
      const to = newValue;
      setTimeout(() => {
        if (from == this.fromDate && to == this.toDate) {
          this.loadMemberUtilizationReports();
        }
      }, 2000);
    },

    selectedEmployer(newVal) {
     if(newVal){
        this.employerStore.getThemeAndLogo(newVal).then(response => {
          if(response.data?.mainLogo){
            this.logoUrl = response.data.mainLogo;
          } 
        });       
     }
    },
    dynamicFilter(newValue, oldValue) {
      if((oldValue == null || Object.keys(oldValue).length === 0) && (newValue == null ||Object.keys(newValue).length === 0)) return;
      this.loadProductUtilization();
      this.loadVisitDistribution();
      this.loadAnnualizedTrend();
      this.loadVisitPerMonth();
      this.loadRxFillMethod();
      this.loadPrescriptionTrend();
      this.loadPrescription();
      this.loadMemberActivity();
      this.loadProductUtilizationByEmployer();
      this.loadTierCountsByEmployeeID();
      this.loadMemberVisitReport();
      this.loadMembersRxReport();
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  computed:{
    employerList: {
      get() { return this.employerStore.employerList; }
    },
    showDateRangeFilter:{
      get(){
        return this.reportFilter == 'Date Range';
      }
    },
    maxFromDate:{
      get(){
        let date = DateTime.local();
        return date.toISODate();
      }
    },
    minToDate:{
      get(){
        if(this.fromDate)
        {
          let date = DateTime.fromISO(this.fromDate);
          return date.toISODate();
        }
        return DateTime.local().toISODate();
      }
    },
    maxToDate:{
      get(){
        const currentDate = DateTime.local();
        let maxDate = currentDate;
        if(this.fromDate)
        {
          let date = DateTime.fromISO(this.fromDate);
          maxDate = date.plus({year: 1, month: 5});
          if(maxDate > currentDate)
            maxDate = currentDate;
        }
        return maxDate.toISODate();
      }
    }
  },
  mounted(){
    this.loadEmployerCustomFields();
    this.onMemberUtilizationFilterTypeSelect();
    this.employerStore.getAllEmployersByActor();

    window.addEventListener("resize", this.myEventHandler);

    this.loadReports();
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  filters: {
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    }
  },
  methods: {
    onSelectedEmployerChange() {
      this.loadEmployerCustomFields();
      this.loadReports();
    },
    loadEmployerCustomFields(){
      this.dynamicFilter = null;
      this.employerCustomFields = [];
      this.dynamicFilterOptions = [];
      this.employerStore.getEmployerCustomFieldsForRegistration(this.selectedEmployer)
      .then((response) => {
        this.employerCustomFields = response.data;
        this.dynamicFilterOptions = this.employerCustomFields.map(x => {
          const field = JSON.parse(x.fieldValue);
          return {
            identifier: field.model,
            name: field.label,
            values: field.options,
            type: field.type
          };
        });
      });
    },
    loadReports(){
        if(this.selectedEmployer){
          this.isLoadingMemberList = true;
          this.loadMemberActivity();
          this.loadProductUtilizationByEmployer();
          this.loadTierCountsByEmployeeID();
          this.loadMemberVisitReport();
          this.loadMembersRxReport();
      }
    },
    loadMemberUtilizationReports() {
      this.loadVisitDistribution();
      this.loadAnnualizedTrend();
      this.loadVisitPerMonth();
      this.loadPrescribedMedications();
      this.loadProductUtilization();
      this.loadCommonDiagnosis();
      this.loadRxFillMethod();
      this.loadPrescriptionTrend();
      this.loadPrescription();
      this.loadMemberSurvey();
    },
    getFilterDates() {
      const fromDateISO = DateTime.fromISO(this.fromDate);
      let toDateISO = DateTime.fromISO(this.toDate);
      const fromDate = fromDateISO.set({day: 1}).toFormat('yyyy-MM-dd');
      const currentDate = DateTime.local();
      toDateISO = toDateISO.set({day: toDateISO.daysInMonth});

      if(toDateISO > currentDate)
        toDateISO = currentDate;

      const toDate = toDateISO.toFormat('yyyy-MM-dd');
      return {fromDate, toDate};
    },
    loadMemberActivity() {
      this.isLoadingMemberActivity = true;
      this.reportStore.getMemberActivityByEmployerID(this.selectedEmployer, this.dynamicFilter).then((result) => {
        this.loadMemberActivityChart(result);
      }).catch((errorMesssage) => {
        this.$refs.pieChartPrimary.innerHTML = errorMesssage;
        this.$refs.pieChartPrimary.style.textAlign = 'center';
      }).finally(() => {
        this.isLoadingMemberActivity = false;
      });
    },
    loadProductUtilizationByEmployer() {
      this.isLoadingMemberByProduct = true;
      this.reportStore.getProductUtilizationByEmployer(this.selectedEmployer, this.dynamicFilter).then((result) => {
        this.loadMemberByProductChart(result);
      }).catch((errorMesssage) => {
        this.$refs.pieChartPrimary1.innerHTML = errorMesssage;
        this.$refs.pieChartPrimary1.style.textAlign = 'center';
      }).finally(() => {
        this.isLoadingMemberByProduct = false;
      });
    },
    loadTierCountsByEmployeeID() {
      this.isLoadingTierCount = true;
      this.reportStore.getTierCountsByEmployeeID(this.selectedEmployer, this.dynamicFilter).then((result) => {
        this.loadMemberByTierChart(result);
      }).catch((errorMesssage) => {
        this.$refs.pieChartPrimary2.innerHTML = errorMesssage;
        this.$refs.pieChartPrimary2.style.textAlign = 'center';
      }).finally(() => {
        this.isLoadingTierCount = false;
      });
    },
    loadMemberVisitReport() {
      this.isLoadingProviders = true;
      this.reportStore.getMemberVisitReport(this.selectedEmployer, this.dynamicFilter).then((result) => {
        if(result.membersWithVisit == 0 && result.membersWithoutVisit == 0){
          this.$refs.memberVisitChart.innerHTML = "No Report Found for the Selected Employer"
          this.$refs.memberVisitChart.style.textAlign = 'center';
          this.isLoadingProviders = false;
        }else{
          this.loadMemberVisitChart(result);
        }
      }).catch((errorMesssage) => {
        this.$refs.memberVisitChart.innerHTML = errorMesssage
        this.$refs.memberVisitChart.style.textAlign = 'center';
        this.isLoadingProviders = false;
      });
    },
    loadMembersRxReport() {
      this.isLoadingRxChart = true;
      this.reportStore.getMembersRxReport(this.selectedEmployer, this.dynamicFilter).then((result) => {
        if(result.membersWithRx == 0 && result.membersWithoutRx == 0){
          this.$refs.memberRxChart.innerHTML = "No Report Found for the Selected Employer"
          this.$refs.memberRxChart.style.textAlign = 'center';

          this.isLoadingRxChart = false;
        }else{
          this.loadMemberRxChart(result);
        }
      }).catch((errorMesssage) => {
        this.$refs.memberRxChart.innerHTML = errorMesssage
        this.$refs.memberRxChart.style.textAlign = 'center';

        this.isLoadingRxChart = false;
      });
    },
    loadVisitDistribution() {
      this.isLoadingVisitDistributionChart = true;
      let promise = Promise.resolve();
      if(!this.checkedEmployerHasUrgentCare){
        promise = this.reportStore.checkEmployerHasUrgentCare(this.selectedEmployer)
        .then((res) => {
          this.employerHasUCProduct = !!res;
          this.checkedEmployerHasUrgentCare = true;
        }).catch(() => {
          this.isLoadingVisitDistributionChart = false;
        });
      }
      promise.then(() => {
        if(this.employerHasUCProduct){
          this.reportStore.getVisitDistributionReport(this.selectedEmployer, this.dynamicFilter).then((visitDist) => { 
            this.loadVisitDistributionChart(visitDist); 
            this.isLoadingVisitDistributionChart = false;
          });
        }
        else{
          this.isLoadingVisitDistributionChart = false;
        }
      });
    },
    loadAnnualizedTrend() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingAnnualizedTrend = true;

      this.reportStore.getAnnualizeVisitReport(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
        this.loadAnnualizedTrendChart(res);
      }).catch((errorMesssage) => {
        this.$refs.allMemberVisitChart.innerHTML = errorMesssage
        this.$refs.allMemberVisitChart.style.textAlign = 'center';

        this.isLoadingAnnualizedTrend = false;
      });
    },
    loadVisitPerMonth() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingVisitPerMonth = true;

      this.reportStore.getReportForVisitsPerMonth(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
        this.loadVisitPerMonthchart(res);
      }).catch((errorMesssage) => {
        this.$refs.visitsPerMonthChart.innerHTML = errorMesssage
        this.$refs.visitsPerMonthChart.style.textAlign = 'center';

        this.isLoadingVisitPerMonth = false;
      });
    },
    loadPrescribedMedications() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingPrescribedMedicationsReport = true;

      this.reportStore.getMostPrescribedMedicationsReport(this.selectedEmployer, fromDate, toDate)
      .then((res) => {        
        this.loadMostPrescribedMedicationsChart(res);
      }).catch((errorMesssage) => {
        this.$refs.mostPrescribedMedicationsChart.innerHTML = errorMesssage;
        this.$refs.mostPrescribedMedicationsChart.style.textAlign = 'center';
        this.isLoadingPrescribedMedicationsReport = false;
      });
    },
    loadProductUtilization() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingProductUtilization = true;
      this.reportStore.getUtilizationByProductReport(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
          this.loadProductUtilizationChart(res);
      }).catch((errorMesssage) => {
        this.$refs.productUtilizationChart.innerHTML = errorMesssage
        this.$refs.productUtilizationChart.style.textAlign = 'center';

        this.isLoadingProductUtilization = false;
      });
    },
    loadCommonDiagnosis() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingCommonDiagnosisChart = true;

      this.reportStore.getMostCommonConditionsReport(this.selectedEmployer, fromDate, toDate).then((res) => {
        this.showCommonDiagnosisReport = true;
        this.loadMostCommonDiagnosisChart(res);
      }).catch((errorMesssage) => {
        this.$refs.mostCommonDiagnosisChart.innerHTML = errorMesssage;
        this.$refs.mostCommonDiagnosisChart.style.textAlign = 'center';

        this.showCommonDiagnosisReport = false;
        this.isLoadingCommonDiagnosisChart = false;
      });
    },
    loadRxFillMethod() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingRxFillMethodReport = true;
      this.isLoadingPrescriptionSavingsReport = true;
      this.reportStore.getRxFillMethodReport(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
        this.prescriptionSavingsReportData = res.reduce((acc, data) => {
          if(acc == data)
            acc = [];
          const prescription = acc.find(x => x.method == data.type);
          if(!prescription){
            acc.push({ method: data.type, prescriptions: data.value1, savings: data.moneySaved });
          }
          else{
            prescription.prescriptions += data.value1;
            prescription.savings += data.moneySaved;
          }
          return acc;
        }, []);
        this.loadRxFillMethodChart(res);
      }).catch((errorMesssage) => {
        this.$refs.rxFillMethodChart.innerHTML = errorMesssage
        this.$refs.rxFillMethodChart.style.textAlign = 'center';
      })
      .finally(() => {
        this.isLoadingRxFillMethodReport = false;
        this.isLoadingPrescriptionSavingsReport = false;
      });
    },
    loadPrescriptionTrend() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingPrescriptionTrend = true;

      this.reportStore.getPrescriptionTrendReport(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
        this.loadPrescriptionTrendChart(res);
      }).catch((errorMesssage) => {
        this.$refs.prescriptionTrendChart.innerHTML = errorMesssage
        this.$refs.prescriptionTrendChart.style.textAlign = 'center';

        this.isLoadingPrescriptionTrend = false;
      });
    },
    loadPrescription() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingPrescriptionReport = true;

      this.reportStore.getPrescriptionsPerMonthReport(this.selectedEmployer, fromDate, toDate, this.dynamicFilter).then((res) => {
        this.loadPrescriptionsPerMonthchart(res);
      }).catch((errorMesssage) => {
        this.$refs.prescriptionPerMonthChart.innerHTML = errorMesssage
        this.$refs.prescriptionPerMonthChart.style.textAlign = 'center';

        this.isLoadingPrescriptionReport = false;
      });
    },
    loadMemberSurvey() {
      const { fromDate, toDate } = this.getFilterDates();
      this.isLoadingMemberSurveyReport = true;
      this.reportStore.getMemberSurveyReport(this.selectedEmployer, fromDate, toDate)
      .then((res) => {
        let total = res.reduce((s, a) => s + a.redirections, 0);
        res.forEach(i => i.percentRedirected = parseFloat(((i.redirections * 100) / total).toPrecision(3)) + '%')
        this.surveyReportData = res;
        let totalWithoutDoneNothingSurvey = res.filter(x => x.venue != "Done nothing").reduce((s, a) => s + a.redirections, 0);
        this.memberSurveyFooter = (totalWithoutDoneNothingSurvey * 367).toLocaleString("en-US");
      })
      .catch(() => {
        this.surveyReportData = [];
        this.memberSurveyFooter = (0).toLocaleString("en-US");
      })
      .finally(() => this.isLoadingMemberSurveyReport = false);
    },
    myEventHandler() {
      this.isDesktop = window.innerWidth > 1440 ? true : false
    },
    exportToPDF(){
      this.isLoading = true;

      let initialTab = this.tabID;
      let newTab = initialTab == 1 ? 0 : 1;

      this.tabID = newTab;

      setTimeout(() => {
        this.tabID = initialTab;
        let divToPrint1 = document.getElementById('itemToExport1');
        let divToPrint2 = document.getElementById('itemToExport2');
  
        const opt = {
          margin: 1,
          filename: 'member-reports.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 2.5 },
          jsPDF: { unit: 'mm', format: this.isDesktop ? 'a2' : 'a3', orientation: 'portrait', putOnlyUsedFonts: true, compress: true },
          pagebreak: { after: '#itemToExport1', before: '.page-break', mode: ['legacy']  }
        }
        
        let container = document.createElement("div");
  
        container.append(divToPrint1.cloneNode(true), divToPrint2.cloneNode(true));
        
        html2pdf().set(opt).from(container)
            .save()
            .then(() => {})
            .then(() => this.isLoading = false);
        
      }, 50);
    },
    loadVisitPerMonthchart(data){
      if (this.chart) { this.chart.dispose(); }
      //get distinct 
      let distinctMonths = [...new Set(data.map(i => i.columnName))]
      let chartData = [];
      if (distinctMonths.length > 0)
      {
        distinctMonths.forEach((x) => {
          let memberCount = data.find(y => y.columnName == x && y.type == 'member');
          let dependentCount = data.find(y => y.columnName == x && y.type == 'dependent');
          chartData.push({
            month: x,
            members: memberCount ? memberCount.value1 : 0,
            dependents: dependentCount ? dependentCount.value1 : 0,
          })
        })
      }
      let chart = am4core.create(this.$refs.visitsPerMonthChart, am4charts.XYChart);
         
      chart.marginRight = 400;
      chart.data = chartData;
      
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.title.text = "";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "";

      function createSeries(field, name) {
  
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "month";
        series.sequencedInterpolation = true;
        
        // Make it stacked
        series.stacked = true;
        
        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
        
        // Add label
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;
        labelBullet.label.fill = am4core.color("#ffffff");
        
        return series;
      }
      createSeries("members", "Members");
      createSeries("dependents", "Dependents");
      // add cursor
      chart.cursor = new am4charts.XYCursor();
      // add legend
      chart.legend = new am4charts.Legend();

      this.isLoadingVisitPerMonth = false;
    },
    loadMemberActivityChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data 

      let activity = data.map(d => {
        return {intValue: d.count, typeName: d.statusName}
      });
      let chart = am4core.create(this.$refs.pieChartPrimary, am4charts.PieChart);
      chart.data = activity;
        

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      
      chart.legend = new am4charts.Legend();
      
      chart.legend.valueLabels.template.text = "{value}";
      chart.legend.valueLabels.template.textAlign = "end"; 

      this.isLoadingMemberList = false;

    },
    loadMemberByProductChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data 
      const activity = data.reduce((acc, {productName, count}) => 
      {
        if(productName){
          acc[productName] ??= {typeName: productName, intValue: count};
        }
        return acc;
      }, {});
      
      let chart = am4core.create(this.$refs.pieChartPrimary1, am4charts.PieChart);
      chart.data = Object.values(activity);

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      
      chart.legend = new am4charts.Legend();
      
      chart.legend.valueLabels.template.text = "{value}";
      chart.legend.valueLabels.template.textAlign = "end"; 
    },
    loadMemberByTierChart(data){
      if (this.chart) { this.chart.dispose(); }
      let activity = data.map(d => {return {intValue: d.count, typeName: d.tierName}});
      let chart = am4core.create(this.$refs.pieChartPrimary2, am4charts.PieChart);
      chart.data = activity;
        

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      
      chart.legend = new am4charts.Legend();
      
      chart.legend.valueLabels.template.text = "{value}";
      chart.legend.valueLabels.template.textAlign = "end";
    },
    loadVisitDistributionChart(visitDist){
      if (this.chart) { this.chart.dispose(); }
      //get the data 
      const holiday =visitDist.filter(x => x.name == 'Weekends/Holidays');
      const afterhours = visitDist.filter(x => x.name == '5PM-9AM');
      const businesshours = visitDist.filter(x => x.name == '9AM-5PM');
      let activity = [
        {intValue: businesshours[0].count, typeName: '9AM-5PM'},
        {intValue: afterhours[0].count, typeName: '5PM-9AM'},
        {intValue: holiday[0].count, typeName: 'Weekends/Holidays'}
      ];
      let chart = am4core.create(this.$refs.visitDistributionChart, am4charts.PieChart);
      chart.data = activity;
        

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      
      chart.legend = new am4charts.Legend();
      

      chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.#')}% ({value})";
      chart.legend.valueLabels.template.textAlign = "end";
    },
    loadMemberVisitChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data 
      let activity = [{intValue: data.membersWithVisit, color: am4core.color("#70ab46"), typeName: `Active households with at least one visit`}, 
                      {intValue: data.membersWithoutVisit, color: am4core.color("#5b9bd5"), typeName: 'None'}];
      let chart = am4core.create(this.$refs.memberVisitChart, am4charts.PieChart);
      chart.data = activity;
      
      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.slices.template.propertyFields.fill = "color";
      series.slices.template.stroke = am4core.color("#fff");
      series.slices.template.strokeWidth = 2;
      series.slices.template.strokeOpacity = 1;
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      chart.legend = new am4charts.Legend();

      this.isLoadingProviders = false;
    },
    loadMemberRxChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data 
      let activity = [{intValue: data.membersWithRx, color: am4core.color("#4472c3"), typeName: `Active households with at least one Rx`}, 
                      {intValue: data.membersWithoutRx, color: am4core.color("#a3a3a3"), typeName: 'None'}];
      let chart = am4core.create(this.$refs.memberRxChart, am4charts.PieChart);
      chart.data = activity;
      
      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.slices.template.propertyFields.fill = "color";
      series.slices.template.stroke = am4core.color("#fff");
      series.slices.template.strokeWidth = 2;
      series.slices.template.strokeOpacity = 1;
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      chart.legend = new am4charts.Legend();

      this.isLoadingRxChart = false;
    },
    loadAnnualizedTrendChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data
      let chart = am4core.create(this.$refs.allMemberVisitChart, am4charts.XYChart);

      const monthCount = getMonthCount1(data);

      monthCount.forEach(x => {
        x.xCategory = `${getShortMonthName(x.month)} ${x.currentYear}`;
      })
      const currentYearDates =  monthCount.map(x => `${x.month} ${x.currentYear}`);
      const previousYearDates =  monthCount.map(x => `${x.month} ${x.previousYear}`);
      const currentYearMinDate = getMinYearMonth(currentYearDates);
      const currentYearMaxDate = getMaxYearMonth(currentYearDates);
      const previousYearMinDate = getMinYearMonth(previousYearDates);
      const previousYearMaxDate = getMaxYearMonth(previousYearDates);

      chart.data = monthCount;
      chart.marginRight = 400;

      /* Create x axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "xCategory";
      categoryAxis.title.text = "Months";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = `visit counts for ${DateTime.now().minus({ year: 1 }).toObject().year} and ${DateTime.now().toObject().year}`;
      
      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.name = `{previousYear}`;
      series1.stroke = am4core.color("#0fc3b2");
      series1.strokeWidth = 3;
      series1.tensionX = 0.7;
      series1.dataFields.valueY = "previousYearData";
      series1.dataFields.categoryX = "xCategory";
      series1.tooltipText = `{month} {previousYear}: [bold]{previousYearData}[/]`;
      series1.legendSettings.labelText = `${previousYearMinDate} - ${previousYearMaxDate}`;

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.name = `{currentYear}`;
      series2.stroke = am4core.color("#ff7a67");
      series2.strokeWidth = 3;
      series2.tensionX = 0.7;
      series2.dataFields.valueY = "currentYearData";
      series2.dataFields.categoryX = "xCategory";
      series2.tooltipText = `{month} {currentYear}: [bold]{currentYearData}[/]`;
      series2.legendSettings.labelText = `${currentYearMinDate} - ${currentYearMaxDate}`;

      if (monthCount.length === 1) {
        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.circle.radius = 5;
        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.radius = 5;
        bullet1.fill = am4core.color("#0fc3b2");
        bullet2.fill = am4core.color("#ff7a67");
      }

      chart.cursor = new am4charts.XYCursor();

      chart.legend = new am4charts.Legend();

      this.isLoadingAnnualizedTrend = false;
    },
    loadPrescriptionTrendChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data
      let chart = am4core.create(this.$refs.prescriptionTrendChart, am4charts.XYChart);

      // chart.data = chartData;
      const monthCount = getMonthCount1(data);

      monthCount.forEach(x => {
        x.xCategory = `${getShortMonthName(x.month)} ${x.currentYear}`;
      })
      const currentYearDates =  monthCount.map(x => `${x.month} ${x.currentYear}`);
      const previousYearDates =  monthCount.map(x => `${x.month} ${x.previousYear}`);
      const currentYearMinDate = getMinYearMonth(currentYearDates);
      const currentYearMaxDate = getMaxYearMonth(currentYearDates);
      const previousYearMinDate = getMinYearMonth(previousYearDates);
      const previousYearMaxDate = getMaxYearMonth(previousYearDates);
      chart.data = monthCount;
      chart.marginRight = 400;

      /* Create x axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "xCategory";
      categoryAxis.title.text = "Months";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = `visit counts for ${DateTime.now().minus({ year: 1 }).toObject().year} and ${DateTime.now().toObject().year}`;

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.name = DateTime.now().minus({ year: 1 }).toObject().year;
      series1.stroke = am4core.color("#0fc3b2");
      series1.strokeWidth = 3;
      series1.tensionX = 0.7;
      series1.dataFields.valueY = "previousYearData";
      series1.dataFields.categoryX = "xCategory";
      series1.tooltipText = `{month} {previousYear}: [bold]{previousYearData}[/]`;
      series1.legendSettings.labelText = `${previousYearMinDate} - ${previousYearMaxDate}`;

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.name = DateTime.now().toObject().year;
      series2.stroke = am4core.color("#ff7a67");
      series2.strokeWidth = 3;
      series2.tensionX = 0.7;
      series2.dataFields.valueY = "currentYearData";
      series2.dataFields.categoryX = "xCategory";
      series2.tooltipText = `{month} {currentYear}: [bold]{currentYearData}[/]`;
      series2.legendSettings.labelText = `${currentYearMinDate} - ${currentYearMaxDate}`;

      if (monthCount.length === 1) {
        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.circle.radius = 5;
        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.radius = 5;
        bullet1.fill = am4core.color("#0fc3b2");
        bullet2.fill = am4core.color("#ff7a67");
      }

      chart.cursor = new am4charts.XYCursor();

      chart.legend = new am4charts.Legend();

      this.isLoadingPrescriptionTrend = false;
    },
    loadPrescriptionsPerMonthchart(data){
      if (this.chart) { this.chart.dispose(); }

      let chart = am4core.create(this.$refs.prescriptionPerMonthChart, am4charts.XYChart);

        let distinctMonths = [...new Set(data.map(i => i.columnName))]
        let chartData = [];
        if (distinctMonths.length > 0)
        {
          distinctMonths.forEach((x) => {
            let memberCount = data.find(y => y.columnName == x && y.type == 'member');
            let dependentCount = data.find(y => y.columnName == x && y.type == 'dependent');
            chartData.push({
              month: x,
              membersCount: memberCount ? memberCount.value1 : 0,
              dependentsCount: dependentCount ? dependentCount.value1 : 0,
            })
          })
        }
      chart.marginRight = 400;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.title.text = "";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "";

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.name = "Members";
      series1.columns.template.fill = am4core.color("#0fc3b2");
      series1.dataFields.valueY = "membersCount";
      series1.dataFields.categoryX = "month";
      series1.tooltipText = `Members Rx in {month}: [bold]{membersCount}[/]`;
      series1.stacked = true;

      let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
      bullet1.label.text = `[bold]{membersCount}[/]`;
      bullet1.locationY = 0.5;
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.label.hideOversized = true;

      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.name = "Dependents";
      series2.columns.template.fill = am4core.color("#ff7a67");
      series2.strokeWidth = 0;
      series2.dataFields.valueY = "dependentsCount";
      series2.dataFields.categoryX = "month";
      series2.tooltipText = `Dependents Rx in {month}: [bold]{dependentsCount}[/]`;
      series2.stacked = true;

      let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
      bullet2.label.text = `[bold]{dependentsCount}[/]`;
      bullet2.locationY = 0.5;
      bullet2.label.fill = am4core.color("#ffffff");
      bullet1.label.hideOversized = true;

      // add cursor
      chart.cursor = new am4charts.XYCursor();
      // add legend
      chart.legend = new am4charts.Legend();

      this.isLoadingPrescriptionReport = false;
    },

    loadProductUtilizationChart(data){
      if (this.chart) { this.chart.dispose(); }
      //get the data 
      let activity = [];
      data.forEach((d) => {
        activity.push({ typeName: d.productName, intValue: d.count })
      });

      let chart = am4core.create(this.$refs.productUtilizationChart, am4charts.PieChart);
      chart.data = activity;
      
      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "intValue";
      series.dataFields.category = "typeName";
      series.slices.template.stroke = am4core.color("#fff");
      series.slices.template.strokeWidth = 2;
      series.slices.template.strokeOpacity = 1;
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.cornerRadius = 10;
      chart.legend = new am4charts.Legend();

      this.isLoadingProductUtilization = false;
    },

    loadMostPrescribedMedicationsChart(data){
      if (this.chart) { this.chart.dispose(); }

      //get the data 
      let activity = [];
      data.forEach((d) => {
        activity.push({ typeName: d.prescriptionName, intValue: d.count })
      });

      let chart = am4core.create(this.$refs.mostPrescribedMedicationsChart, am4charts.XYChart);
      chart.paddingRight = 25;
      chart.data = activity;

      /* Create axes */
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "typeName";
      categoryAxis.renderer.minGridDistance = 2;
      categoryAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

      /* Create series */
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "intValue";
      series.dataFields.categoryY = "typeName";
      series.columns.template.fill = am4core.color("#4471c3");
      series.columns.template.stroke = am4core.color("#fff");
      series.columns.template.strokeWidth = 1;
      series.columns.template.strokeOpacity = 0.5;
      series.columns.template.height = am4core.percent(70);

      let columnTemplate = series.columns.template;
      columnTemplate.strokeOpacity = 0;

      let label = columnTemplate.createChild(am4core.Label);
      label.text = "{intValue}";
      label.align = "center";
      label.valign = "middle";
      label.fill = am4core.color("#ffffff");

      chart.cursor = new am4charts.XYCursor()
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      valueAxis.cursorTooltipEnabled = false;

      this.isLoadingPrescribedMedicationsReport = false;
    },
    loadMostCommonDiagnosisChart(data){
      if (this.chart) { this.chart.dispose(); }

      //get the data 
      let activity = [];
      data.forEach((d) => {
        activity.push({ typeName: d.conditionName, intValue: d.count })
      });

      let chart = am4core.create(this.$refs.mostCommonDiagnosisChart, am4charts.XYChart);
      chart.paddingRight = 25;
      chart.data = activity;

      /* Create axes */
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "typeName";
      categoryAxis.renderer.minGridDistance = 2;
      categoryAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      
      categoryAxis.renderer.minGridDistance = 5;

      /* Create series */
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "intValue";
      series.dataFields.categoryY = "typeName";
      series.columns.template.fill = am4core.color("#4471c3");
      series.columns.template.stroke = am4core.color("#fff");
      series.columns.template.strokeWidth = 1;
      series.columns.template.strokeOpacity = 0.5;
      series.columns.template.height = am4core.percent(70);

      let columnTemplate = series.columns.template;
      columnTemplate.strokeOpacity = 0;

      let label = columnTemplate.createChild(am4core.Label);
      label.text = "{intValue}";
      label.align = "center";
      label.valign = "middle";
      label.fill = am4core.color("#ffffff");

      chart.cursor = new am4charts.XYCursor()
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      valueAxis.cursorTooltipEnabled = false;

      this.isLoadingCommonDiagnosisChart = false;
    },
    loadRxFillMethodChart(data){
      if (this.chart) { this.chart.dispose(); }

      let chart = am4core.create(this.$refs.rxFillMethodChart, am4charts.XYChart);

        let distinctMonths = [...new Set(data.map(i => i.columnName))]
        let chartData = [];
        if (distinctMonths.length > 0)
        {
          distinctMonths.forEach((x) => {
            let retailCount = data.find(y => y.columnName == x && y.type == 'retail');
            let mailOrderCount = data.find(y => y.columnName == x && y.type == 'mail order');
            chartData.push({
              month: x,
              retailCount: retailCount ? retailCount.value1 : 0,
              mailOrderCount: mailOrderCount ? mailOrderCount.value1 : 0,
            })
          })
        }
      chart.marginRight = 400;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.title.text = "";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "";

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.name = "Retail Pharmacies (Procare)";
      series1.columns.template.fill = am4core.color("#0fc3b2");
      series1.dataFields.valueY = "retailCount";
      series1.dataFields.categoryX = "month";
      series1.tooltipText = `Retail Pharmacies in {month}: [bold]{retailCount}[/]`;
      series1.stacked = true;

      let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
      bullet1.label.text = `[bold]{retailCount}[/]`;
      bullet1.locationY = 0.5;
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.label.hideOversized = true;

      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.name = "Mail Order (Manifest)";
      series2.columns.template.fill = am4core.color("#ff7a67");
      series2.strokeWidth = 0;
      series2.dataFields.valueY = "mailOrderCount";
      series2.dataFields.categoryX = "month";
      series2.tooltipText = `Mail Order in {month}: [bold]{mailOrderCount}[/]`;
      series2.stacked = true;

      let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
      bullet2.label.text = `[bold]{mailOrderCount}[/]`;
      bullet2.locationY = 0.5;
      bullet2.label.fill = am4core.color("#ffffff");
      bullet1.label.hideOversized = true;

      // add cursor
      chart.cursor = new am4charts.XYCursor();
      // add legend
      chart.legend = new am4charts.Legend();
    },
    onMemberUtilizationFilterTypeSelect(){
      if(this.reportFilter == 'Last 12 Months') {
        const currentDate = new Date();
        const twelveMonthsAgo = new Date(currentDate);
        twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
        this.fromDate = twelveMonthsAgo.toISOString().substring(0, 7);
        this.toDate = currentDate.toISOString().substring(0, 7);
      }
    },
    applyDynamicFilter(filters) {
      this.dynamicFilter = filters;
    },

  }
};
</script>
<style>
.chart23{
  width: 100%;
  min-height: 500px;
}
#reportContainer{
  background-color: white !important;
}
.hidden {
   position: absolute !important;
   top: -9999px !important;
   left: -9999px !important;
}
</style>