<template>
<div @employerSettings="employerSettings">
    <h3 class="font-weight-bold primary--text mb-5 mt-3">{{employerStore.fieldLabelSettings.groupLabel}} Settings:</h3>
    <v-form v-model="employerSettings.valid" ref="formSettings">
        <v-expansion-panels v-model.trim="settingsExpansionPanel">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="font-weight-bold primary--text">Billing Settings</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <v-row>
                        <v-col md="6">
                            <v-select v-model.trim="employerSettings.billing.enrolledInTheGroup" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Can Enroll Members"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="6">
                            <v-select v-model.trim="employerSettings.billing.billingMethod" :items="billingOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Billing Method"></v-select>
                        </v-col> 
                         <v-col md="6" v-if="isAdmin">
                            <v-select v-model.trim="employerSettings.billing.invoiceCollection" :items="collectionOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Invoice Collection"></v-select>
                        </v-col>
                        <v-col md="6" v-if="(employerSettings.billing.invoiceCollection == 'remittance' && isAdmin)">
                            <!-- <v-text-field v-model.trim="employerSettings.billing.netTerm" type="number" min="0" label="Net Term" hint="the default value of 15 can be bypassed" :rules="[(v) => !!v || 'Net Term is required']" persistent-hint clearable v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }" placeholder="The number of days before a renewal invoice is due. A value between 0 (due immediately) and 180"></v-text-field> -->
                            <v-text-field
                                v-model.trim="employerSettings.billing.netTerm"
                                :append-icon="marker ? 'mdi-pencil' : 'mdi-close-circle'"
                                type="number"
                                :readonly="marker"
                                @click:append="marker = !marker"
                                :min="0"
                                :max="180"
                                label="Net Term"
                                :rules="[(v) => !!v || 'Net Term is required']"
                                v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }" 
                                placeholder="The number of days before a renewal invoice is due. A value between 0 (due immediately) and 180"
                            ></v-text-field>
                        </v-col>

                        <v-col md="6" v-if="(employerSettings.billing.invoiceCollection == 'remittance' && isAdmin)">
                            <v-text-field v-model.trim="employerSettings.user.first_name" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'First Name is required']" label="Bill to First Name" required></v-text-field>
                        </v-col>
                        <v-col md="6" v-if="(employerSettings.billing.invoiceCollection == 'remittance' && isAdmin)">
                            <v-text-field v-model.trim="employerSettings.user.last_name" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" :rules="[(v) => !!v || 'Last Name is required']" label="Bill To Last Name" required></v-text-field>
                        </v-col>
                        <v-col md="6" v-if="(employerSettings.billing.invoiceCollection == 'remittance' && isAdmin)">
                            <v-text-field v-model.trim="employerSettings.user.email" :rules="[ (v) => !!v || 'E-mail is required', (v) => /^\w+([.-]?\w+)*@[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]{1,})+$/.test(v) || 'E-mail must be valid' ]" label="Bill To Email" required></v-text-field>
                        </v-col>
                    </v-row>
                    <h3 v-if="isAdmin" class="mt-4 font-weight-bold primary--text">Discount</h3>
                    <v-row v-if="isAdmin" > 
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.discount.discountPercentage" v-maska="{ mask: ['##','##.####'] }" label="Percentage" placeholder="Percentage" oninput="if(this.value < 0) {this.value = 0} else if (this.value > 100) {this.value = 0};" prepend-inner-icon="mdi-percent-outline"></v-text-field>
                        </v-col>
                        <!-- <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.discount.numberOfMonths" label="Number of Months" placeholder="Number of Months" type="number" min="0"></v-text-field>
                        </v-col> -->
                        <v-col md="6">
                            <v-menu v-model.trim="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y nudge-top="400" max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model.trim="computedEndDateFormatted" :append-icon="employerSettings.discount.numberOfMonths ? 'clear' : '' " prepend-inner-icon="event"
                                    @click:append="employerSettings.discount.numberOfMonths = null" label="Number of Months" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model.trim="employerSettings.discount.numberOfMonths" no-title @input="endDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row v-if="isAdmin" > 
                        <h3 class="mt-4 font-weight-bold primary--text">Pricing Structure</h3>
                        <employer-pricing @employerPriceUpdate="handlePriceUpdate" :isSave="isSubmitted" :isAdmin="isAdmin" :allEmployerProducts="empProducts" 
                                    :fromParent="getIsFromParent" :employerID="employerID" :emCustomerType="empCustomerType" :newEmployer="isNewEmployer" :parentEmployerID="parentEmpID" >
                        </employer-pricing>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="font-weight-bold primary--text">
                        {{employerStore.fieldLabelSettings.groupLabel}} Settings
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <v-row>
                        <v-col md="4" v-if="isAdmin">
                            <v-select v-model.trim="employerSettings.system.allowSelfEnrollment" :items="trueOrFalse" item-text="text" item-value="value" label="Allow Self-Enrollment"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-text-field v-model.trim="employerSettings.system.enrollmentCode" readonly label="Enrollment Code">
                                <template v-slot:append-outer v-if="!employerSettings.system.enrollmentCode">
                                    <v-btn style="top: -5px" @click="generateCode">Generate Code</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.system.groupCreation" :disabled="!employerStore.systemSettings.groupCreation && !isAdmin" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" :label="'Enable '+employerStore.fieldLabelSettings.groupLabel+' Creation'"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.system.welcomeMail" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Send Welcome Mail/Kit"></v-select>
                        </v-col>
                        <v-col v-if="employerSettings.system.welcomeMail" md="4">
                            <v-text-field v-model.trim="employerSettings.system.reminderTiming" type="number" min="0" max="31" label="Reminder Timing:" placeholder="Reminder Timing" clearable v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }"></v-text-field>
                        </v-col>
                        <v-col md="4" v-if="employerSettings.system.welcomeMail">
                            <v-text-field v-model.trim="employerSettings.member.englishEmailTemplate"  label="English Email Template:" placeholder="Email Template"></v-text-field>
                        </v-col>
                        <v-col md="4" v-if="employerSettings.system.welcomeMail">
                            <v-text-field v-model.trim="employerSettings.member.spanishEmailTemplate"  label="Spanish Email Template:" placeholder="Email Template"></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.profileEdit" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Allow Profile Edits"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.displayDependent" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" :label="'Display ' + employerStore.fieldLabelSettings.dependentLabel"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.system.sendWelcomeSMS" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Send Welcome SMS"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.dependentAddOrEdit" :items="dependentAddOrEditOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" :label="'Allow '+ employerStore.fieldLabelSettings.dependentLabel +' Add/Edits'" placeholder="Choose a setting..."></v-select>
                        </v-col>
                        <v-col md="4" v-if="isAdmin">
                            <v-select v-model.trim="employerSettings.system.allowMinorsAsPrimary" :items="minorsAsPrimariesOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Allow Minors As Primaries"></v-select>
                        </v-col>
                        <v-col md="4" v-if="isAdmin && employerSettings.system.allowMinorsAsPrimary">
                            <v-text-field v-model.trim="employerSettings.system.minimumPrimaryAge" type="number"
                            :rules="minAgeRules"
                            label="Minimum Primary Age" placeholder="Minimum Primary Age"></v-text-field>
                        </v-col>
                        <v-col md="4" v-if="isAdmin && employerSettings.system.allowMinorsAsPrimary">
                            <v-text-field v-model.trim="employerSettings.system.minimumEmailAge" type="number"
                            :rules="[rules.minimumEmailAgeRule(employerSettings.system.minimumEmailAge, employerSettings.system.minimumPrimaryAge)]"
                            label="Minimum Primary Email Age" placeholder="Minimum Primary Email Age"></v-text-field>
                        </v-col>

                             <!-- <v-radio-group
            v-model="newDependent.dependentAddOrEdit"
            mandatory
            id="dependentAddOrEdit-text"
          >
              <v-radio
                label="Add and edit"
                :value="2"
                color="info"
                class="my-0 mx-2"
              ></v-radio>
              <v-radio
                label="Edit only"
                :value="1"
                color="info"
                class="my-0 mx-2"
              ></v-radio>
              <v-radio 
                label="None"
                :value="0"
                color="info"
                class="my-0 mx-2"
                ></v-radio>
              </v-radio-group> -->
                       
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>
                <span class="font-weight-bold primary--text">
                    {{employerStore.fieldLabelSettings.subscriberLabel}} Settings
                </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <v-row>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.directLogins" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Enable Direct Logins"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.startDate" :items="startDateItems" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Default Start Date:"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.endDate" :items="endDateItems" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Default End Date:"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.uploadType" :items="types" :item-text="(item) => item.text" :item-value="(item) => item.value" label="File Selection Type"></v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select v-model.trim="employerSettings.member.deleteOnFullImport" :items="ftpDependentDeleteTypes" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Delete Dependents on Full File Import"></v-select>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="font-weight-bold primary--text">
                        Field Labels
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <!-- group/employer label -->
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.groupLabel" :label="employerStore.fieldLabelSettings.groupLabel" :placeholder="employerStore.fieldLabelSettings.groupLabel" clearable></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.secondaryGroupLabel" :label="'Plural of ' + employerStore.fieldLabelSettings.groupLabel" :placeholder="employerStore.fieldLabelSettings.secondaryGroupLabel" clearable></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- subscriber/member label -->
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.subscriberLabel" :label="employerStore.fieldLabelSettings.subscriberLabel" :placeholder="employerStore.fieldLabelSettings.subscriberLabel" clearable></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.secondarySubscriberLabel" :label="'Plural of ' + employerStore.fieldLabelSettings.subscriberLabel" :placeholder="employerStore.fieldLabelSettings.secondarySubscriberLabel" clearable></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- product label -->
                    <v-row>
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.serviceLabel" :label="employerStore.fieldLabelSettings.serviceLabel" :placeholder="employerStore.fieldLabelSettings.serviceLabel" clearable></v-text-field>
                        </v-col>
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.secondaryServiceLabel" :label="'Plural of ' + employerStore.fieldLabelSettings.serviceLabel" :placeholder="employerStore.fieldLabelSettings.secondaryServiceLabel" clearable></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- dependent label -->
                    <v-row>
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.dependentLabel" :label="employerStore.fieldLabelSettings.dependentLabel" :placeholder="employerStore.fieldLabelSettings.dependentLabel" clearable></v-text-field>
                        </v-col>
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.secondaryDependentLabel" :label="'Plural of ' + employerStore.fieldLabelSettings.dependentLabel" :placeholder="employerStore.fieldLabelSettings.secondaryDependentLabel" clearable></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="6">
                            <v-text-field v-model.trim="employerSettings.fieldLabel.ProgramNameLabel" :label="employerStore.fieldLabelSettings.ProgramNameLabel" :placeholder="employerStore.fieldLabelSettings.ProgramNameLabel" clearable></v-text-field>
                        </v-col>
                    </v-row>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="font-weight-bold primary--text">
                        Customization Settings
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <div>
                        <v-row>
                            <v-col md="6" cols="12">
                                <v-select v-model.trim="employerSettings.customization.enableCustomization" :items="options" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Customization Enabled"></v-select>
                            </v-col>
                            <v-col md="6" cols="12">
                                <v-select v-model.trim="employerSettings.customization.brandingType" :items="brandingOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Branding Type"></v-select>
                            </v-col>
                            <v-col md="4" cols="12">
                                <label for="phone" style="font-size:12px; color:gray">Phone Number</label>
                                <vue-tel-input id="phone" v-model.trim="employerSettings.customization.phoneNumber" @input="onPhoneObject" :disabled="!isAdmin" />
                                <div v-if="employerSettings.customization.phoneNumber">
                                    <small v-if="!employerSettings.phoneObject.valid" class="error--text">Phone number is not valid</small>
                                </div>
                            </v-col>
                            <v-col :md="isAdminDependentCol" cols="12">
                                <v-file-input show-size label="Main Logo" accept=".png, .jpg" prepend-icon="mdi-camera" :rules="logoRules" truncate-length="50" @change="previewLogoImage" v-model.trim="employerSettings.customization.mainLogo"></v-file-input>
                            </v-col>
                            <v-col :md="isAdminDependentCol" cols="12">
                                <v-file-input show-size label="Mobile Logo" accept="image/png, image/jpeg" prepend-icon="mdi-camera" :rules="mobileLogoRules" truncate-length="50" @change="previewMobileLogoImage" v-model.trim="employerSettings.customization.mobileLogo"></v-file-input>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-img :src="logoUrl" width="150px"></v-img>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-img :src="mobileLogoUrl" width="150px"></v-img>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <v-label>Member Portal Header Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.headerColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.headerColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.headerColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Member Portal Header Text Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.headerTextColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.headerTextColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.headerTextColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Member Portal Footer Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.footerColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.footerColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.footerColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Member Portal Footer Text Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.footerTextColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.footerTextColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.footerTextColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Member Portal Primary Action Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.primaryActionColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.primaryActionColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.primaryActionColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Member Portal Secondary Action Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.secondaryActionColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.secondaryActionColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.secondaryActionColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Primary Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.primaryColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.primaryColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.primaryColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Secondary Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.secondaryColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.secondaryColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.secondaryColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Secondary Text Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.secondaryTextColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.secondaryTextColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.secondaryTextColor"></v-color-picker>
                    </div>
                    <div>
                        <v-label>Background Color:</v-label>
                        <v-tooltip v-if="employerSettings.customization.backgroundColor" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="employerSettings.customization.backgroundColor = null" class="ma-2">format_color_reset</v-icon>
                            </template>
                            <span>Remove Color Choice</span>
                        </v-tooltip>
                        <v-color-picker class="ma-2" v-model.trim="employerSettings.customization.backgroundColor"></v-color-picker>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-form>
</div>
</template>

<script>
import { cleanDate } from "../../commons/date";
import { useEmployerStore } from "../../stores/employer";
import { randomString } from "../../commons/user";

export default {
    name: "EmployerSettings",
    emits: ["change"],
    props: ['isSubmitted', 'memberObj', 'systemObj', 'fieldLabelObj', 'customizationObj', 'billingObj', 'discount', 'isAdmin', 
            'parentAttributes', 'userDetails', 'employerProducts', 'employerID', 'customerType', 'isNewEmployer', 'parentEmpID'],
    components: {
        EmployerPricing: () => import('./EmployerPricing.vue')
    },
    setup() {
        const employerStore = useEmployerStore();

        return { employerStore }
    },
    data() {
        return {
            logoRules: [
                (v) => (typeof v === 'object' && !Array.isArray(v) && v !== null ? v?.name?.length < 100 : true) || "Main Logo File Name must be less than 100 characters",
                (v) => (typeof v === 'object' && !Array.isArray(v) && v !== null ? /^[a-zA-Z0-9-_]*\.[a-zA-Z]*$/.test(v.name) : true) || "Main Logo File Name cannot include special characters except hyphen, underscore, and one period",
            ],
            mobileLogoRules: [
                (v) => (typeof v === 'object' && !Array.isArray(v) && v !== null ? v?.name?.length < 100 : true) || "Mobile Logo File Name must be less than 100 characters",
                (v) => (typeof v === 'object' && !Array.isArray(v) && v !== null ? /^[a-zA-Z0-9-_]*\.[a-zA-Z]*$/.test(v.name) : true) || "Mobile Logo File Name cannot include special characters except hyphen, underscore, and one period",
            ],
            minAgeRules:[
                (v) => !!v || 'Min age is required',
                (v) => (v != null && v >= 2 && v < 18) || 'Min age should be greater than 2 and less than 18',
            ],
            minEmailAgeRules:[
                (v) => (v == null || v == '' || ((v > 12 && v < 18) && (this.employerSettings.system.minimumPrimaryAge != null && this.employerSettings.system.minimumPrimaryAge != '' && v >= this.employerSettings.system.minimumPrimaryAge)  )) 
                    || `Minimum Email age should be greater than 12 & the minimum minor primary member age, and less than 18`,
            ],
            rules: {
                minimumEmailAgeRule(val, minPrimaryAge){
                    if(!val || val == null || val == '')
                        return true;
                    else if(minPrimaryAge != null && minPrimaryAge != '' && parseInt(val) >= parseInt(minPrimaryAge) && (val > 12 && val < 18))
                        return true;
                    return "Minimum primary email age should be greater than 12 and less than 18. Minimum primary email age must be greater than the minimum primary age. Members under 12 years old cannot be sent emails due to Compliance reasons.";
                }
            },
            startDateItems: [
                { text: "Not Set", value: null },
                { text: "First day of Next Month", value: "firstDay" },
                { text: "Current Day", value: "currentDay" }
            ],
            endDateItems: [
                { text: "Not Set", value: null },
                { text: "Last day of Month", value: "lastDay" },
                { text: "Current Day", value: "currentDay" }
            ],
            types: [
                { text: "Not Set", value: null },
                { text: "Full", value: "full" },
                { text: "Edits Only", value: "partial" },
                { text: "Both", value: "both" },
            ],
            options: [
                { text: "Not Set", value: null },
                { text: "Yes", value: true },
                { text: "No", value: false },
            ],
            dependentAddOrEditOptions:[
                {text: "Add and Edit", value: 2},
                {text: "Edit Only", value: 1},
                {text: "No Permissions", value: 0},
            ],
            minorsAsPrimariesOptions:[
                {text: "No", value: 0},
                {text: "Yes", value: 1}
            ],
            trueOrFalse: [
                { text: "Yes", value: true },
                { text: "No", value: false },
            ],
            billingOptions: [
                { text: "Not Set", value: null },
                { text: "Individual Billing", value: "individualBilling" },
                { text: "Aggregate Billing", value: "aggregateBilling" },
            ],
            collectionOptions: [
                { text: "Not Set", value: null },
                { text: "Automatic", value: "automatic" },
                { text: "Remit", value: "remittance" },
            ],
            brandingOptions: [
                { text: "Revive", value: null },
                { text: "Co-Branding", value: 1 },
                { text: "White Label", value: 2 },
            ],
            ftpDependentDeleteTypes: [
                { text: "Do Not Terminate Omitted Dependents", value: "1" },
                { text: "Terminate Omitted Dependents", value: "2" },
                { text: "Terminate Omitted Primaries", value: "3" },
                { text: "Terminate All Member Types Omitted", value: "4" }
            ],
            endDateMenu: false,
            settingsExpansionPanel: 0,
            employerSettings: {
                valid: true,
                discount: {
                    discountPercentage: null,
                    numberOfMonths:null
                },
                system: {
                    allowSelfEnrollment: false,
                    enrollmentCode: null,
                    groupCreation: null,
                    welcomeMail: false,
                    reminderTiming: 15,
                    allowMinorsAsPrimary: null,
                    minimumPrimaryAge: null,
                    minimumEmailAge: null,
                    sendWelcomeSMS: null
                },
                member: {
                    profileEdit: null,
                    displayDependent: true,
                    dependentAddOrEdit: 2,
                    directLogins: null,
                    endDate: null,
                    startDate: null,
                    uploadType: null,
                    deleteOnFullImport: null,
                    englishEmailTemplate: null,
                    spanishEmailTemplate: null
                },
                fieldLabel: {
                    groupLabel: null,
                    secondaryGroupLabel: null,
                    serviceLabel: null,
                    secondaryServiceLabel: null,
                    subscriberLabel: null,
                    secondarySubscriberLabel: null,
                    dependentLabel: null,
                    secondaryDependentLabel: null,
                    ProgramNameLabel: null,
                },
                customization: {
                    mainLogo: null,
                    mobileLogo: null,
                    phoneNumber: null,
                    enableCustomization: null,
                    primaryColor: null,
                    secondaryColor: null,
                    secondaryTextColor: null,
                    backgroundColor: null,
                    brandingType: null,
                    headerColor: null,
                    headerTextColor: null,
                    footerColor: null,
                    footerTextColor: null,
                    primaryActionColor: null,
                    secondaryActionColor: null,
                },
                billing: {
                    billingMethod: null,
                    enrolledInTheGroup: null, 
                    invoiceCollection: null,
                    netTerm: 15
                },
                user: {
                    first_name: null,
                    last_name: null,
                    email: null
                },
                oldMainLogo: null,
                oldMobileLogo: null,
                // parentEmployerAttribute: {
                //     billingMethod: null,
                //     enrolledInTheGroup: null, 
                //     invoiceCollection: null,
                //     netTerm: 0
                // },
                phoneObject: {
                    valid: false,
                },
            },
            logoUrl: "",
            mobileLogoUrl: "",
            marker: true,
            toggleEdit: false,
            isSave: false,
            isPricingFromParent: true,
            empProducts: [],
            empCustomerType: ''
        };
    },
    computed: {
        getIsFromParent(){
            if(this.isNewEmployer && this.isPricingFromParent)
                return true;
            return this.isPricingFromParent;
        },
        computedEndDateFormatted: function () {
            return this.employerSettings.discount.numberOfMonths ? cleanDate(this.employerSettings.discount.numberOfMonths) : null;
        },
        isAdminDependentCol: function() {
            return this.isAdmin ? 4 : 6;
        }
    },
    methods: {
        onPhoneObject(formattedNumber, phoneObj) {
            this.employerSettings.phoneObject = phoneObj;
        },
        previewLogoImage() {
            this.logoUrl = this.employerSettings.customization.mainLogo ? URL.createObjectURL(this.employerSettings.customization.mainLogo) : '';
        },
        previewMobileLogoImage() {
            this.mobileLogoUrl = this.employerSettings.customization.mobileLogo ? URL.createObjectURL(this.employerSettings.customization.mobileLogo) : '';
        },
        resetIcon () {
            this.iconIndex = 0
        },
        changeIcon () {
            this.iconIndex === this.icons.length - 1
            ? this.iconIndex = 0
            : this.iconIndex++
        },
        showErrorForInvoiceType(){
            return this.$swal({
                title: "Error",
                text: "The parent group is set for Aggregate bill. You must either set the Billing Method for this group to Individual or Aggregate Billing or set the Invoice Collection setting as Not Set.",
                icon: "error",
                confirmButtonText: "Try Again",
                confirmButtonColor: this.$vuetify.theme.themes.light.success
            }).then(() => {
                this.employerSettings.billing.invoiceCollection = null;
                return;
            });
        },
        generateCode(){
            if(!this.employerSettings?.system?.enrollmentCode){
                this.employerSettings.system.enrollmentCode = randomString(10);

                this.$swal({
                    title: "Success",
                    text: "The Enrollment code has been successfully generated. Kindly click on the Save button below to have the code stored to the database.",
                    icon: "success",
                    confirmButtonText: "Ok",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success
                })
            }else{

                this.$swal({
                    title: "Error",
                    text: "You cannot create the Enrollment code twice!",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success
                })
            }
        },
        handlePriceUpdate(pricePayload){
            this.employerSettings.billing.pricing = pricePayload;
            this.$emit('employerSettings', this.employerSettings);
        }
    },
    watch: {
        employerProducts(products){
            this.empProducts = products;
        },
        customerType(type){
            this.empCustomerType = type;
        },
        isSubmitted(val) {
            if(!this.$refs.formSettings.validate()){
                this.employerSettings.validationMessages = this.$refs.formSettings._data.inputs;
            }

            if(val){
                if(!this.isAdmin){
                    this.employerSettings.billing.pricing = null;
                    this.$emit('employerSettings', this.employerSettings);
                }
            }
        },
        memberObj(member) {
            if(member) {
                this.employerSettings.member = JSON.parse(member.attributeValue);
            }
        },
        billingObj(billing) {
            if(billing) {
                this.employerSettings.billing = JSON.parse(billing.attributeValue);

                if(typeof this.employerSettings.billing.netTerm == 'undefined' || !this.employerSettings.billing.netTerm){
                    this.employerSettings.billing.netTerm = 15
                }
                if(typeof this.employerSettings.billing?.billingMethod == 'undefined' || this.employerSettings.billing?.billingMethod == null){
                    this.isPricingFromParent = true;
                }
                else{
                    this.isPricingFromParent = false;
                }
            }
        },
        systemObj(system) {
            if(system) {
                this.employerSettings.system = JSON.parse(system.attributeValue);
                if(typeof this.employerSettings.system.reminderTiming == 'undefined' || this.employerSettings.system.reminderTiming == null){
                    this.employerSettings.system.reminderTiming = 15
                }
                if(typeof this.employerSettings.system.welcomeMail == 'undefined' || this.employerSettings.system.welcomeMail == null){
                    this.employerSettings.system.welcomeMail = false
                }
                if(!this.employerSettings.system.welcomeMail && this.employerSettings.system.reminderTiming == 0){
                    this.employerSettings.system.reminderTiming = 15;
                }
            }
        },
        fieldLabelObj(field) {
            if(field) {
                this.employerSettings.fieldLabel = JSON.parse(field.attributeValue);
            }
        },
        userDetails(user) {
            if(user) {
                this.employerSettings.user = user.subscription.customer
            }
        },
        discount(discountDetails) {
            if(discountDetails) {
                this.employerSettings.discount = discountDetails;
            }
        },
        customizationObj(customization) {
            if(customization) {
                let parseCustomization = JSON.parse(customization.attributeValue);
                
                this.employerSettings.customization.enableCustomization = parseCustomization.enableCustomization;
                this.employerSettings.customization.primaryColor = parseCustomization.primaryColor;
                this.employerSettings.customization.secondaryColor = parseCustomization.secondaryColor;
                this.employerSettings.customization.secondaryTextColor = parseCustomization.secondaryTextColor;
                this.employerSettings.customization.backgroundColor = parseCustomization.backgroundColor;
                this.employerSettings.customization.brandingType = parseCustomization.brandingType;
                this.employerSettings.customization.phoneNumber = parseCustomization.phoneNumber;
                this.employerSettings.customization.headerColor = parseCustomization.headerColor;
                this.employerSettings.customization.headerTextColor = parseCustomization.headerTextColor;           
                this.employerSettings.customization.footerColor = parseCustomization.footerColor;
                this.employerSettings.customization.footerTextColor = parseCustomization.footerTextColor;
                this.employerSettings.customization.primaryActionColor = parseCustomization.primaryActionColor;
                this.employerSettings.customization.secondaryActionColor = parseCustomization.secondaryActionColor;

                if(parseCustomization.mainLogo){
                    this.employerSettings.oldMainLogo = parseCustomization.mainLogo;
                    this.logoUrl = "/uploads" +parseCustomization.mainLogo.split("Uploads")[1];
                }
                if(parseCustomization.mobileLogo){
                    this.employerSettings.oldMobileLogo = parseCustomization.mobileLogo;
                    this.mobileLogoUrl = "/uploads" +parseCustomization.mobileLogo.split("Uploads")[1];
                }
            }
        }
    },
    created() {
        this.employerSettings.customization.headerColor = this.employerSettings.customization.headerColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.headerTextColor = this.employerSettings.customization.headerTextColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.secondaryTextColor = this.employerSettings.customization.secondaryTextColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.footerColor = this.employerSettings.customization.footerColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.footerTextColor = this.employerSettings.customization.footerTextColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.primaryActionColor = this.employerSettings.customization.primaryActionColor ?? {"alpha":1,"hex":"#223047","hexa":"#223047FF","hsla":{"h":217.2972972972973,"s":0.3523809523809523,"l":0.2058823529411765,"a":1},"hsva":{"H":217.2972972972973,"S":0.5211267605633803,"V":0.2784313725490196,"A":1},"rgba":{"r":34,"g":48,"b":71,"a":1},"hue":217.2972972972973};
        this.employerSettings.customization.secondaryActionColor = this.employerSettings.customization.secondaryActionColor ?? {"alpha":1,"hex":"#FFC600","hexa":"#FFC600FF","hsla":{"h":46.588235294117645,"s":1,"l":0.5,"a":1},"hsva":{"H":46.588235294117645,"S":1,"V":1,"A":1},"rgba":{"r":255,"g":198,"b":0,"a":1},"hue":46.588235294117645};
        
        this.$watch("employerSettings.billing.invoiceCollection", (invoiceType) => {
            if(invoiceType && (this.parentAttributes && this.parentAttributes.billingMethod == 'aggregateBilling' && (!this.employerSettings.billing.billingMethod || this.employerSettings.billing.billingMethod == 'aggregateBilling'))){
                this.showErrorForInvoiceType();
            }

            if(invoiceType == 'automatic'){
                this.employerSettings.billing.netTerm = 0;
            }
            if(invoiceType == 'remittance' && (typeof this.employerSettings.billing.netTerm == 'undefined' || !this.employerSettings.billing.netTerm || this.employerSettings.billing.netTerm == 0)){
                this.employerSettings.billing.netTerm = 15;
            }
        });
        this.$watch("employerSettings.billing.billingMethod", (billingMethod) => {
            if(typeof billingMethod == 'undefined' || billingMethod == null){
                this.isPricingFromParent = true;
            }
            else{
                this.isPricingFromParent = false;
            }
            
            if((!billingMethod || billingMethod == 'aggregateBilling') && this.parentAttributes && this.parentAttributes.billingMethod == 'aggregateBilling' && this.employerSettings.billing.invoiceCollection){
                this.showErrorForInvoiceType();
            }
        });

        if(window.location.pathname.includes('/group-admin/new')){
            this.employerSettings.system.enrollmentCode = randomString(10);
        }
    }
};
</script>

<style scoped>

.price-container {
  .col-2 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .theme--light.v-input {
    padding-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}

</style>