<template>
  <v-navigation-drawer v-model.trim="drawer" width="350" :mini-variant.sync="mini" class="navColor--text navBackground" permanent app>
    
    <v-list-item class="px-2 mt-10">
      <v-list-item-avatar>
        <v-img :src="user.picture"></v-img>
      </v-list-item-avatar>

      <v-list-item-content v-if="user">
        <v-list-item-title class="navColor--text">{{ user.name }}</v-list-item-title>
        <br />
        <v-list-item-subtitle class="navColor--text">{{ user.email }}</v-list-item-subtitle>
        <br /><br />
        <span v-if="employerStore.currentEmployerFull">{{ employerStore.currentEmployerFull.name }}</span>
      </v-list-item-content>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon color="navColor">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
 
    <template v-slot:append>
      <v-list-item @click="logout" link>
        <v-list-item-icon>
          <v-icon color="navColor">arrow-u-left-bottom</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="navColor--text">Logout</v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-title class="navColor--text">{{ time }}</v-list-item-title>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list color="transparent" dense>
      <v-list-item v-for="(res, index) in menu" :key="index" @click="navigate(res.url, res.query)" link>
              <!-- <v-icon small class="mr-2" @click="$router.push({ name: 'group_admin_edit', query: { employerID: item.employerID } })"> mdi-pencil </v-icon> -->

        <v-list-item-icon>
          <v-icon color="navColor" large>{{ res.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="navColor--text"><h2>{{ res.title }}</h2><br /><br /></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import { useEmployerStore } from "../stores/employer";
import { DateTime } from "luxon";
import { useAuthStore } from "../stores/auth"; 
import { gncRoles } from "../data/definitions/gncRoles";
import { roles } from "../data/definitions/roles";
export default {
  name: "SideNavComponent",
  setup() {
    const employerStore = useEmployerStore();
    const authStore = useAuthStore();

    return {
      employerStore, authStore, gncRoles
    };
  },
  data() {
    return {
      time: DateTime.now().toLocaleString(DateTime.DATETIME_FULL),
      drawer: true,
      mini: false,
      user: this.$auth.user,
    };
  },
  computed: {
    resource: {
      get() {
        let sideNav = [
          { icon: 'mdi-debug-step-over', title: 'Dashboard', url: 'dashboard' },
          { icon: 'mdi-apps', title: this.employerStore.fieldLabelSettings.groupLabel+' Admin', url: 'group_admin_index' },
          { icon: 'mdi-account-group', title: this.employerStore.fieldLabelSettings.subscriberLabel+' Admin', url: 'subscriber_admin_index' },
          { icon: 'mdi-file-document-outline', title: 'Reporting', url: 'reporting' },
          { icon: 'mdi-account', title: 'Users', url: 'user_admin_index'},
          { icon: 'mdi-information', title: 'Resources', url: 'resources_list'  },
          { icon: 'mdi-cog', title: 'Settings', url: 'group_admin_edit', query: { employerID: this.employerStore.currentEmployerID } },
          { icon: "mdi-email", title: "Messages", url: "messaging" },
        ];
        if(!this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin))
          sideNav = sideNav.filter((s) => s.url != 'messaging');
        
        if(this.authStore.userRole == gncRoles.memberSupport)
          sideNav = sideNav.filter((s) => s.url == 'subscriber_admin_index');
        
        if(this.authStore.userRole == gncRoles.reporting){
          sideNav = sideNav.filter((s) => s.url == 'dashboard' || s.url == 'reporting' || s.url == 'resources_list');
        }
          
        if(this.authStore.userRole == gncRoles.generalUser)
          sideNav = sideNav.filter((s) => s.url == 'dashboard' || s.url == 'group_admin_index' || s.url == 'subscriber_admin_index' || s.url == 'reporting' || s.url == 'resources_list' || s.url == 'group_admin_edit');

          // if(this.employerStore.systemSettings.groupCreation) {
          //   sideNav.push({ icon: 'mdi-cog', title: 'Settings', url: 'group_admin_edit', query: { employerID: this.employerStore.currentEmployerID } })
          // }

        return sideNav;
      }
    },
    menu: {
      get(){
        let returnArray = [];
        const store = useAuthStore();
        this.resource.forEach((resource) => {
          let canAccess = false;
          if (resource.role && resource.role.length > 0)
          {
            resource.role.forEach((x) => {
            if (!canAccess)
              {
                canAccess = store.getUserInRole(x);
              }
            })
            if (canAccess)
            {
              returnArray.push(resource)
            }
          }
          else {returnArray.push(resource)}
        })
        return returnArray;
      }
    }
  },
  methods: {
    navigate(navName, query=null){
      this.$router.push({ name: navName, query: query }).catch(()=>{});
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  mounted() {
    const hubSpotScript = document.createElement("script");
    hubSpotScript.setAttribute(
      "src",
      "//js-na1.hs-scripts.com/20327830.js",
    );
    hubSpotScript.async = true;
    hubSpotScript.defer = true;
    document.head.appendChild(hubSpotScript);
  }
};
</script>

<style scoped>
.v-list-item__content,
.v-list-item__title {
  color: white;
}
</style>
