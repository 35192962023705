import Vue from "vue";
import Maska from "maska";
import App from "./App.vue";
import router from "./router";
import { Auth0Plugin } from "./auth";
import vuetify from "./plugins/vuetify";
import VueTelInput from "vue-tel-input";
import VuePapaParse from "vue-papa-parse";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-tel-input/dist/vue-tel-input.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import { domain, clientId, audience } from "../auth_config";
import vueCountryRegionSelect from "vue-country-region-select"

const pinia = createPinia();

const VueTelInputOptions = {
  mode: "international",
  onlyCountries: ["US", "CA" ],
  defaultCountry: "US",
  autoFormat: true,
  validCharactersOnly: true
}

Vue
  .use(Maska)
  .use(VuePapaParse)
  .use(VueSweetalert2)
  .use(PiniaVuePlugin)
  .use(VueCompositionAPI)
  .use(VueTelInput, VueTelInputOptions)
  .use(vueCountryRegionSelect)
  .use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: appState => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    }
  });

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount("#app")