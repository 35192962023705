<template>
  <v-container>
    <header-component></header-component>
    <v-row class="mt-10">
        <v-col md="2"></v-col>
        <v-col sm="12" lg="8">
            <v-card>
                <v-card-title class="justify-center font-weight-bold">
                    <h3>Update Password</h3>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                        <v-row>
                            <v-col sm="12">
                                <v-text-field :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="passwordRules" v-model.trim="password" name="input-10-1"
                                    label="Password" class="input-group--focused"
                                    hint="Password must contain one uppercase, one lowercase, one special character, one number and at least 8 characters"
                                    :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                                    @keydown.space.prevent @paste="removeEventSpaces" @drop="removeEventSpaces"
                                >
                                </v-text-field> 
                            </v-col>
                            <v-col sm="12">
                                <v-text-field :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="confirmPasswordRules.concat(passwordConfirmationRule)" v-model.trim="confirmPassword"
                                    label="Confirm Password" class="input-group--focused" name="input-10-2"
                                    :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword"
                                    @keydown.space.prevent @paste="removeEventSpaces" @drop="removeEventSpaces"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col md="12" class="d-flex justify-center">
                                <v-btn color="success" class="ma-2" :disabled="!valid" @click="updatePassword()" :loading="loading">
                                    Update
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderComponent from "./HeaderComponent.vue";
import { useAuth0Store } from "../stores/auth0";
import { useAuthStore } from "../stores/auth";
import { useUserStore } from "../stores/user";
import { useEmployerStore } from "../stores/employer";

export default {
    name: "UpdatePasswordComponent",
    components: { HeaderComponent },
    setup() {
        const auth0Store = useAuth0Store();
        const userStore = useUserStore();
        const authStore = useAuthStore();
        const employerStore = useEmployerStore();

        return { auth0Store, userStore, authStore, employerStore };
    },
    data: () => ({
        loading: false,
        valid: true,
        password: "",
        confirmPassword: "",
        showPassword: false,
        showConfirmPassword: false,
        passwordRules: [
            (v) => !!v || "Password is required",
            (v) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || "Password must contain one uppercase, one lowercase, one special character, one number and at least 8 characters",
        ],
        confirmPasswordRules: [v => !!v || "Confirm Password is required"]
    }),
    computed: {
        passwordConfirmationRule() {
            return () => this.password === this.confirmPassword || "Password must match";
        }
    },
    methods: {
        removeEventSpaces(e){
            e.preventDefault();
            const left    = e.target.value.substring(0, e.target.selectionStart);
            const right   = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
            const pasted  = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
            e.target.value = left + pasted + right;
        },
        updatePassword() {
            if(!this.$refs.form.validate()){
                this.$swal({
                    title: "Error",
                    icon: "error",
                    html: Object.keys(this.$refs.form._data.inputs).map((key) => {
                        if (this.$refs.form._data.inputs[key].validations) {
                            if (this.$refs.form._data.inputs[key].validations.length > 0) {
                                return "<br>" + "- " + this.$refs.form._data.inputs[key].validations[0];
                            }
                        }
                    }),
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "Try Again",
                });
            }

            this.loading = true;
            
            //update password to auth0
            this.auth0Store.updateUser(this.authStore.user.sub, {password: this.password})
            .then( (res) => {
                if(res) {
                    let user = this.authStore.userDetailsFromDatabase;
                    user.forcePasswordReset = false;
                    this.userStore.updateUser(user.userID, user)
                    .then( () => {
                      this.$auth.loginWithRedirect();
                        // if(!this.employerStore.billingSettings.BillingInfoCompleted && this.employerStore.billingSettings.enrolledInTheGroup){
                        //     this.$router.push({ name: "billing", query: {employerID: this.employerStore.currentEmployerFull.employerID} });
                        // }else{
                        //     this.$router.push({ name: "dashboard" }) 
                        // }
                    })
                }
            })
            .finally(() => this.loading = false);
        },
    },
};
</script>
